import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { formatDate } from '@Common';

import UpdateVisitDialog from '../UpdateVisitDialog/index';
import UpdateProviderCredentials from '../UpdateProviderCredentials/index';
import MoveVisitDialog from '../MoveVisitDialog/index';

const useStyles = makeStyles(theme => ({
	icon: {
		marginRight: theme.spacing(2)
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	cardContent: {
		flexGrow: 1
	},
	buttonsWrapper: {
		justifyContent: 'space-between',
		display: 'flex'
	},
	markButtonsWrapper: {
		justifyContent: 'space-around',
		display: 'flex'
	},
	updatedDate: {
		marginBottom: '20px',
		textAlign: 'center',
		width: '100%'
	},
	visibilitySettingsButton: {
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	button: {
		width: '100%'
	}
}));

const Vendor = props => {
	const classes = useStyles();

	const {
		providerData,
		visitData,
		providerCredentials,
		updateVisit,
		updateProviderCredentials,
		handlePrint,
		moveVisit,
		getPersonsList,
		personsList,
		loggedAccountData
	} = props;

	return (
		<Grid item xs={12} sm={12} md={12}>
			<Card className={classes.card}>
				<CardContent className={classes.cardContent}>
					<Grid container spacing={4}>
						<Grid
							item
							xs={12}
							md={12}
							className={classes.buttonsWrapper}
						>
							<UpdateVisitDialog updateVisit={updateVisit} />
						</Grid>

						{providerData.providerName !== 'self provider' && (
							<Grid
								item
								xs={12}
								md={12}
								className={classes.buttonsWrapper}
							>
								<UpdateProviderCredentials
									updateProviderCredentials={
										updateProviderCredentials
									}
									providerCredentials={providerCredentials}
								/>
							</Grid>
						)}

						<Grid
							item
							xs={12}
							md={12}
							className={classes.buttonsWrapper}
						>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								onClick={handlePrint}
							>
								Print
							</Button>
						</Grid>

						<Grid
							item
							xs={12}
							md={12}
							className={classes.buttonsWrapper}
						>
							<MoveVisitDialog
								moveVisit={moveVisit}
								personsList={personsList}
								getPersonsList={getPersonsList}
								currentPersonId={visitData.person}
								loggedAccountData={loggedAccountData}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							md={12}
							className={classes.buttonsWrapper}
						>
							<Typography
								align="center"
								gutterBottom
								variant="h6"
								component="h6"
								className={classes.updatedDate}
							>
								Updated on {formatDate(visitData.created)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

Vendor.propTypes = {
	visitData: PropTypes.object.isRequired,
	handlePrint: PropTypes.func.isRequired,
	providerData: PropTypes.object.isRequired,
	loggedAccountData: PropTypes.object.isRequired,
	providerCredentials: PropTypes.func.isRequired,
	updateVisit: PropTypes.func.isRequired,
	updateProviderCredentials: PropTypes.func.isRequired,
	getPersonsList: PropTypes.func.isRequired,
	moveVisit: PropTypes.func.isRequired,
	personsList: PropTypes.array.isRequired
};

export default Vendor;
