import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Button,
	CssBaseline,
	Typography,
	Grid,
	Container,
	TextField
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
/* Clear information for selected person in left navigation */
import {
	clearSelectedPerson,
	setDefaultSelectedUser,
	setLoggedAccountData
} from '@SharedActions';

import { isNumeric } from '@Common';
import { EMAIL_REGEX } from '@Constants';

import { editAccountInfoStyles } from './styles';

import { getAccountInformation, editAccountInformation } from '../actions';

import cookies from '../../../utilities/cookies';

class EditAccountInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: {},
			errors: {}
		};
	}

	componentDidMount = async () => {
		await this.props.clearSelectedPerson();
		await this.props.setDefaultSelectedUser();

		const { userId } = this.props.loggedAccountData;

		const response = await this.props.getAccountInformation({ userId });
		const accountResponse = response.response.toJS();

		this.setState({
			fields: {
				firstName: accountResponse.firstName,
				lastName: accountResponse.lastName,
				email: accountResponse.email,
				phoneNumber: accountResponse.phoneNumber
			}
		});
	};

	onChangeHandler = event => {
		const { fields } = this.state;
		fields[event.target.name] = event.target.value;
		this.setState({ fields, errors: {} });
	};

	handleValidation = () => {
		const { fields } = this.state;
		const errors = {};
		let formIsValid = true;

		if (!fields.firstName) {
			formIsValid = false;
			errors.firstName = 'Field cannot be empty.';
		}

		if (!fields.lastName) {
			formIsValid = false;
			errors.lastName = 'Field cannot be empty.';
		}

		if (!fields.email) {
			formIsValid = false;
			errors.email = 'Field cannot be empty.';
		} else if (!EMAIL_REGEX.test(fields.email)) {
			formIsValid = false;
			errors.email = 'Email format is not valid.';
		}

		if (!fields.phoneNumber) {
			formIsValid = false;
			errors.phoneNumber = 'Field cannot be empty.';
		} else if (!isNumeric(fields.phoneNumber)) {
			formIsValid = false;
			errors.phoneNumber =
				'Phone number format is not valid. It can be only numbers';
		}

		this.setState({ errors });

		return formIsValid;
	};

	onFormSubmit = async event => {
		event.preventDefault();
		if (this.handleValidation()) {
			const { fields } = this.state;

			const accountInfoData = {
				firstName: fields.firstName,
				lastName: fields.lastName,
				email: fields.email.toLowerCase(),
				phoneNumber: fields.phoneNumber
			};

			try {
				const res = await this.props.editAccountInformation(
					accountInfoData
				);
				/* For now just change username */

				const userName = `${res.response.get(
					'firstName'
				)} ${res.response.get('lastName')}`;

				this.props.setLoggedAccountData({
					username: userName
				});

				cookies.remove('uname');
				cookies.set('uname', userName);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e, 'edit account error');
			}
		}
	};

	render = () => {
		const { classes } = this.props;
		const { errors, fields } = this.state;

		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					<div className={classes.accountInfoContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Edit Personal Information
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Please edit below information of your account
							</Typography>
						</Container>
						<Container
							className={classes.accountInfoGrid}
							maxWidth="md"
						>
							<form
								onSubmit={this.onFormSubmit}
								className={classes.form}
								noValidate
							>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<TextField
											autoComplete="fname"
											name="firstName"
											onChange={this.onChangeHandler}
											variant="outlined"
											required
											fullWidth
											value={fields.firstName}
											InputLabelProps={{
												shrink: true
											}}
											error={errors.firstName}
											helperText={errors.firstName}
											id="firstName"
											label="First Name"
											inputProps={{
												autocomplete: 'new-password',
												form: {
													autocomplete: 'off'
												}
											}}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant="outlined"
											required
											fullWidth
											id="lastName"
											label="Last Name"
											name="lastName"
											value={fields.lastName}
											InputLabelProps={{
												shrink: true
											}}
											error={errors.lastName}
											helperText={errors.lastName}
											onChange={this.onChangeHandler}
											autoComplete="lname"
											inputProps={{
												autocomplete: 'new-password',
												form: {
													autocomplete: 'off'
												}
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant="outlined"
											fullWidth
											id="email"
											label="Email Address"
											name="email"
											disabled
											value={fields.email}
											InputLabelProps={{
												shrink: true
											}}
											onChange={this.onChangeHandler}
											error={errors.email}
											helperText={errors.email}
											inputProps={{
												autocomplete: 'new-password',
												form: {
													autocomplete: 'off'
												}
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant="outlined"
											required
											fullWidth
											id="phone_number"
											label="Phone Number"
											name="phoneNumber"
											value={fields.phoneNumber}
											InputLabelProps={{
												shrink: true
											}}
											onChange={this.onChangeHandler}
											error={errors.phoneNumber}
											helperText={errors.phoneNumber}
											inputProps={{
												autocomplete: 'new-password',
												form: {
													autocomplete: 'off'
												}
											}}
										/>
									</Grid>
								</Grid>

								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
									/* 	disabled={!fields.agree} */
								>
									Edit Account Info
								</Button>
							</form>
						</Container>
					</div>
				</main>
			</React.Fragment>
		);
	};
}

EditAccountInformation.propTypes = {
	classes: PropTypes.object.isRequired,
	getAccountInformation: PropTypes.func.isRequired,
	clearSelectedPerson: PropTypes.func.isRequired,
	setDefaultSelectedUser: PropTypes.func.isRequired,
	editAccountInformation: PropTypes.func.isRequired,
	setLoggedAccountData: PropTypes.func.isRequired,
	loggedAccountData: PropTypes.object.isRequired
};

export default withStyles(editAccountInfoStyles)(
	connect(
		state => {
			return {
				loggedAccountData: state.general.get('loggedAccountData').toJS()
			};
		},
		{
			clearSelectedPerson,
			setDefaultSelectedUser,
			getAccountInformation,
			editAccountInformation,
			setLoggedAccountData
		}
	)(EditAccountInformation)
);
