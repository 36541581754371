import React, { Component } from 'react';
import PrivateRoute from '@Components/Routes/PrivateRoute';
import VisitsList from './pages/VisitsList/index';
import HealthSummaryList from './pages/HealthSummaryList/index';
import VisitInformation from './pages/VisitInformation/index';

class Visits extends Component {
	render = () => {
		return (
			<React.Fragment>
				<PrivateRoute
					path="/dashboard/:personId/visits"
					component={VisitsList}
					exact
				/>
				<PrivateRoute
					path="/dashboard/:personId/visits/:visitId"
					component={VisitInformation}
					exact
				/>
				<PrivateRoute
					path="/dashboard/:personId/health-summaries"
					component={HealthSummaryList}
					exact
				/>
				<PrivateRoute
					path="/dashboard/:personId/health-summaries/:visitId"
					component={VisitInformation}
					exact
				/>
			</React.Fragment>
		);
	};
}

export default Visits;
