/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	avatar: {
		display: 'flex',
		flexDirection: 'column',
		height: '140px',
		width: '140px',
		margin: '0 auto'
	},
	input: {
		display: 'none'
	},
	uploadButtons: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '15px'
	}
}));

const PasswordChangedSuccessfulDialog = ({ onClose, open }) => {
	const classes = useStyles();

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="edit-person-dialog"
			className={classes.root}
		>
			<DialogTitle id="edit-person-dialog">
				Password Changed Successful
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Please re-login for apply changes
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};

PasswordChangedSuccessfulDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired
};

export default PasswordChangedSuccessfulDialog;
