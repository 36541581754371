import React, { useState } from 'react';
import PropTypes from 'prop-types';

import RenderLink from '@Components/RenderLink/index';

import {
	Avatar,
	Menu,
	MenuItem,
	ListItemAvatar,
	ListItemText,
	Box
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	accountButton: {
		[theme.breakpoints.down('sm')]: {
			padding: 5
		}
	}
}));

const AccountMenu = ({
	authentication,
	logOut,
	selectedUser,
	userData,
	persons
}) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const openMenu = Boolean(anchorEl);

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogOut = async () => {
		try {
			const res = await logOut();
			if (res.response.get('success')) {
				// refactor this
				authentication.logout();
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	return (
		<>
			<IconButton
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={handleMenu}
				className={classes.accountButton}
				color="inherit"
			>
				{selectedUser.photo ? (
					<Avatar
						alt={selectedUser.personName}
						src={selectedUser.photo}
						style={{
							width: '48px',
							height: '48px'
						}}
					/>
				) : (
					<AccountCircle
						style={{
							width: '48px',
							height: '48px'
						}}
					/>
				)}
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={openMenu}
				onClose={handleClose}
			>
				<MenuItem>
					<Box
						display="flex"
						fontWeight="bold"
						justifyContent="center"
						width="100%"
					>
						Persons
					</Box>
				</MenuItem>
				{persons.map(person => (
					<RenderLink
						element={MenuItem}
						onClick={handleClose}
						key={person.id}
						to={{
							pathname: `/dashboard/${person.id}`,
							state: {
								person: {
									id: person.id,
									name: person.personName,
									photo: person.photo
								}
							},
							pathInfo: person.personName
						}}
					>
						<ListItemAvatar>
							<Avatar
								alt={person.pathNames}
								src={person.photo}
								style={{
									width: '24px',
									height: '24px'
								}}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={person.personName}
							secondary={null}
						/>
					</RenderLink>
				))}
				<MenuItem>
					<Box
						display="flex"
						fontWeight="bold"
						justifyContent="center"
						width="100%"
					>
						Account section
					</Box>
				</MenuItem>
				<RenderLink
					element={MenuItem}
					onClick={handleClose}
					to={{
						pathname: `/`
					}}
				>
					<ListItemAvatar
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<AccountCircle />
					</ListItemAvatar>
					<ListItemText
						primary={userData.username}
						secondary={null}
					/>
				</RenderLink>
				<RenderLink
					element={MenuItem}
					onClick={handleClose}
					to={{
						pathname: `/${userData.userId}/edit-account-info`,
						state: {
							userId: userData.userId
						}
					}}
				>
					Edit Account Information
				</RenderLink>
				<RenderLink
					element={MenuItem}
					onClick={handleClose}
					to={{
						pathname: `/${userData.userId}/change-password`,
						state: {
							userId: userData.userId
						}
					}}
				>
					Change Password
				</RenderLink>

				<MenuItem onClick={handleLogOut}>Log Out</MenuItem>
			</Menu>
		</>
	);
};

AccountMenu.propTypes = {
	logOut: PropTypes.func.isRequired,
	selectedUser: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	authentication: PropTypes.object.isRequired,
	persons: PropTypes.array.isRequired
};

export default AccountMenu;
