import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { formatDate } from '@Common';

import UpdateProfileDialog from '../UpdateProfileDialog/index';
import UpdateProviderCredentials from '../UpdateProviderCredentials/index';
import MoveProfileDialog from '../MoveProfileDialog/index';

const useStyles = makeStyles(theme => ({
	icon: {
		marginRight: theme.spacing(2)
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	cardContent: {
		flexGrow: 1
	},
	buttonsWrapper: {
		justifyContent: 'space-between',
		display: 'flex'
	},
	markButtonsWrapper: {
		justifyContent: 'space-around',
		display: 'flex'
	},
	updated: {
		marginBottom: '20px',
		textAlign: 'center',
		width: '100%'
	},
	visibilitySettingsButton: {
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	button: {
		width: '100%',
		cursor: 'pointer',
		textAlign: 'center',

		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	}
}));

const Vendor = props => {
	const classes = useStyles();

	const {
		profileId,
		providerData,
		profileData,
		providerCredentials,
		updateProfile,
		updateProviderCredentials,
		handlePrint,
		person,
		moveProfile,
		personsList
	} = props;

	const isSelfProvider =
		providerData.providerName ===
		'self provider'; /* Add field in data base about that */

	return (
		<Grid item xs={12} sm={12} md={12}>
			<Card className={classes.card}>
				<CardContent className={classes.cardContent}>
					<Grid container spacing={4}>
						{!isSelfProvider ? (
							<Grid
								item
								xs={12}
								md={12}
								className={classes.buttonsWrapper}
							>
								<UpdateProfileDialog
									updateProfile={updateProfile}
								/>
							</Grid>
						) : (
							<Grid item xs={12} md={12}>
								<Button
									component={RouterLink}
									className={classes.button}
									variant="contained"
									color="primary"
									to={{
										pathname: `/dashboard/${profileData.person}/edit-self-profile/${profileId}`,
										state: {
											person
										}
									}}
								>
									Edit Profile
								</Button>
							</Grid>
						)}

						{!isSelfProvider && (
							<Grid
								item
								xs={12}
								md={12}
								className={classes.buttonsWrapper}
							>
								<MoveProfileDialog
									moveProfile={moveProfile}
									personsList={personsList}
									currentPersonId={profileData.person}
								/>
							</Grid>
						)}

						{!isSelfProvider && (
							<Grid
								item
								xs={12}
								md={12}
								className={classes.buttonsWrapper}
							>
								<UpdateProviderCredentials
									updateProviderCredentials={
										updateProviderCredentials
									}
									providerCredentials={providerCredentials}
								/>
							</Grid>
						)}

						<Grid
							item
							xs={12}
							md={12}
							className={classes.buttonsWrapper}
						>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								onClick={handlePrint}
							>
								Print
							</Button>
						</Grid>

						<Grid
							item
							xs={12}
							md={12}
							className={classes.buttonsWrapper}
						>
							<Typography
								align="center"
								gutterBottom
								variant="h6"
								component="h6"
								className={classes.updated}
							>
								Updated on {formatDate(profileData.updated)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

Vendor.propTypes = {
	profileData: PropTypes.object.isRequired,
	handlePrint: PropTypes.func.isRequired,
	providerData: PropTypes.object.isRequired,
	person: PropTypes.object.isRequired,
	profileId: PropTypes.string.isRequired,
	providerCredentials: PropTypes.object.isRequired,
	updateProfile: PropTypes.func.isRequired,
	updateProviderCredentials: PropTypes.func.isRequired,
	moveProfile: PropTypes.func.isRequired,
	personsList: PropTypes.array.isRequired
};

export default Vendor;
