/* eslint-disable no-param-reassign */
import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	providers: [],
	providersType: [],
	providersWaitingForSms: []
});

const connection = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_PROVIDERS:
			if (action.state === 'finished') {
				state = state.set('providers', action.response);
			}
			return state;
		case actionTypes.GET_PROVIDER_TYPE:
			if (action.state === 'finished') {
				state = state.set('providersType', action.response);
			}
			return state;
		case actionTypes.GET_PROVIDERS_WAITING_FOR_SMS:
			if (action.state === 'finished') {
				state = state.set('providersWaitingForSms', action.response);
			}
			return state;
		default:
			return state;
	}
};

export default connection;
