import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CssBaseline,
    Typography,
    Container,
    Grid,
    Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import ConfirmationDialog from '@Components/ConfirmationDialog';

import {
    summaryProfilesList,
    createTempLinkSummary,
    sendExport
} from './actions';

import SummaryContent from './components/SummaryContent/index';
import TempLinkDialog from './components/TempLinkDialog/index';
import ExportDialog from './components/ExportDialog/index';

import { styles } from './styles';

class Summary extends Component {
    constructor(props) {
        super(props);
        this.summaryRef = React.createRef();
        this.state = {
            email: '',
            name: '',
            isConfirmationDialogOpen: false,
            tempLikDialogOpen: false
        };
    }

    componentDidMount = () => {
        this.props.summaryProfilesList(this.props.match.params.personId);
    };

    generateTempLink = () => {
        const loggedData = this.props.loggedAccountData;

        const tempLink = {
            user: loggedData.userId,
            email: this.state.email,
            name: this.state.name,
            profileId: null,
            content: {
                parentData: this.props.match.params.personId
            }
        };

        this.props.createTempLinkSummary(tempLink);
        this.setState({
            email: '',
            isConfirmationDialogOpen: false
        });
    };

    handleSendEmail = data => {
        this.setState({
            email: data.email,
            name: data.name,
            isConfirmationDialogOpen: true,
            tempLikDialogOpen: false
        });
    };

    sendExport = params => {
        const formData = {
            title: params.title,
            email: params.email,
            personId: this.props.match.params.personId
        };

        this.props.sendExport(formData);
    };

    handleCloseConfirmationDialog = () => {
        this.setState({
            email: '',
            isConfirmationDialogOpen: false
        });
    };

    sendMedicalAdvice = () => {
        // eslint-disable-next-line no-console
        console.log('send medical advice');
    };

    handleClickDialogOpen = () => {
        this.setState({
            email: '',
            name: '',
            tempLikDialogOpen: true
        });
    };

    handleDialogClose = () => {
        this.setState({
            tempLikDialogOpen: false
        });
    };

    render = () => {
        const { classes, allInformation, loggedAccountData } = this.props;
        const { isConfirmationDialogOpen } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <main ref={this.summaryRef} className={classes.mainContainer}>
                    <div className={classes.exportSummaryContent}>
                        <Container maxWidth="sm">
                            <Typography
                                component="h1"
                                variant="h2"
                                align="center"
                                color="textPrimary"
                                gutterBottom
                                className={classes.header}
                            >
                                MyPHI Health Overview
                            </Typography>
                            <Typography
                                variant="h5"
                                align="center"
                                color="textSecondary"
                                paragraph
                                className={classes.subHeader}
                            >
                                MyPHI Health Overview provides health data in
                                one place from all connected portals, including
                                education portals. This Health Overview can be
                                shared by sending a temporary link to the data
                                or as a pdf attachment. Data is shared according
                                to the visibility settings for each health
                                record source.
                            </Typography>
                        </Container>
                        <Container
                            className={classes.exportSummaryGrid}
                            maxWidth="md"
                        >
                            <Grid
                                style={{ marginBottom: '10px' }}
                                container
                                spacing={4}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleClickDialogOpen}
                                    className={classes.tempLinkButton}
                                >
                                    Send temporary link
                                </Button>
                                <TempLinkDialog
                                    isDialogOpened={
                                        this.state.tempLikDialogOpen
                                    }
                                    selectedPerson={{
                                        name: loggedAccountData.username
                                    }}
                                    handleSendTemporaryLinkData={
                                        this.handleSendEmail
                                    }
                                    handleDialogClose={this.handleDialogClose}
                                />
                            </Grid>
                            <Grid
                                style={{ marginBottom: '10px' }}
                                container
                                spacing={4}
                            >
                                <ExportDialog sendExport={this.sendExport} />
                            </Grid>
                            {/* <Grid
                                style={{ marginBottom: '10px' }}
                                container
                                spacing={4}
                            >
                                <MedicalAdviceDialog
                                    requestMedicalAdvice={
                                        this.sendMedicalAdvice
                                    }
                                    medicalQuestions={medicalQuestions}
                                />
                            </Grid> */}
                        </Container>
                        <Container
                            className={classes.summaryContentGrid}
                            maxWidth="md"
                        >
                            <Grid style={{ marginBottom: '10px' }} container>
                                <Grid item md={12} xs={12}>
                                    <SummaryContent
                                        scrollElement={this.summaryRef}
                                        data={[
                                            {
                                                categoryName: 'title',
                                                isNested: false,
                                                values: 'Health Data'
                                            },
                                            ...allInformation.myChart,
                                            {
                                                categoryName: 'title',
                                                isNested: false,
                                                values: 'Education Data'
                                            },
                                            ...allInformation.education,
                                            {
                                                categoryName: 'title',
                                                isNested: false,
                                                values: 'Fort Worth'
                                            },
                                            ...allInformation.fwisd,
                                            ...allInformation.absences,
                                            {
                                                categoryName: 'title',
                                                isNested: false,
                                                values: 'IrvingISD'
                                            },
                                            ...allInformation.irvingISD
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </main>
                <ConfirmationDialog
                    open={isConfirmationDialogOpen}
                    dialogContent="Are you sure that you want to share your health information with this email?"
                    dialogTitle="Confirmation Dialog"
                    action={this.generateTempLink}
                    close={this.handleCloseConfirmationDialog}
                />
            </React.Fragment>
        );
    };
}

Summary.propTypes = {
    classes: PropTypes.object.isRequired,
    allInformation: PropTypes.object.isRequired,
    summaryProfilesList: PropTypes.func.isRequired,
    createTempLinkSummary: PropTypes.func.isRequired,
    loggedAccountData: PropTypes.object.isRequired,
    sendExport: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            personId: PropTypes.string.isRequired
        })
    })
};

Summary.defaultProps = {
    match: {}
};

export default withStyles(styles)(
    connect(
        state => {
            return {
                allInformation: state.summary.get('allInformation').toJS(),
                medicalQuestions: state.summary.get('medicalQuestions').toJS(),
                loggedAccountData: state.general.get('loggedAccountData').toJS()
            };
        },
        { summaryProfilesList, createTempLinkSummary, sendExport }
    )(Summary)
);