import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	tempLinkButton: {
		width: '100%',
		cursor: 'pointer',
		textAlign: 'center',

		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	},
	email: {
		width: '600px'
	}
}));

const TempLinkDialog = props => {
	const classes = useStyles();
	const {
		selectedPerson,
		handleSendTemporaryLinkData,
		isDialogOpened,
		handleDialogClose
	} = props;

	const [name, setName] = React.useState('');
	const [email, setEmail] = React.useState('');

	const handleClose = () => {
		handleDialogClose();
		setName('');
		setEmail('');
	};

	const handleNameFieldChange = event => {
		setName(event.target.value);
	};

	const handleEmailFieldChange = event => {
		setEmail(event.target.value);
	};

	const handleSendButton = () => {
		handleSendTemporaryLinkData({ name, email });
		handleClose();
	};

	return (
		<React.Fragment>
			<Dialog
				open={isDialogOpened}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">
					Send a temporary link to My Health Info
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{`Send a temporary link to ${selectedPerson?.name}'s Personal Health
                        Info to share with the recipient below:`}
					</DialogContentText>

					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="name"
						label="Name"
						name="name"
						value={name}
						onChange={handleNameFieldChange}
					/>

					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email"
						name="email"
						value={email}
						onChange={handleEmailFieldChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button
						disabled={!email}
						onClick={handleSendButton}
						color="primary"
					>
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

TempLinkDialog.propTypes = {
	selectedPerson: PropTypes.object.isRequired,
	handleSendTemporaryLinkData: PropTypes.func.isRequired,
	isDialogOpened: PropTypes.bool.isRequired,
	handleDialogClose: PropTypes.func.isRequired
};

export default TempLinkDialog;