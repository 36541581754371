import { makeStyles } from '@material-ui/core/styles';

export const useStyles = props =>
	makeStyles(theme => ({
		root: {
			width: '100%'
		},
		ellipsisText: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			maxWidth: '80%',
			display: 'inline-block',
			fontSize: props.isSmallContainer ? '0.80rem' : '1.25rem',
			[theme.breakpoints.down('sm')]: {
				fontSize: '0.80rem'
			}
		},
		paperWrapper: {
			padding: '6px 16px'
		},
		timelineHeader: {
			fontSize: props.isSmallContainer ? '0.80rem' : '1.25rem',
			[theme.breakpoints.down('sm')]: {
				fontSize: '0.80rem'
			}
		},
		profileName: {
			color: 'grey',
			fontWeight: '300'
		},
		icon: {
			[theme.breakpoints.down('xs')]: {
				marginLeft: '-5px'
			}
		},
		dateTime: {
			marginTop: '10px'
		}
	}));
