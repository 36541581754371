export const OPEN_ALERT = Symbol('OPEN_ALERT');
export const CLEAR_SELECTED_PERSON_INFO = Symbol('CLEAR_SELECTED_PERSON_INFO');
export const SET_SELECTED_PERSON = Symbol('SET_SELECTED_PERSON');
export const LOG_IN = Symbol('LOG_IN');
export const SET_SELECTED_USER = Symbol('SET_SELECTED_USER');
export const SET_DEFAULT_SELECTED_USER = Symbol('SET_DEFAULT_SELECTED_USER');
export const SET_LOGGED_ACCOUNT_DATA = Symbol('SET_LOGGED_ACCOUNT_DATA');
export const LOG_OUT = Symbol('LOG_OUT');

export const openAlert = body => {
	return {
		type: OPEN_ALERT,
		body: {
			...body
		}
	};
};

export const clearSelectedPerson = () => {
	return {
		type: CLEAR_SELECTED_PERSON_INFO
	};
};

export const logIn = loginData => {
	return {
		type: LOG_IN,
		requestType: 'POST',
		request: `/login`,
		message: 'Login is successful.',
		body: {
			...loginData
		}
	};
};

export const setSelectedUser = userData => {
	return {
		type: SET_SELECTED_USER,
		body: {
			...userData
		}
	};
};

export const setDefaultSelectedUser = () => {
	return {
		type: SET_DEFAULT_SELECTED_USER
	};
};

export const setLoggedAccountData = accountData => {
	return {
		type: SET_LOGGED_ACCOUNT_DATA,
		body: {
			...accountData
		}
	};
};

export const logOut = () => {
	return {
		type: LOG_OUT,
		requestType: 'POST',
		request: `/logout`,
		body: {}
	};
};

export const setSelectedPerson = selectedPerson => {
	return {
		type: SET_SELECTED_PERSON,
		data: {
			selectedPerson
		}
	};
};
