/* eslint-disable no-const-assign */
/* eslint-disable react/no-unescaped-entities */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import {
	Button,
	CssBaseline,
	Grid,
	Container,
	Box,
	Stepper,
	Step,
	StepLabel,
	Typography
} from '@material-ui/core';

import { withStyles } from '@material-ui/styles';

import ProviderSelect from './components/ProviderSelect';
import ProviderForm from './components/ProviderForm';
import RequestNewProviderForm from './components/RequestNewProviderForm';
import TwoFactorAuthenticationForm from './components/TwoFactorAuthenticationForm';
import ReviewComponent from './components/ReviewComponent';

import {
	getProviders,
	getProviderType,
	addProfileFromProvider,
	requestNewProvider,
	getProvidersWaitingForSms,
	sendSmsCode,
	saveSettings,
	getSettings
} from './actions';

import { styles } from './styles';
import { STEPS, validationMap } from './constants';

class Connection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeStep: 0,
			selectedProviderId: '',
			providerUsername: '',
			providerPassword: '',
			person: {},
			isConsent: false
		};
	}

	componentDidMount = async () => {
		await this.props.getProviders();
		await this.props.getProviderType();

		const { personId } = this.props.match.params;

		this.setState({ person: this.props.history.location.state.person });
		await this.props.getProvidersWaitingForSms(personId);
	};

	handleNext = () => {
		const { activeStep } = this.state;
		this.setState({
			activeStep: activeStep + 1
		});
	};

	handleBack = () => {
		const { activeStep } = this.state;
		this.setState({
			activeStep: activeStep - 1
		});
	};

	handleReset = () => {
		this.setState({
			activeStep: 0,
			selectedProviderId: '',
			providerUsername: '',
			providerPassword: ''
		});
	};

	setProvider = event => {
		this.setState({
			selectedProviderId: event.target.value
		});
	};

	isValidate = () => {
		const { activeStep, isConsent } = this.state;
		let isValidate = false;

		// eslint-disable-next-line array-callback-return
		validationMap[activeStep].map(item => {
			if (!this.state[item]) {
				isValidate = true;
			}
		});

		if (activeStep === STEPS.length - 1 && !isConsent) {
			isValidate = true;
		}

		return isValidate;
	};

	getStepContent = step => {
		const {
			selectedProviderId,
			providerUsername,
			providerPassword,
			isConsent
		} = this.state;
		const { providers } = this.props;

		switch (step) {
			case 0:
				return (
					<ProviderSelect
						handleChange={this.setProvider}
						providerId={selectedProviderId}
						providers={providers}
					/>
				);
			case 1:
				return (
					<ProviderForm
						handleChange={this.handleTextField}
						values={{
							providerUsername,
							providerPassword
						}}
					/>
				);
			case 2:
				return (
					<ReviewComponent
						values={{
							selectedProviderId,
							providerUsername,
							providerPassword
						}}
						providers={providers}
						isConsent={isConsent}
						handleConsentChange={this.handleConsentChange}
					/>
				);
			default:
				return 'Unknown step';
		}
	};

	submitHandler = async () => {
		const {
			selectedProviderId,
			providerUsername,
			providerPassword
		} = this.state;

		const { personId } = this.props.match.params;

		const data = {
			providerId: selectedProviderId,
			personId,
			username: providerUsername,
			password: providerPassword
		};

		try {
			await this.props.addProfileFromProvider(data);
			// await this.props.getSettings(personId);

			const resSettings = await this.props.getSettings(personId);

			let settings = resSettings.response;

			settings = settings.setIn(
				['value', 'providersConsents', selectedProviderId],
				true
			);

			this.props.saveSettings(settings.toJS().value, personId);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}

		this.setState({ isConsent: false });
		this.handleNext();
	};

	handleTextField = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSendSmsCode = async (smsCode, providerId) => {
		try {
			await this.props.sendSmsCode(smsCode, providerId);

			const { personId } = this.props.match.params;

			await this.props.getProvidersWaitingForSms(personId);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	handleConsentChange = event => {
		this.setState({ isConsent: event.target.checked });
	};

	render = () => {
		const { classes, providersType, providersWaitingForSms } = this.props;

		const { activeStep, person } = this.state;

		return (
			<Fragment>
				<CssBaseline />
				<main>
					<div className={classes.profileCreateContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Add a New Connection
							</Typography>
						</Container>
					</div>
					<Container
						className={classes.profileCrateGrid}
						maxWidth="md"
					>
						<Grid container spacing={4}>
							<div className={classes.stepperWrapper}>
								<Stepper activeStep={activeStep}>
									{STEPS.map((label, index) => {
										const stepProps = {};
										const labelProps = {};

										return (
											<Step key={label} {...stepProps}>
												<StepLabel {...labelProps}>
													<div
														className={
															classes.stepLabel
														}
													>
														{label}
													</div>
												</StepLabel>
											</Step>
										);
									})}
								</Stepper>

								<Container maxWidth="md">
									{activeStep === 0 && (
										<Box>
											<Typography
												variant="h5"
												color="textSecondary"
												align="center"
												paragraph
												className={classes.subHeader}
											>
												Create a new connection using
												the provider list below.
											</Typography>
											<Typography
												variant="h5"
												color="textSecondary"
												align="center"
												paragraph
												className={classes.subHeader}
											>
												Don't see your Provider? Scroll
												down to request their addition.
											</Typography>
										</Box>
									)}

									{activeStep === 1 && (
										<Box>
											<Typography
												variant="h5"
												color="textSecondary"
												align="center"
												paragraph
												className={classes.subHeader}
											>
												Enter the Login and Password you
												use with your provider
											</Typography>
										</Box>
									)}
									{activeStep === 2 && (
										<Box>
											<Typography
												variant="h5"
												color="textSecondary"
												align="center"
												paragraph
												className={classes.subHeader}
											>
												Provider Connection will be
												added after you select "Finish"
												below.
											</Typography>
											<Typography
												variant="h5"
												color="textSecondary"
												align="center"
												paragraph
												className={classes.subHeader}
											>
												Adding this connection will
												allow MyPHI to access
												information from this provider
												and share it with other
												providers in your account
												according MyPHI's data sharing
												policies or
												<RouterLink
													style={{ color: 'inherit' }}
													component={Typography}
													to={{
														pathname: `/dashboard/${person.id}/export-summary`,
														state: {
															person
														}
													}}
												>
													{' at your direction '}
												</RouterLink>
												via email or link. Account data
												sharing settings can be modified
												at any time in
												<RouterLink
													style={{ color: 'inherit' }}
													component={Typography}
													to={{
														pathname: `/dashboard/${person.id}/settings`,
														state: {
															person
														}
													}}
												>
													{' account settings'}
												</RouterLink>
											</Typography>
										</Box>
									)}
								</Container>

								<div>
									{activeStep === STEPS.length ? (
										<div>
											<Container
												style={{ marginBottom: '50px' }}
												fixed
											>
												<Typography
													variant="h5"
													align="center"
													paragraph
												>
													Profile create request sent
													successful. You can check
													create status in Connections
													menu.
												</Typography>
											</Container>
											<Button
												onClick={this.handleReset}
												className={classes.button}
												variant="contained"
												color="primary"
											>
												Select another provider
											</Button>
										</div>
									) : (
										<div>
											<Container
												style={{ marginBottom: '50px' }}
												fixed
											>
												{this.getStepContent(
													activeStep
												)}
											</Container>
											<div
												className={
													classes.buttonsWrapper
												}
											>
												<Button
													disabled={activeStep === 0}
													variant="contained"
													color="primary"
													onClick={this.handleBack}
												>
													Back
												</Button>
												<Button
													variant="contained"
													color="primary"
													disabled={this.isValidate()}
													onClick={
														activeStep ===
														STEPS.length - 1
															? this.submitHandler
															: this.handleNext
													}
												>
													{activeStep ===
													STEPS.length - 1
														? 'Finish'
														: 'Next'}
												</Button>
											</div>
										</div>
									)}
								</div>
							</div>
						</Grid>
					</Container>
					<div>
						<Container
							maxWidth="sm"
							className={classes.requestContainer}
						>
							<Typography
								variant="h4"
								align="center"
								paragraph
								className={classes.subHeader}
							>
								Request New Provider
							</Typography>
							<Typography
								variant="h5"
								align="center"
								paragraph
								className={classes.subHeader}
								color="textSecondary"
								style={{
									marginBottom: '40px',
									marginTop: '40px'
								}}
							>
								If you don't see your provider listed, you can
								request a new one. Click below to send us their
								details.
							</Typography>
							<RequestNewProviderForm
								providersType={providersType}
								requestNewProvider={
									this.props.requestNewProvider
								}
							/>
						</Container>
					</div>

					{providersWaitingForSms.length > 0 && (
						<div>
							<Container
								maxWidth="sm"
								className={classes.smsContainer}
							>
								<Typography
									variant="h4"
									align="center"
									paragraph
									className={classes.subHeader}
								>
									Send Sms Code
								</Typography>
								<Typography
									variant="h5"
									align="center"
									paragraph
									className={classes.subHeader}
									color="textSecondary"
									style={{
										marginBottom: '40px',
										marginTop: '40px'
									}}
								>
									If your provider has two factor
									authentication enabled and requires SMS code
									- after you receive the code please enter it
									by clicking the button below.
								</Typography>
								<TwoFactorAuthenticationForm
									providersWaitingForSms={
										providersWaitingForSms
									}
									sendSmsCode={this.handleSendSmsCode}
								/>
							</Container>
						</div>
					)}
				</main>
			</Fragment>
		);
	};
}

Connection.propTypes = {
	getProviders: PropTypes.func.isRequired,
	getProviderType: PropTypes.func.isRequired,
	requestNewProvider: PropTypes.func.isRequired,
	addProfileFromProvider: PropTypes.func.isRequired,
	getProvidersWaitingForSms: PropTypes.func.isRequired,
	sendSmsCode: PropTypes.func.isRequired,
	saveSettings: PropTypes.func.isRequired,
	getSettings: PropTypes.func.isRequired,
	providers: PropTypes.array.isRequired,
	providersWaitingForSms: PropTypes.array.isRequired,
	providersType: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			personId: PropTypes.string.isRequired
		})
	})
};

Connection.defaultProps = {
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				providers: state.connection.get('providers').toJS(),
				providersType: state.connection.get('providersType').toJS(),
				providersWaitingForSms: state.connection
					.get('providersWaitingForSms')
					.toJS()
			};
		},
		{
			getProviders,
			getProviderType,
			addProfileFromProvider,
			requestNewProvider,
			getProvidersWaitingForSms,
			sendSmsCode,
			saveSettings,
			getSettings
		}
	)(Connection)
);
