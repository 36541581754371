export const styles = theme => ({
	healthSummariesGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
		display: 'flex',
		justifyContent: 'center'
	},
	content: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '0'
		}
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	}
});
