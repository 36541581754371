import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import VirtualizedTable from '@Components/VirtualizedTable/index';

import {
	Button,
	CssBaseline,
	Typography,
	Paper,
	TextField,
	Container
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
/* Clear information for selected person in left navigation */
import { clearSelectedPerson, setDefaultSelectedUser } from '@SharedActions';
import { styles } from './styles';

import { getUsers, updateUserType, setUserType } from './actions';

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			searchValue: ''
		};
	}

	componentDidMount = () => {
		this.loadUsersList();
		this.props.clearSelectedPerson();
		this.props.setDefaultSelectedUser();
	};

	loadUsersList = () => {
		const { searchValue } = this.state;
		this.props.getUsers(searchValue);
	};

	onFieldChange = (event, rowIndex) => {
		const newUserType = event.target.value.toUpperCase();

		this.props.setUserType({
			rowIndex,
			newUserType
		});
	};

	onFiledSearchChange = event => {
		this.setState({
			searchValue: event.target.value
		});
	};

	updateUserType = async (id, rowId) => {
		if (this.handleValidation(rowId)) {
			const { users } = this.props;
			const userData = {
				id,
				userType: users[rowId].userType
			};
			this.props.updateUserType(userData);
		}
	};

	refreshUserList = () => {
		this.props.getUsers('');
		this.setState({
			searchValue: ''
		});
	};

	handleValidation = index => {
		const field = this.props.users[index].userType;
		const { errors } = this.state;

		let formIsValid = true;

		if (field.trim().length === 0) {
			formIsValid = false;
			errors[index] = 'Field cannot be empty.';
		} else if (field !== 'BASIC' && field !== 'ADMIN') {
			formIsValid = false;
			errors[index] = `User type must be 'BASIC' or 'ADMIN'`;
		} else {
			errors[index] = '';
		}

		this.setState({
			errors
		});

		return formIsValid;
	};

	render = () => {
		const { users, classes } = this.props;
		const { searchValue, errors } = this.state;

		return (
			<Fragment>
				<CssBaseline />
				<main>
					<div className={classes.usersContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
							>
								Users Administration
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
							>
								Please see below information about users
							</Typography>
						</Container>
						<Container className={classes.usersGrid} maxWidth="md">
							<div className={classes.buttonsGrid}>
								<Button
									onClick={this.refreshUserList}
									color="primary"
									variant="contained"
									className={classes.refreshButton}
								>
									Refresh
								</Button>
								<Button
									onClick={this.loadUsersList}
									color="primary"
									variant="contained"
									className={classes.searchButton}
								>
									Search
								</Button>

								<TextField
									variant="outlined"
									required
									fullWidth
									id="search"
									label="Search"
									name="search"
									onChange={this.onFiledSearchChange}
									value={searchValue}
								/>
							</div>

							<Paper
								style={{
									height: ' calc(100vh - 400px)',
									width: '870px'
								}}
							>
								<VirtualizedTable
									rowCount={users.length}
									rowGetter={({ index }) => users[index]}
									rowHeight={100}
									columns={[
										{
											width: 250,
											label: 'Email',
											dataKey: 'email'
										},
										{
											width: 300,
											label: 'Full Name',
											dataKey: 'fullName'
										},
										{
											width: 200,
											label: 'User Type',
											dataKey: 'userType',
											customComponent: (
												userType,
												rowIndex
											) => (
												<div>
													<TextField
														variant="outlined"
														required
														fullWidth
														id="userType"
														label="User Type"
														name="userType"
														value={userType}
														error={errors[rowIndex]}
														helperText={
															errors[rowIndex]
														}
														onChange={event =>
															this.onFieldChange(
																event,
																rowIndex
															)
														}
													/>
												</div>
											)
										},
										{
											width: 120,
											label: 'Actions',
											dataKey: 'id',
											customComponent: (id, rowId) => (
												<Button
													onClick={() =>
														this.updateUserType(
															id,
															rowId
														)
													}
													variant="contained"
													color="primary"
												>
													Update
												</Button>
											)
										}
									]}
								/>
							</Paper>
						</Container>
					</div>
				</main>
			</Fragment>
		);
	};
}

Users.propTypes = {
	users: PropTypes.array.isRequired,
	getUsers: PropTypes.func.isRequired,
	clearSelectedPerson: PropTypes.func.isRequired,
	setDefaultSelectedUser: PropTypes.func.isRequired,
	setUserType: PropTypes.func.isRequired,
	updateUserType: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				users: state.admin.getIn(['adminUsers', 'users']).toJS()
			};
		},
		{
			getUsers,
			updateUserType,
			setUserType,
			clearSelectedPerson,
			setDefaultSelectedUser
		}
	)(Users)
);
