import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VirtualizedTable from '@Components/VirtualizedTable/index';

import {
	Button,
	CssBaseline,
	Typography,
	Paper,
	TextField,
	Container
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
/* Clear information for selected person in left navigation */
import { clearSelectedPerson, setDefaultSelectedUser } from '@SharedActions';

import { styles } from './styles';

import { getAllProfiles } from './actions';

class ProfilesList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchValue: ''
		};
	}

	componentDidMount = () => {
		this.getAllProfiles('', '', true);
		this.props.clearSelectedPerson();
		this.props.setDefaultSelectedUser();
	};

	onFiledSearchChange = event => {
		this.setState({
			searchValue: event.target.value
		});
	};

	getAllProfiles = (searchTags, endCursor, isSubmit) => {
		this.props.getAllProfiles({ searchTags, endCursor, isSubmit });
	};

	loadProfile = async profileData => {
		this.props.history.push({
			pathname: `/admin/allProfiles/profile/${profileData.profileId}`,
			state: {
				providerId: profileData.providerId,
				profileId: profileData.profileId,
				profileName: `${profileData.firstName} ${profileData.lastName}`
			}
		});
	};

	refreshProfileList = () => {
		this.getAllProfiles('', '', true);

		this.setState({
			searchValue: ''
		});
	};

	searchProfileList = () => {
		const { searchValue } = this.state;
		this.getAllProfiles(searchValue, '', true);
	};

	loadMore = () => {
		const { endCursor, hasMore } = this.props;
		const { searchValue } = this.state;
		if (hasMore) {
			this.getAllProfiles(searchValue, endCursor, false);
		}
	};

	render() {
		const { classes, profiles } = this.props;
		const { searchValue } = this.state;
		return (
			<Fragment>
				<CssBaseline />
				<main>
					<div className={classes.profileListContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
							>
								List of active profiles
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
							>
								Please see below information about active
								profiles
							</Typography>
						</Container>
						<Container
							className={classes.profileListGrid}
							maxWidth="md"
						>
							<div className={classes.buttonsGrid}>
								<Button
									onClick={this.refreshProfileList}
									color="primary"
									variant="contained"
									className={classes.refreshButton}
								>
									Refresh
								</Button>
								<Button
									onClick={this.searchProfileList}
									color="primary"
									variant="contained"
									className={classes.searchButton}
								>
									Search
								</Button>

								<TextField
									variant="outlined"
									required
									fullWidth
									id="search"
									label="Search"
									name="search"
									onChange={this.onFiledSearchChange}
									value={searchValue}
								/>
							</div>
							<Paper
								style={{
									height: ' calc(100vh - 400px)',
									width: '1000px'
								}}
							>
								<VirtualizedTable
									rowCount={profiles.length}
									rowGetter={({ index }) => profiles[index]}
									isRowLoaded={({ index }) =>
										!!profiles[index]
									}
									loadMore={this.loadMore}
									rowHeight={100}
									columns={[
										{
											width: 250,
											label: 'Email',
											dataKey: 'email'
										},
										{
											width: 150,
											label: 'First Name',
											dataKey: 'firstName'
										},
										{
											width: 150,
											label: 'Last Name',
											dataKey: 'lastName'
										},
										{
											width: 250,
											label: 'Address',
											dataKey: 'address'
										},
										{
											width: 200,
											label: 'Actions',
											dataKey: 'profileId',
											customComponent: (
												profileId,
												rowIndex
											) => (
												<Button
													onClick={() =>
														this.loadProfile(
															profiles[rowIndex]
														)
													}
													variant="contained"
													color="primary"
												>
													Show Profile
												</Button>
											)
										}
									]}
								/>
							</Paper>
						</Container>
					</div>
				</main>
			</Fragment>
		);
	}
}

ProfilesList.propTypes = {
	profiles: PropTypes.array.isRequired,
	endCursor: PropTypes.string.isRequired,
	hasMore: PropTypes.bool.isRequired,
	getAllProfiles: PropTypes.func.isRequired,
	clearSelectedPerson: PropTypes.func.isRequired,
	setDefaultSelectedUser: PropTypes.func.isRequired,
	history: PropTypes.any.isRequired,
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				profiles: state.admin.getIn(['profiles', 'profiles']).toJS(),
				endCursor: state.admin.getIn(['profiles', 'endCursor']),
				hasMore: state.admin.getIn(['profiles', 'hasMore'])
			};
		},
		{
			getAllProfiles,
			clearSelectedPerson,
			setDefaultSelectedUser
		}
	)(ProfilesList)
);
