import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
	List,
	WindowScroller,
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache
} from 'react-virtualized';
import Table from '../NestedTable/index';
import { sectionTitlesMap } from '../../constants';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular
	},
	header: {
		padding: '20px'
	}
}));

const headCells = isNested => {
	return [
		!isNested && {
			id: 'info',
			numeric: false,
			disablePadding: false,
			label: 'Info'
		},
		{
			id: 'profile',
			numeric: false,
			disablePadding: false,
			label: 'Profile'
		},
		{
			id: 'provider',
			numeric: false,
			disablePadding: false,
			label: 'Provider'
		}
	];
};

const saveFile = (e, fileInfo) => {
	e.preventDefault();
	e.stopPropagation();
	const downloadLink = document.createElement('a');
	downloadLink.setAttribute('href', fileInfo.fileUrl);
	downloadLink.setAttribute('target', '_blank');
	downloadLink.setAttribute('download', fileInfo.name);
	downloadLink.style.display = 'none';
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};

const filesCustomComponent = fileInfo => {
	return (
		<Button
			onClick={e => saveFile(e, fileInfo)}
			variant="contained"
			color="primary"
			style={{ width: '120px' }}
		>
			Open
		</Button>
	);
};

function rowRenderer(
	{
		key, // Unique key within array of rows
		index, // Index of row within collection
		parent,
		isScrolling, // The List is currently being scrolled
		isVisible, // This row is visible within the List (eg it is not an overscanned row)
		style // Style object to be applied to row (to position it)
	},
	data,
	cache
) {
	const { isNested } = data[index];

	return (
		<CellMeasurer
			key={key}
			cache={cache}
			parent={parent}
			columnIndex={0}
			rowIndex={index}
		>
			{/* /* HOT FIX - BUG - MYP-226 - hide scroll on tables and set scroll on parent element - Check ISSUE in react-virtualized and when this is fixed, just remove this code - https://github.com/bvaughn/react-virtualized/issues/1339   */}
			<div key={key} style={{ ...style, minWidth: '750px' }}>
				{data[index].categoryName === 'title' ? (
					<Typography
						align="left"
						gutterBottom
						variant="h5"
						component="h2"
						style={{
							marginTop: '10px',
							marginLeft: '10px'
						}}
					>
						{data[index].values}
					</Typography>
				) : (
					<div
						style={{
							backgroundColor: 'rgb(247,247,247)',
							border: '1px solid rgb(192 192 192)',
							minWidth: '750px'
						}}
					>
						<Typography
							style={{
								fontWeight: 'bold',
								marginLeft: '15px',
								paddingTop: '20px'
							}}
						>
							{sectionTitlesMap[data[index].categoryName] ||
								data[index].categoryName}
						</Typography>
						<Table
							rows={data[index].values}
							headCells={headCells(isNested)}
							isNested={isNested}
							customMap={{
								component: filesCustomComponent,
								key: 'fileUrl'
							}}
						/>
					</div>
				)}
			</div>
		</CellMeasurer>
	);
}

const SummaryContent = props => {
	const classes = useStyles();
	const { data, scrollElement } = props;

	const cache = new CellMeasurerCache({
		fixedWidth: true,
		defaultHeight: 50
	});

	const getDynamicHeight = e => cache.rowHeight(e) + 20;

	return (
		<div className={classes.root}>
			<WindowScroller
				scrollElement={
					scrollElement && scrollElement.current !== null
						? scrollElement.current
						: window
				}
			>
				{({
					height,
					isScrolling,
					registerChild,
					onChildScroll,
					scrollTop
				}) => (
					<div style={{ minWidth: '750px' }}>
						<AutoSizer disableHeight>
							{({ width }) => (
								<div ref={registerChild}>
									<List
										autoHeight
										ref={el => {
											window.listEl = el;
										}}
										height={height}
										isScrolling={isScrolling}
										rowCount={data.length}
										rowHeight={getDynamicHeight}
										rowRenderer={e =>
											rowRenderer(e, data, cache)
										}
										scrollTop={scrollTop}
										width={width}
										overscanRowCount={2}
										onScroll={onChildScroll}
										deferredMeasurementCache={cache}
									/>
								</div>
							)}
						</AutoSizer>
					</div>
				)}
			</WindowScroller>
		</div>
	);
};

SummaryContent.propTypes = {
	data: PropTypes.array.isRequired,
	scrollElement: PropTypes.any.isRequired
};

export default SummaryContent;
