/* eslint-disable react/no-unescaped-entities */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
	Typography,
	IconButton,
	InputLabel,
	FormControl,
	Input,
	Container,
	InputAdornment,
	Grid,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		width: '100%'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	providerName: {
		textTransform: 'capitalize'
	},
	selectedÏnformationGrid: {
		marginBottom: '10px',
		textAlign: 'center'
	},
	textField: {
		width: '300px'
	}
}));

const ReviewComponent = ({
	values,
	providers,
	isConsent,
	handleConsentChange
}) => {
	const classes = useStyles();

	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const selectedProvider = providers.find(
		item => item.id === values.selectedProviderId
	);

	return (
		<Fragment>
			<Container maxWidth="md">
				<Grid
					container
					className={classes.selectedÏnformationGrid}
					spacing={4}
				>
					<Grid item md={12} xs={12}>
						<Typography variant="h6" align="center" paragraph>
							Your selected provider information is:
						</Typography>
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							label="Provider Name"
							defaultValue={selectedProvider.name}
							value={selectedProvider.name}
							className={classes.textField}
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							label="Provider Username"
							defaultValue={values.providerUsername}
							value={values.providerUsername}
							className={classes.textField}
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item md={12} xs={12}>
						<FormControl>
							<InputLabel htmlFor="provider-password">
								Provider Password
							</InputLabel>
							<Input
								id="provider-password"
								type={showPassword ? 'text' : 'password'}
								value={values.providerPassword}
								defaultValue={values.providerPassword}
								className={classes.textField}
								InputProps={{
									readOnly: true
								}}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
										>
											{showPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</Grid>
					<Grid item md={12} xs={12}>
						<FormGroup row>
							<FormControlLabel
								style={{
									display: 'flex',
									alignItems: 'flex-start'
								}}
								control={
									<Checkbox
										checked={isConsent}
										onChange={handleConsentChange}
										name="isConsent"
										color="primary"
									/>
								}
								label={
									<Typography
										variant="h6"
										align="center"
										paragraph
									>
										I am authorized to access this
										information and share it. I consent to
										share this data with MyPHI and to the
										other providers in my account per my
										settings
									</Typography>
								}
							/>
						</FormGroup>
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
};

ReviewComponent.propTypes = {
	values: PropTypes.object.isRequired,
	providers: PropTypes.array.isRequired,
	isConsent: PropTypes.bool.isRequired,
	handleConsentChange: PropTypes.func.isRequired
};

export default ReviewComponent;
