export const styles = theme => ({
	dashboardContent: {
		padding: theme.spacing(3)
	},
	timelineGrid: {
		paddingTop: `${theme.spacing(4)}px !important`
	},
	card: {
		maxWidth: 400,
		minWidth: 400,
		borderRadius: 0
	},
	cardMedia: {
		height: 240
	},
	iconButton: {
		transform: 'scale(0.8)'
	},
	link: {
		textDecoration: 'none',
		color: 'rgba(0, 0, 0, 0.87)'
	},
	avatarContent: {
		display: 'flex'
	},
	avatar: {
		display: 'flex',
		height: '140px',
		width: '140px'
	},
	userAction: {
		display: 'flex',
		cursor: 'pointer'
	},
	actionIcon: {
		marginRight: '10px'
	},
	cardWrapper: {
		margin: '0 auto'
	}
});
