import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import TempLinkButton from '../TempLinkButton/index';
import DeleteProfileDialog from '../DeleteProfileDialog/index';

const useStyles = makeStyles(theme => ({
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	cardContent: {
		flexGrow: 1
	},
	buttonsWrapper: {
		justifyContent: 'space-between',
		display: 'flex'
	},
	markButtonsWrapper: {
		justifyContent: 'space-around',
		display: 'flex'
	},
	header: {
		marginBottom: '20px'
	},
	button: {
		width: '100%',
		cursor: 'pointer',
		textAlign: 'center',

		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	}
}));

const ActionButtons = props => {
	const classes = useStyles();

	const {
		providerName,
		selectedPerson,
		showVisibilitySettings,
		handleShowVisibilitySettings,
		updateVisibilitySettings,
		markAll
	} = props;

	const isSelfProvider =
		providerName ===
		'self provider'; /* Add field in data base about that */

	return (
		<Grid item xs={12} sm={12} md={12}>
			<Card className={classes.card}>
				<CardContent className={classes.cardContent}>
					<Grid container spacing={4}>
						<Grid
							item
							xs={12}
							md={12}
							className={classes.buttonsWrapper}
						>
							<TempLinkButton
								selectedPerson={selectedPerson}
								handleSendTemporaryLinkData={
									props.handleSendTemporaryLinkData
								}
							/>
						</Grid>
						{!isSelfProvider && (
							<Grid
								item
								xs={12}
								md={12}
								className={classes.buttonsWrapper}
							>
								<DeleteProfileDialog
									deleteProfile={props.deleteProfile}
								/>
							</Grid>
						)}

						<Grid
							item
							xs={12}
							md={12}
							className={classes.buttonsWrapper}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={handleShowVisibilitySettings}
								className={classes.button}
							>
								{showVisibilitySettings
									? 'Hide visibility settings'
									: 'Show visibility settings'}
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							className={classes.buttonsWrapper}
						>
							{showVisibilitySettings && (
								<Button
									variant="contained"
									color="primary"
									className={classes.button}
									onClick={updateVisibilitySettings}
								>
									Update visibility settings
								</Button>
							)}
						</Grid>
						{showVisibilitySettings && (
							<React.Fragment>
								<Grid
									item
									xs={12}
									md={12}
									className={classes.markButtonsWrapper}
								>
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										onClick={() => markAll(true)}
									>
										Mark All
									</Button>
								</Grid>
								<Grid
									item
									xs={12}
									md={12}
									className={classes.markButtonsWrapper}
								>
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										onClick={() => markAll(false)}
									>
										Unmark All
									</Button>
								</Grid>
							</React.Fragment>
						)}
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

ActionButtons.propTypes = {
	providerName: PropTypes.string.isRequired,
	selectedPerson: PropTypes.object.isRequired,
	handleShowVisibilitySettings: PropTypes.func.isRequired,
	updateVisibilitySettings: PropTypes.func.isRequired,
	markAll: PropTypes.func.isRequired,
	handleSendTemporaryLinkData: PropTypes.func.isRequired,
	deleteProfile: PropTypes.func.isRequired,
	showVisibilitySettings: PropTypes.bool.isRequired
};

export default ActionButtons;
