/* eslint-disable array-callback-return */
import { sectionTitlesMap } from '../../constants';

export const descendingComparator = (a, b, orderBy) => {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
};

export const getComparator = (order, orderBy) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
};

export const generateNestedHeadCells = (obj, customMap = {}) => {
	if (!obj) {
		return [];
	}

	const cellKeys = Object.keys(obj).sort();

	// eslint-disable-next-line consistent-return
	const cells = cellKeys.map(key => {
		if (typeof obj[key] === 'string') {
			const isCustom = key === customMap.key;
			return {
				id: isCustom ? 'custom' : key,
				isCustom,
				numeric: false,
				disablePadding: false,
				label: sectionTitlesMap[key] || key,
				customComponent: isCustom ? customMap.component : () => {},
				isDateFormat:
					key === 'date' ||
					key === 'when' ||
					key === 'date1' ||
					key === 'date2' ||
					key === 'date3' ||
					key === 'date4' ||
					key === 'date5'
			};
		}
	});

	return cells.filter(element => {
		return element !== undefined;
	});
};
