export const styles = theme => ({
	profileCreateContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 0)
	},
	profileCrateGrid: {
		width: '100%',
		paddingTop: 0,
		paddingBottom: theme.spacing(8),
		[theme.breakpoints.down('sm')]: {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	},
	subHeader: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.00rem'
		}
	},
	button: {
		marginRight: '24px',
		marginLeft: '24px'
	},

	stepperWrapper: {
		width: '100%'
	},
	requestContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		marginTop: '50px'
	},
	smsContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		marginTop: '50px'
	},
	buttonsWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingLeft: '30px',
		paddingRight: '30px'
	},
	stepLabel: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.675rem'
		}
	}
});
