import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import { Avatar, Grid, Typography } from '@material-ui/core';

import {
	setSelectedUser,
	setDefaultSelectedUser,
	setSelectedPerson
} from '@SharedActions';
import EditPersonModal from '@Components/Modals/EditPersonModal';

import News from '@Components/News';

import { styles } from './styles';
import {
	getPersonsList,
	photoUpload,
	updatePerson,
	getUserNews,
	addPerson
} from './actions';

import cookies from '../../utilities/cookies';

class Persons extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openEditPersonModal: false
		};
	}

	componentDidMount = async () => {
		const { history } = this.props;
		cookies.remove('person');
		const { userId } = this.props.loggedAccountData;
		await this.props.setSelectedPerson({});
		await this.props.setDefaultSelectedUser({});

		await this.handleGetPersonsList();
		await this.props.getUserNews(userId);
		/* Set creation dialog open, when user log in after registration  */
		this.setState({
			openEditPersonModal:
				(history.location.state &&
					history.location.state.isCreateDialogOpen) ||
				false
		});

		history.replace(history.location.pathname, {
			isCreateDialogOpen: false
		});
	};

	handleGetPersonsList = async () => {
		const { userId } = this.props.loggedAccountData;

		await this.props.getPersonsList(userId);
	};

	loadDashboard = person => {
		const personId = person.id;
		this.props.setSelectedPerson(person);

		this.props.setSelectedUser({
			name: person.personName,
			id: person.id,
			photo: person.photo
		});

		cookies.set('person', personId);
	};

	handleClose = () => { };

	handleCloseEditPersonModal = () => {
		this.setState({ openEditPersonModal: false });
	};

	editPerson = async (personData, formData) => {
		try {
			if (formData) {
				const res = await this.props.photoUpload(formData);
				// eslint-disable-next-line no-param-reassign
				personData.photo = res.response.get('fileName');
			}

			this.updatePerson({
				...personData
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	updatePerson = async personData => {
		try {
			await this.props.updatePerson(personData);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	render = () => {
		const { personsList, classes, news, loggedAccountData } = this.props;
		const { openEditPersonModal } = this.state;

		return (
			<>
				<Grid container spacing={3}>
					<Grid item xs={12} sm md={6}>
						<Grid container item spacing={4}>
							{personsList.map(person => (
								<Grid
									item
									key={person.id}
									xs={12}
									sm
									md={6}
									className={classes.avatarWrapper}
								>
									<Link
										onClick={() =>
											this.loadDashboard(person)
										}
										className={classes.link}
										to={{
											pathname: `/dashboard/${person.id}`,
											state: {
												person: {
													id: person.id,
													name: person.personName,
													photo: person.photo
												}
											},
											pathInfo: person.personName
										}}
									>
										<Avatar
											className={classes.avatar}
											alt={person.personName}
											src={person.photo}
										/>
										<Typography
											className={classes.personName}
										>
											{person.personName}
										</Typography>
									</Link>
								</Grid>
							))}
						</Grid>
					</Grid>
					<Grid item xs={12} sm md={6}>
						{news.length > 0 ? (
							<News props={this.props}
								data={news}
								// selectedPerson={selectedPerson}
								person={personsList[0]}
								loggedAccountData={loggedAccountData}
								addPerson = {this.props.addPerson}
								photoUpload = {this.props.photoUpload}
							/>
						) : (
							<Typography
								variant="h6"
								align="center"
								color="textSecondary"
								className={classes.subHeader}
								style={{
									marginTop: '100px'
								}}
							>
								This account has no news data
							</Typography>
						)}
					</Grid>
				</Grid>
				{openEditPersonModal && (
					<EditPersonModal
						open={openEditPersonModal}
						editPersonHandler={this.editPerson}
						person={{
							name: personsList[0].personName,
							photo: personsList[0].photo,
							id: personsList[0].id
						}}
						onClose={this.handleCloseEditPersonModal}
					/>
				)}
			</>
		);
	};
}

Persons.propTypes = {
	classes: PropTypes.object.isRequired,
	personsList: PropTypes.array.isRequired,
	getPersonsList: PropTypes.func.isRequired,
	photoUpload: PropTypes.func.isRequired,
	setSelectedPerson: PropTypes.func.isRequired,
	setSelectedUser: PropTypes.func.isRequired,
	setDefaultSelectedUser: PropTypes.func.isRequired,
	history: PropTypes.any.isRequired,
	updatePerson: PropTypes.func.isRequired,
	loggedAccountData: PropTypes.object.isRequired,
	getUserNews: PropTypes.func.isRequired,
	news: PropTypes.array.isRequired,
	addPerson: PropTypes.func.isRequired,
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				personsList: state.persons.get('personsList').toJS(),
				loggedAccountData: state.general
					.get('loggedAccountData')
					.toJS(),
				news: state.persons.get('news').toJS()
			};
		},
		{
			getPersonsList,
			photoUpload,
			setSelectedPerson,
			setSelectedUser,
			setDefaultSelectedUser,
			updatePerson,
			getUserNews,
			addPerson,
		}
	)(Persons)
);
