export const UPDATE_PERSON = Symbol('UPDATE_PERSON');
export const PHOTO_UPLOAD = Symbol('PHOTO_UPLOAD');
export const DELETE_PERSON = Symbol('DELETE_PERSON');
export const GET_PERSON = Symbol('GET_PERSON');
export const GET_PROFILES = Symbol('GET_PROFILES');
export const GET_PROVIDERS_WAITING_FOR_SMS = Symbol(
	'GET_PROVIDERS_WAITING_FOR_SMS'
);
export const SEND_SMS_CODE = Symbol('SEND_SMS_CODE');
export const GET_TIMELINE_LIST = Symbol('GET_TIMELINE_LIST');
export const GET_CONTENT = Symbol('GET_CONTENT');
export const GET_PERSON_NEWS = Symbol('GET_PERSON_NEWS');
