import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CssBaseline, Grid, Container } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@Components/TabPanel';

import {
	getAllProviders,
	addProfile,
	fileUpload,
	setDefaultState,
	getTextFromImage
} from './actions';

import { createProfileStyles } from './styles';

import Form from '../../components/Form/index';
import TextImageDialog from '../../components/TextImageDialog/index';

class CreateSelfProfile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: 0
		};
	}

	componentDidMount = async () => {
		this.props.getAllProviders();
	};

	handleChange = (event, newValue) => {
		this.props.setDefaultState();
		this.setState({ value: newValue });
	};

	handleChangeIndex = index => {
		this.setState({ value: index });
	};

	componentWillUnmount = () => {
		this.props.setDefaultState();
	};

	addProfile = async (profile, providerType, files) => {
		const { userId } = this.props.loggedAccountData;

		const { person } = this.props.history.location.state;

		let formData;

		const provider = this.props.providers.find(pr => {
			return (
				pr.name === 'self provider' &&
				pr.providerTypeName.toLowerCase() === providerType.toLowerCase()
			);
		});

		if (files && files.length !== 0) {
			formData = new FormData();

			files.forEach(file => {
				formData.append('avatar', file.file);
			});
		}

		const profileData = {
			userId,
			personId: {
				id: person.id
			},
			providerId: provider.id,
			profile
		};

		try {
			const addedProfile = await this.props.addProfile(
				profileData,
				providerType
			);
			if (addedProfile.response && formData) {
				formData.append('profileId', addedProfile.response.toJS().id);
				try {
					await this.props.fileUpload(formData);
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				}
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	redirect = path => {
		const { person } = this.props.history.location.state;
		this.props.history.push({
			pathname: `/dashboard/${person.id}`,
			state: { person }
		});
	};

	handleGetOCR = async file => {
		const formData = new FormData();
		formData.append('avatar', file.file);
		await this.props.getTextFromImage(formData);
	};

	render() {
		const { classes, profile, imageText } = this.props;
		const { value } = this.state;

		const { showMenu, type, person } = this.props.history.location.state;

		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					<div className={classes.heroContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Add Health and Educational Information
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								{`Please provide information about `}
								<span
									style={{
										textTransform: 'capitalize'
									}}
								>
									{person.name}
								</span>
							</Typography>
						</Container>
					</div>
					<Container className={classes.cardGrid} maxWidth="md">
						<Grid container spacing={4}>
							{showMenu ? (
								<div className={classes.tabsContainer}>
									<AppBar position="static" color="default">
										<Tabs
											value={value}
											onChange={this.handleChange}
											indicatorColor="primary"
											textColor="primary"
											variant="fullWidth"
											aria-label="full width tabs example"
										>
											<Tab label="Medical" />
											<Tab label="Education" />
										</Tabs>
									</AppBar>

									<SwipeableViews
										axis="x-reverse"
										index={value}
										onChangeIndex={this.handleChangeIndex}
									>
										<TabPanel
											value={value}
											index={0}
											dir="ltr"
										>
											<Form
												type="myChart"
												action={this.addProfile}
												data={profile}
												redirect={this.redirect}
												handleGetOCR={this.handleGetOCR}
											/>
										</TabPanel>
										<TabPanel
											value={value}
											index={1}
											dir="ltr"
										>
											<Form
												type="education"
												action={this.addProfile}
												data={profile}
												redirect={this.redirect}
												handleGetOCR={this.handleGetOCR}
											/>
										</TabPanel>
									</SwipeableViews>
								</div>
							) : (
								<Form
									type={type}
									action={this.addProfile}
									data={profile}
									redirect={this.redirect}
									handleGetOCR={this.handleGetOCR}
								/>
							)}
						</Grid>
					</Container>
				</main>
				<TextImageDialog text={imageText} />
			</React.Fragment>
		);
	}
}

CreateSelfProfile.propTypes = {
	classes: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	loggedAccountData: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	providers: PropTypes.array.isRequired,
	getAllProviders: PropTypes.func.isRequired,
	addProfile: PropTypes.func.isRequired,
	imageText: PropTypes.string.isRequired,
	fileUpload: PropTypes.func.isRequired,
	setDefaultState: PropTypes.func.isRequired,
	getTextFromImage: PropTypes.func.isRequired
};

export default withStyles(createProfileStyles)(
	connect(
		state => {
			return {
				providers: state.selfProfile
					.getIn(['createSelfProfile', 'providers'])
					.toJS(),
				profile: state.selfProfile
					.getIn(['createSelfProfile', 'profileData'])
					.toJS(),
				imageText: state.selfProfile.getIn([
					'createSelfProfile',
					'imageText'
				]),
				loggedAccountData: state.general.get('loggedAccountData').toJS()
			};
		},
		{
			getAllProviders,
			addProfile,
			fileUpload,
			setDefaultState,
			getTextFromImage
		}
	)(CreateSelfProfile)
);
