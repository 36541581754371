/* eslint-disable react/no-unescaped-entities */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120,
		width: '100%'
	}
}));

const TwoFactorAuthenticationForm = props => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [providerId, setProviderType] = useState('');
	const [smsCode, setSmsCode] = useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setProviderType('');
		setSmsCode('');
	};

	const handleFieldChange = event => {
		setSmsCode(event.target.value);
	};

	const handleChange = event => {
		setProviderType(event.target.value);
	};

	const sendSmsCode = () => {
		props.sendSmsCode(smsCode, providerId);
		handleClose();
	};

	return (
		<Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
			>
				Send sms code
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Send SMS Code</DialogTitle>
				<DialogContent>
					<DialogContentText>
						If your provider has has two factor authentication
						enabled and requires SMS code - after you receive the
						code please select the provider from the list below,
						enter the code and click the "SEND SMS CODE" button
					</DialogContentText>
					<FormControl
						variant="outlined"
						className={classes.formControl}
					>
						<InputLabel id="provider-type-select-label">
							Provider Type
						</InputLabel>
						<Select
							labelId="provider-type-select-label"
							id="provider-type-select"
							value={providerId}
							onChange={handleChange}
							label="Provider Type"
						>
							{props.providersWaitingForSms.map(provider => {
								return (
									!provider.code && (
										<MenuItem
											key={provider.id}
											value={provider.id}
										>
											{`${provider.providerName} - ${provider.userName}`}
										</MenuItem>
									)
								);
							})}
						</Select>
					</FormControl>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="smsCode"
						label="SMS Code"
						name="smsCode"
						value={smsCode}
						onChange={handleFieldChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={sendSmsCode} color="primary">
						Send Sms Code
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

TwoFactorAuthenticationForm.propTypes = {
	sendSmsCode: PropTypes.func.isRequired,
	providersWaitingForSms: PropTypes.array.isRequired
};

export default TwoFactorAuthenticationForm;
