/* eslint-disable array-callback-return */
/* eslint-disable react/no-danger */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@Components/NestedTable/index';
import { sectionTitlesMap } from './constants';

const useStyles = props =>
	makeStyles(theme => ({
		linkButton: {
			cursor: 'pointer',
			marginLeft: '5px',
			marginRight: '5px',
			position: 'relative',
			fontSize: props.isSmallContainer ? '0.80rem' : '1rem',
			top: props.isSmallContainer ? '-4px' : '-10px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '0.80rem',
				top: '-4px'
			}
		}
	}));

const generateNestedHeadCells = info => {
	// eslint-disable-next-line consistent-return
	const cells = Object.keys(info).map(key => {
		if (typeof info[key] === 'string') {
			return {
				id: key,
				numeric: false,
				disablePadding: false,
				label: sectionTitlesMap[key] || key,
				isDateFormat:
					key === 'date' ||
					key === 'when' ||
					key === 'date1' ||
					key === 'date2' ||
					key === 'date3' ||
					key === 'date4' ||
					key === 'dateOfBirth' ||
					key === 'date5'
			};
		}
	});

	return cells.filter(element => {
		return element !== undefined;
	});
};

const TimelineInfoDialog = props => {
	const classes = useStyles(props)();
	const { data, title, subTitle, type, getContent } = props;

	const [open, setOpen] = useState(false);

	const [visitContent, setVisitContent] = useState('');

	const handleClickOpen = async () => {
		setOpen(true);

		if (type === 'visitNotes') {
			const result = await getContent(data.content);
			setVisitContent(result.response);
		}
	};

	const handleClose = () => {
		setOpen(false);
		setVisitContent('');
	};

	let isNested = '';
	Object.keys(data).map(key => {
		if (Array.isArray(data[key]) && data[key].length > 0) {
			isNested = key;
		}
	});

	return (
		<Fragment>
			<Link onClick={handleClickOpen} className={classes.linkButton}>
				details
			</Link>

			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={false}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{subTitle}</DialogContentText>
					{type !== 'visitNotes' ? (
						<Table
							rows={[data]}
							headCells={generateNestedHeadCells(data)}
							showVisibilitySettings={false}
							selectedValues={[]}
							nestedRowsColumns={
								!isNested
									? []
									: generateNestedHeadCells(data[isNested][0])
							}
							isCollapsible={!!isNested}
							isCollapsibleOpenByDefault={!!isNested}
							nestedRowName={!isNested ? [] : isNested}
						/>
					) : (
						<div
							className="visits_content_wrapper"
							dangerouslySetInnerHTML={{
								__html: visitContent
							}}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

TimelineInfoDialog.propTypes = {
	data: PropTypes.array.isRequired,
	getContent: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string.isRequired
};

export default TimelineInfoDialog;
