import * as actionTypes from './actionTypes';

export const getSettings = personId => {
	return {
		type: actionTypes.GET_SETTINGS,
		requestType: 'GET',
		request: `/settings?personId=${personId}`,
		body: {}
	};
};

export const changeProviderConsent = (isChecked, providerId) => {
	return {
		type: actionTypes.CHANGE_PROVIDER_CONSENT,
		body: { isChecked, providerId }
	};
};

export const changeTempField = value => {
	return {
		type: actionTypes.CHANGE_TEMP_FIELD,
		body: { value }
	};
};

export const saveSettings = (settings, personId) => {
	return {
		type: actionTypes.SAVE_SETTINGS,
		requestType: 'POST',
		request: `/settings`,
		message: 'Settings are saved',
		body: {
			settings,
			personId
		}
	};
};

export const getProviders = () => {
	return {
		type: actionTypes.GET_PROVIDERS,
		requestType: 'GET',
		request: `/providers?excludeSelf=true`
	};
};
