/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	moveButton: {
		width: '100%',
		cursor: 'pointer',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	},
	formControl: {
		width: '100%'
	}
}));

const MoveProfileDialog = props => {
	const classes = useStyles();
	const { personsList, currentPersonId } = props;
	const [open, setOpen] = useState(false);
	const [personId, setPerson] = useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = event => {
		setPerson(event.target.value);
	};

	const handleMoveProfile = () => {
		props.moveProfile(personId);
		setOpen(false);
	};

	return (
		<Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				className={classes.moveButton}
			>
				Move Profile
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="move-profile"
				className={classes.root}
			>
				<DialogTitle id="move-profile">Move Profile</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please choose where you want to move the profile.
					</DialogContentText>
					<FormControl
						variant="outlined"
						className={classes.formControl}
					>
						<Select
							labelId="persons-select-label"
							id="persons-select"
							value={personId}
							onChange={handleChange}
						>
							{personsList.map(item => {
								if (currentPersonId !== item.id) {
									return (
										<MenuItem value={item.id}>
											{item.personName}
										</MenuItem>
									);
								}
							})}
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button
						disabled={!personId}
						onClick={handleMoveProfile}
						color="primary"
					>
						Move Profile
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

MoveProfileDialog.propTypes = {
	moveProfile: PropTypes.func.isRequired,
	personsList: PropTypes.array.isRequired,
	currentPersonId: PropTypes.string.isRequired
};

export default MoveProfileDialog;
