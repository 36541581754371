import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CssBaseline, Typography, Container, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import TimelineData from '@Components/TimelineList/TimelineData/index';

import { getTimelineList, getContent } from './actions';

import { styles } from './styles';
import { Link as RouterLink } from 'react-router-dom';
class Timeline extends Component {
	constructor(props) {
		super(props);
		this.timelineRef = React.createRef();
	}

	componentDidMount = () => {
		this.props.getTimelineList(this.props.match.params.personId);
	};

	render = () => {
		const { classes, timelineData } = this.props;
		const person = this.props.location.state.person;
		return (
			<React.Fragment>
				<CssBaseline />
				<main ref={this.timelineRef} className={classes.mainContainer}>
					<div className={classes.timelineContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Timeline
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Please see below the summarized medical events
								timeline
							</Typography>
						</Container>

						<Container
							className={classes.timelineContentGrid}
							maxWidth="md"
						>
							<Grid style={{ marginBottom: '10px' }} container>
								<Grid item md={12} xs={12}>
									{timelineData.length > 0 ? (
										<TimelineData
											scrollElement={this.timelineRef}
											data={timelineData}
											getContent={this.props.getContent}
										/>
									) : (
										(person.profileId ?
											<Typography
												variant="h6"
												align="center"
												color="textSecondary"
												className={classes.subHeader}
												style={{
													marginTop: '100px'
												}}
											>

												Add some Health Data to see it on the timeline!&nbsp;
												<RouterLink
													style={{ color: 'inherit' }}
													component={Typography}
													to={{
														pathname: `/dashboard/${person.id}/profiles/${person.profileId}`,
														state: {
															person,
															profile: {
																profileDetailId:
																	person.profileDetailId,
																id: person.profileId,
																name:
																	person.profileName,
																provider: {
																	id:
																		person.providerId
																}
															}
														}
													}}
												>
													{'Enter your health data'}
												</RouterLink>
												&nbsp;or&nbsp;
												<RouterLink
													style={{ color: 'inherit' }}
													component={Typography}
													to={{
														pathname: `/dashboard/${person.id}/new-provider-profile`,
														state: {
															person
														}
													}}
												>
													{'Add connection'}
												</RouterLink>
												&nbsp;to a patient portal!
											</Typography> :
											<Typography
												variant="h6"
												align="center"
												color="textSecondary"
												className={classes.subHeader}
												style={{
													marginTop: '100px'
												}}
											>

												This person has no timeline data!
											</Typography>)
									)}
								</Grid>
							</Grid>
						</Container>
					</div>
				</main>
			</React.Fragment>
		);
	};
}

Timeline.propTypes = {
	classes: PropTypes.object.isRequired,
	timelineData: PropTypes.array.isRequired,
	getContent: PropTypes.func.isRequired,
	getTimelineList: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			personId: PropTypes.string.isRequired
		})
	})
};

Timeline.defaultProps = {
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				timelineData: state.timeline.get('timelineData').toJS()
			};
		},
		{ getTimelineList, getContent }
	)(Timeline)
);
