import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Button,
	CssBaseline,
	Grid,
	Typography,
	Container
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import {
	getSettings,
	saveSettings,
	changeProviderConsent,
	changeTempField,
	getProviders
} from './actions';

import Select from './components/SettingsSelect/index';
import ConsentPdfDialog from './components/ConsentPdfDialog/index';
import ProvidersContainer from './components/ProvidersContainer/index';

import { styles } from './styles';

import { UPDATE_OPTIONS, TEMP_LINK_OPTIONS } from './constants';

class Consent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			person: {}
		};
	}

	componentDidMount = async () => {
		const { person } = this.props.location.state;

		await this.props.getProviders();

		await this.props.getSettings(person.id);

		this.setState({
			person
		});
	};

	handleSwitchChange = (event, providerId) => {
		this.props.changeProviderConsent(event.target.checked, providerId);
	};

	handleFieldChange = event => {
		this.props.changeTempField(event.target.value);
	};

	handleSaveSettings = () => {
		const { settings } = this.props;
		const { person } = this.state;
		this.props.saveSettings(settings, person.id);
	};

	render = () => {
		const { settings, classes, providers } = this.props;
		const { person } = this.state;

		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					<div className={classes.settingsContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								{`Settings - ${person.name}`}
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								These are settings that apply to all the people
								in your MyPHI group. Changing them here changes
								the settings for everyone.
							</Typography>
						</Container>
						<Container
							className={classes.settingsGrid}
							maxWidth="md"
						>
							<Typography
								align="center"
								variant="h5"
								component="h5"
								className={classes.sectionHeader}
							>
								Consent
							</Typography>

							<ProvidersContainer
								settings={settings}
								handleSwitchChange={this.handleSwitchChange}
								providers={providers}
							/>

							<ConsentPdfDialog />

							<Typography
								align="center"
								variant="h5"
								component="h5"
								className={classes.sectionHeader}
							>
								Temporary link
							</Typography>

							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								When sending out a temporty link to a Summary
								Report, this will determine the length of time
								the link will work. After that time, you will
								have to recreate a link and resend it to
								recipients.
							</Typography>

							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Select
									options={TEMP_LINK_OPTIONS}
									value={settings.timeElapsed}
									title="Temp Link Duration"
									handleChange={this.handleFieldChange}
								/>
							</Grid>

							<Typography
								align="center"
								variant="h5"
								component="h5"
								className={classes.sectionHeader}
							>
								Profile Update Time
							</Typography>

							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Please choose how often your information to be
								updated.
							</Typography>

							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Select
									options={UPDATE_OPTIONS}
									title="Update Time"
								/>
							</Grid>

							<Grid
								container
								direction="row"
								justify="flex-end"
								alignItems="center"
							>
								<Button
									variant="contained"
									color="primary"
									className={classes.saveButton}
									onClick={this.handleSaveSettings}
								>
									Save
								</Button>
							</Grid>
						</Container>
					</div>
				</main>
			</React.Fragment>
		);
	};
}

Consent.propTypes = {
	classes: PropTypes.object.isRequired,
	getSettings: PropTypes.func.isRequired,
	getProviders: PropTypes.func.isRequired,
	providers: PropTypes.array.isRequired,
	saveSettings: PropTypes.func.isRequired,
	changeTempField: PropTypes.func.isRequired,
	changeProviderConsent: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired,
	location: PropTypes.shape({
		state: PropTypes.shape({
			person: PropTypes.object.isRequired
		})
	})
};

Consent.defaultProps = {
	location: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				settings: state.consent.get('settings').toJS(),
				providers: state.consent.get('providers').toJS()
			};
		},
		{
			getSettings,
			saveSettings,
			changeProviderConsent,
			changeTempField,
			getProviders
		}
	)(Consent)
);
