/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	formControl: {
		width: '100%'
	}
}));

const MoveVisitDialog = props => {
	const classes = useStyles();
	const { personsList, currentPersonId } = props;
	const [open, setOpen] = useState(false);
	const [personId, setPerson] = useState('');
	const { userId } = props.loggedAccountData;

	useEffect(() => {
		props.getPersonsList(userId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, personsList);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = event => {
		setPerson(event.target.value);
	};

	return (
		<Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				style={{
					width: '100%'
				}}
			>
				Move Visit
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="move-visit"
				className={classes.root}
			>
				<DialogTitle id="move-visit">Move Visit</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please choose where you want to move the visit.
					</DialogContentText>
					<FormControl
						variant="outlined"
						className={classes.formControl}
					>
						<Select
							labelId="persons-select-label"
							id="persons-select"
							value={personId}
							onChange={handleChange}
						>
							{personsList.map(item => {
								if (currentPersonId !== item.id) {
									return (
										<MenuItem value={item.id}>
											{item.personName}
										</MenuItem>
									);
								}
							})}
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button
						onClick={() => props.moveVisit(personId)}
						color="primary"
						disabled={!personId}
					>
						Move Visit
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

MoveVisitDialog.propTypes = {
	moveVisit: PropTypes.func.isRequired,
	personsList: PropTypes.object.isRequired,
	loggedAccountData: PropTypes.object.isRequired,
	getPersonsList: PropTypes.func.isRequired,
	currentPersonId: PropTypes.string.isRequired
};

export default MoveVisitDialog;
