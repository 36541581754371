/* eslint-disable */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '@Common';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Box from '@material-ui/core/Box';
import TableHead from '@material-ui/core/TableHead';
import Collapse from '@material-ui/core/Collapse';
import Row from './Row';

import EnhancedTableHead from './EnhancedTableHead';
import { styles } from './styles';

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

const useStyles = makeStyles(styles);

export default function EnhancedTable(props) {
	const {
		rows,
		headCells,
		columns,
		hasEmptyRows,
		customCheckbox,
		showVisibilitySettings,
		isCollapsible,
		nestedRowName,
		nestedRowsColumns,
		handleCheckboxOnChange,
		selectedValues,
		isCollapsibleOpenByDefault
	} = props;

	const classes = useStyles();
	const [order, setOrder] = React.useState('desc');
	const [orderBy, setOrderBy] = React.useState('date');
	const [selected, setSelected] = React.useState([...selectedValues]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [open, setOpen] = React.useState({});

	useEffect(() => {
		setSelected(selectedValues);
	}, [selectedValues]);

	useEffect(() => {
		const openObject = rows.map((item, index) => {
			return isCollapsibleOpenByDefault || false;
		});

		setOpen(openObject);
	}, [rows]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelected = rows.map((n, index) => index);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name, index, isItemSelected) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
		handleCheckboxOnChange(index, !isItemSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = event => {
		setDense(event.target.checked);
	};

	const isSelected = index => selected.indexOf(index) !== -1;

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	return (
		<div className={classes.root}>
			<TableContainer>
				<Table
					className={classes.table}
					aria-labelledby="tableTitle"
					size={dense ? 'small' : 'medium'}
					aria-label="enhanced table"
				>
					<EnhancedTableHead
						classes={classes}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						headCells={headCells}
						rowCount={rows.length}
						customCheckbox={customCheckbox}
						showVisibilitySettings={showVisibilitySettings}
						isCollapsible={isCollapsible}
					/>
					<TableBody>
						{stableSort(rows, getComparator(order, orderBy)).map(
							(row, index) => {
								const isItemSelected = isSelected(
									index.toString()
								);

								return (
									<React.Fragment>
										<TableRow
											onClick={event =>
												showVisibilitySettings &&
												handleClick(
													event,
													index.toString(),
													index,
													isItemSelected
												)
											}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={index.toString()}
											selected={isItemSelected}
											style={{ backgroundColor: 'unset' }}
										>
											{!isCollapsible &&
												headCells.map(head => {
													if (head.isCustom) {
														return (
															<TableCell>
																{head.customComponent(
																	row
																)}
															</TableCell>
														);
													}
													return (
														<TableCell>
															{head.isDateFormat
																? formatDate(
																		row[
																			head
																				.id
																		]
																  )
																: row[head.id]}
														</TableCell>
													);
												})}

											{isCollapsible && (
												<React.Fragment>
													<TableCell>
														{index + 1}
													</TableCell>
													{headCells.map(head => {
														if (head.isCustom) {
															return (
																<TableCell>
																	{head.customComponent(
																		row
																	)}
																</TableCell>
															);
														}
														return (
															<TableCell>
																{head.isDateFormat
																	? formatDate(
																			row[
																				head
																					.id
																			]
																	  )
																	: row[
																			head
																				.id
																	  ]}
															</TableCell>
														);
													})}
												</React.Fragment>
											)}
										</TableRow>
										{isCollapsible && (
											<TableRow>
												<TableCell
													style={{
														paddingBottom: 0,
														paddingTop: 0
													}}
													colSpan={6}
												>
													<Table
														size="small"
														aria-label="purchases"
													>
														<TableHead>
															<TableRow>
																<TableCell />
																{nestedRowsColumns.map(
																	head => {
																		return (
																			<TableCell>
																				{
																					head.label
																				}
																			</TableCell>
																		);
																	}
																)}
															</TableRow>
														</TableHead>
														<TableBody>
															{row[
																nestedRowName
															].map(
																(
																	nestedRow,
																	nestedRowIndex
																) => {
																	const rowColumns = Object.keys(
																		nestedRow
																	);
																	/* TODO: Hot fix nested table in nested table. Some day, please refactor this really big big sh*t !!!! */

																	let nestedColumns = [];
																	let nestedNestedRow = [];
																	let nested = false;

																	for (const element of rowColumns) {
																		if (
																			Array.isArray(
																				nestedRow[
																					element
																				]
																			) &&
																			nestedRow[
																				element
																			]
																				.length >
																				0
																		) {
																			nested = true;
																			nestedNestedRow =
																				nestedRow[
																					element
																				];

																			const columnsNames = Object.keys(
																				nestedNestedRow[0]
																			);
																			nestedColumns = columnsNames.map(
																				item => {
																					return {
																						id: item,
																						numeric: false,
																						disablePadding: false,
																						label: item
																					};
																				}
																			);
																			break;
																		}
																	}

																	return (
																		<React.Fragment>
																			<TableRow>
																				<TableCell>
																					{nestedRowIndex +
																						1}
																				</TableCell>
																				{nestedRowsColumns.map(
																					column => {
																						const nestedRowInfo =
																							nestedRow[
																								column
																									.id
																							];

																						if (
																							column.isCustom
																						) {
																							return (
																								<TableCell>
																									{column.customComponent(
																										nestedRow
																									)}
																								</TableCell>
																							);
																						}

																						return (
																							<TableCell>
																								{nestedRowInfo
																									? column.isDateFormat
																										? formatDate(
																												nestedRowInfo
																										  )
																										: nestedRowInfo
																									: 'N/A'}
																							</TableCell>
																						);
																					}
																				)}
																			</TableRow>
																			{nested && (
																				<TableRow>
																					<TableCell
																						style={{
																							paddingBottom: 0,
																							paddingTop: 0
																						}}
																						colSpan={
																							6
																						}
																					>
																						<Table
																							size="small"
																							aria-label="purchases"
																						>
																							<TableHead>
																								<TableRow>
																									<TableCell />
																									{nestedColumns.map(
																										head => {
																											return (
																												<TableCell>
																													{
																														head.label
																													}
																												</TableCell>
																											);
																										}
																									)}
																								</TableRow>
																							</TableHead>
																							<TableBody>
																								{nestedNestedRow.map(
																									(
																										nestedItem,
																										nestedNestedRowIndex
																									) => {
																										return (
																											<TableRow>
																												<TableCell>
																													{nestedNestedRowIndex +
																														1}
																												</TableCell>
																												{nestedColumns.map(
																													column => {
																														return (
																															<TableCell>
																																{nestedItem[
																																	column
																																		.id
																																] ||
																																	'N/A'}
																															</TableCell>
																														);
																													}
																												)}
																											</TableRow>
																										);
																									}
																								)}
																							</TableBody>
																						</Table>
																					</TableCell>
																				</TableRow>
																			)}
																		</React.Fragment>
																	);
																}
															)}
														</TableBody>
													</Table>
													{/* 		</Box>
													</Collapse> */}
												</TableCell>
											</TableRow>
										)}
									</React.Fragment>
								);
							}
						)}
						{emptyRows > 0 && hasEmptyRows && (
							<TableRow
								style={{
									height: (dense ? 33 : 53) * emptyRows
								}}
							>
								<TableCell colSpan={6} />
							</TableRow>
						)}

						{rows.length === 0 && (
							<TableRow>
								<TableCell align="center" colSpan={6}>
									No Data
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

EnhancedTable.propTypes = {
	selectedValues: PropTypes.array
};
