import * as actionTypes from './actionTypes';

export const getProviders = () => {
	return {
		type: actionTypes.GET_PROVIDERS,
		requestType: 'GET',
		request: `/providers?excludeSelf=true`
	};
};

export const getProviderType = () => {
	return {
		type: actionTypes.GET_PROVIDER_TYPE,
		requestType: 'GET',
		request: `/providersType`
	};
};

export const requestNewProvider = data => {
	return {
		type: actionTypes.REQUEST_NEW_PROVIDER,
		requestType: 'POST',
		message: 'Provider request sent successful.',
		request: `/requestProvider`,
		body: {
			...data
		}
	};
};

export const addProfileFromProvider = data => {
	return {
		type: actionTypes.ADD_PROFILE_FROM_PROVIDER,
		requestType: 'POST',
		message: 'Great! We are on it. Check back in 30 minutes!',
		request: `/addProfileFromProvider`,
		body: {
			...data
		}
	};
};

export const getProvidersWaitingForSms = personId => {
	return {
		type: actionTypes.GET_PROVIDERS_WAITING_FOR_SMS,
		requestType: 'GET',
		request: `/getProvidersWaitingForSms?personId=${personId}`
	};
};

export const sendSmsCode = (smsCode, providerId) => {
	return {
		type: actionTypes.SEND_SMS_CODE,
		requestType: 'POST',
		message: 'SMS code sent successful.',
		request: `/sendSmsCode`,
		body: {
			smsCode,
			id: providerId
		}
	};
};

export const saveSettings = (settings, personId) => {
	return {
		type: actionTypes.SAVE_SETTINGS,
		requestType: 'POST',
		request: `/settings`,
		body: {
			settings,
			personId
		}
	};
};

export const getSettings = personId => {
	return {
		type: actionTypes.GET_SETTINGS,
		requestType: 'GET',
		request: `/settings?personId=${personId}`,
		body: {}
	};
};
