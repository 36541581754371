import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
	Typography,
	Box,
	List,
	ListItem,
	ListItemText,
	Divider,
	IconButton
} from '@material-ui/core';

import CreatePersonModal from '@Components/Modals/CreatePersonModal';
import CloseIcon from '@material-ui/icons/Close';
import ForwardIcon from '@material-ui/icons/Forward';

const News = ({ props, data, person, onRecordDelete, loggedAccountData, setSelectedUser,
	setSelectedPerson,
	addPerson,
	photoUpload }) => {
	const [openCreatePersonModal, setOpenCreatePersonModal] = useState(false);
	// const handleOnDelete = id => {
	// 	onRecordDelete(id);
	// };

	const handleClose = () => {
		setOpenCreatePersonModal(false);
	};

	const handleOnAction = messageAction => {
		let pathname = '';
		let state = {};
		switch (messageAction) {
			case 'Add Connection':
				pathname = `/dashboard/${person.id}/new-provider-profile`;
				state = {
					person
				};
				break;
			case 'Add Person':
				setOpenCreatePersonModal(true);
				break;
			case 'View Self-Provided Health Data':
				pathname = `/dashboard/${person.id}/profiles/${person.profileId}`;
				state = {
					person,
					profile: {
						profileDetailId:
							person.profileDetailId,
						id: person.profileId,
						name:
							person.profileName,
						provider: {
							id:
								person.providerId
						}
					}
				};
				break;
			default:
				break;
		}
		props.history.push({
			pathname,
			state
		});


	};

	return (
		<Box
			bgcolor="#f4f7fa"
			padding="10px 30px"
			style={{
				overflowY: 'scroll'
			}}
			height="500px"
			marginBottom="60px"
		>
			<List>
				{data
					.filter(n => !n.isRead)
					.map((record, index) => (
						<Box>
							<ListItem>
								<ListItemText
									primary={
										<Typography
											variant="subtitle2"
											color="textPrimary"
										>
											{record.messageTitle}
										</Typography>
									}
									secondary={
										<Typography
											component="span"
											variant="caption"
										>
											{record.messageBody}
										</Typography>
									}
								/>

								{record.messageAction !== "No Action" && record.messageAction !== "delete" &&

									<IconButton
										style={{ transform: 'scale(0.8)' }}
										edge="end"
										aria-label="close"
										onClick={() => handleOnAction(record.messageAction)}
									>
										<ForwardIcon />
									</IconButton>

								}
								{record.messageAction === "delete" &&
									<IconButton
										style={{ transform: 'scale(0.8)' }}
										edge="end"
										aria-label="close"
										onClick={() => handleOnDelete(record.id)}
									>
										<CloseIcon />
									</IconButton>
								}
							</ListItem>
							<Divider />
						</Box>
					))}
			</List>
			<CreatePersonModal
				open={openCreatePersonModal}
				addPerson={addPerson}
				photoUpload={photoUpload}
				handleClose={handleClose}
				loggedAccountData={loggedAccountData}
				setSelectedUser={props.setSelectedUser}
				setSelectedPerson={props.setSelectedPerson}
			/>
		</Box>

	);
};

News.propTypes = {
	data: PropTypes.func.isRequired,
	onRecordDelete: PropTypes.object.isRequired,
	loggedAccountData: PropTypes.object.isRequired,
	setSelectedUser: PropTypes.func.isRequired,
	setSelectedPerson: PropTypes.func.isRequired,
	addPerson: PropTypes.func.isRequired,
	photoUpload: PropTypes.func.isRequired
};

export default News;
