import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
	FormGroup,
	FormControlLabel,
	Switch,
	Typography,
	Grid,
	Box
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		width: '100%'
	},
	optionName: {
		textTransform: 'capitalize'
	}
}));

const ProvidersContainer = ({ settings, handleSwitchChange, providers }) => {
	const classes = useStyles();

	const { enabledProviders } = useMemo(() => {
		const personProviders = providers.filter(
			provider =>
				!!settings.providersConsents &&
				// eslint-disable-next-line no-prototype-builtins
				settings.providersConsents.hasOwnProperty(provider.id)
		);

		return {
			enabledProviders: personProviders
		};
	}, [settings, providers]);

	return (
		<Grid
			container
			direction="row"
			justify="space-between"
			alignItems="center"
		>
			{enabledProviders.length > 0 ? (
				enabledProviders.map(provider => {
					return (
						<Grid item xs="12" md="12" lg="12" key={provider.id}>
							<Box
								display="flex"
								alignItem="center"
								justifyContent="space-between"
							>
								<Typography
									className={classes.subHeader}
									align="center"
									variant="subtitle1"
								>
									{provider.name}
								</Typography>

								<FormGroup row>
									<FormControlLabel
										control={
											<Switch
												checked={
													!!settings
														.providersConsents[
														provider.id
													]
												}
												onChange={event =>
													handleSwitchChange(
														event,
														provider.id
													)
												}
												name={provider.id}
												color="primary"
											/>
										}
										label={
											settings.providersConsents[
												provider.id
											]
												? 'Yes'
												: 'No'
										}
									/>
								</FormGroup>
							</Box>
						</Grid>
					);
				})
			) : (
				<Grid item xs="12" md="12" lg="12">
					<Box
						display="flex"
						alignItem="center"
						justifyContent="center"
					>
						<Typography
							className={classes.subHeader}
							align="center"
							variant="subtitle1"
						>
							No added consents for providers
						</Typography>
					</Box>
				</Grid>
			)}
		</Grid>
	);
};

ProvidersContainer.propTypes = {
	settings: PropTypes.object.isRequired,
	handleSwitchChange: PropTypes.func.isRequired,
	providers: PropTypes.array.isRequired
};

export default ProvidersContainer;
