import React from 'react';

import { Switch } from 'react-router-dom';
import { withRouter } from 'react-router';

import PrivateRoute from './PrivateRoute';

/* Dashboard Page */
import Dashboard from '../../pages/Dashboard/index';

/* Persons */
import PersonsList from '../../pages/Persons/index';

/* Connection */
import CreateProviderProfile from '../../pages/Connection/index';

/* Summary Page */
import Summary from '../../pages/Summary/index';

/* Timeline Page */
import Timeline from '../../pages/Timeline/index';

/* Jobs page */
import Jobs from '../../pages/Jobs/index';

/* Edit Account info page */
import EditAccountInformation from '../../pages/Authentication/pages/EditAccountInformation';
import ChangePassword from '../../pages/Authentication/pages/ChangePassword';

/* Consent(settings) page */
import Consent from '../../pages/Consent/index';

const Routes = () => {
	return (
		<Switch>
			<PrivateRoute exact path="/" component={PersonsList} />

			<PrivateRoute
				path="/dashboard/:personId/new-provider-profile"
				component={CreateProviderProfile}
			/>

			<PrivateRoute
				path="/:userId/edit-account-info"
				component={EditAccountInformation}
			/>

			<PrivateRoute
				path="/:userId/change-password"
				component={ChangePassword}
			/>

			<PrivateRoute
				path="/dashboard/:personId/timeline"
				component={Timeline}
			/>
			<PrivateRoute
				path="/dashboard/:personId/export-summary"
				component={Summary}
			/>
			<PrivateRoute path="/profiles-jobs" component={Jobs} />
			<PrivateRoute
				path="/dashboard/:personId/settings"
				component={Consent}
			/>
			<PrivateRoute
				path="/dashboard/:personId"
				component={Dashboard}
				exact
			/>
		</Switch>
	);
};

export default withRouter(Routes);
