import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VirtualizedTable from '@Components/VirtualizedTable/index';

import {
	Button,
	CssBaseline,
	Typography,
	Paper,
	Container
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
/* Clear information for selected person in left navigation */
import { clearSelectedPerson, setDefaultSelectedUser } from '@SharedActions';
import { getCovidFeedbacks } from './actions';

const styles = theme => ({
	covidContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	covidGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	},
	subHeader: {
		marginBottom: '20px',
		marginTop: '20px'
	},
	refreshButton: {
		marginBottom: '20px'
	}
});

export const diseaseMap = {
	asthma: 'Asthma',
	adhd: 'ADHD',
	autismSpectrum: 'Autism Spectrum',
	depression: 'Anxiety/Depression',
	other: 'Other',
	none: 'None'
};

class CovidFeedbacks extends Component {
	componentDidMount() {
		this.props.getCovidFeedbacks();
		this.props.clearSelectedPerson();
		this.props.setDefaultSelectedUser();
	}

	render() {
		const { covidFeedbacks, classes } = this.props;

		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					<div className={classes.covidContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
							>
								Covid Feedback
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
							>
								See below the submitted information by the form
							</Typography>
						</Container>
						<Container className={classes.covidGrid} maxWidth="md">
							<Button
								onClick={this.props.getCovidFeedbacks}
								color="primary"
								variant="contained"
								className={classes.refreshButton}
							>
								Refresh
							</Button>
							<Paper
								style={{
									height: ' calc(100vh - 400px)',
									width: '940px'
								}}
							>
								<VirtualizedTable
									rowCount={covidFeedbacks.length}
									rowGetter={({ index }) =>
										covidFeedbacks[index]
									}
									rowHeight={100}
									columns={[
										{
											width: 200,
											label: 'Email',
											dataKey: 'email'
										},
										{
											width: 200,
											label: 'Feedback',
											dataKey: 'feedback'
										},
										{
											width: 50,
											label: 'Rating',
											dataKey: 'rating'
										},
										{
											width: 120,
											label: 'Crated',
											dataKey: 'created',
											isDateTime: true
										},
										{
											width: 120,
											label: 'Diseases',
											dataKey: 'disease',
											customComponent: disease => (
												<div>
													{disease.map(item => (
														<span>
															{diseaseMap[item]},{' '}
														</span>
													))}
												</div>
											)
										},
										{
											width: 200,
											label: 'Other Disease',
											dataKey: 'otherDisease'
										},
										{
											width: 50,
											label: 'Zip Code',
											dataKey: 'zipCode'
										}
									]}
								/>
							</Paper>
						</Container>
					</div>
				</main>
			</React.Fragment>
		);
	}
}

CovidFeedbacks.propTypes = {
	covidFeedbacks: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	clearSelectedPerson: PropTypes.func.isRequired,
	setDefaultSelectedUser: PropTypes.func.isRequired,
	getCovidFeedbacks: PropTypes.func.isRequired
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				covidFeedbacks: state.admin
					.getIn(['covidFeedbacks', 'covidFeedbacks'])
					.toJS()
			};
		},
		{
			getCovidFeedbacks,
			clearSelectedPerson,
			setDefaultSelectedUser
		}
	)(CovidFeedbacks)
);
