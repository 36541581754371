import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ShareIcon from './ShareIcon';
import HomeScreenIcon from './HomeScreenIcon';

const useStyles = makeStyles(theme => ({
	installButton: {
		width: '100%',
		cursor: 'pointer',
		textAlign: 'center',
		backgroundColor: '#d9534f',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		},
		'&:hover': {
			backgroundColor: '#c9302c'
		}
	}
}));

const PWAInstallDialog = props => {
	const classes = useStyles();
	const [open, setOpen] = useState(props.forceOpen);
	const [showForeverButton, setShowForeverButton] = useState(props.forceOpen);

	const handleClickOpen = () => {
		setOpen(true);
		setShowForeverButton(false);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseForever = () => {
		localStorage.setItem(
			'iosPwaDialog',
			JSON.stringify({
				visits: 6
			})
		);
		handleClose();
	};

	useEffect(() => {
		let promptData = JSON.parse(localStorage.getItem('iosPwaDialog'));

		if (promptData === null) {
			promptData = { visits: 0 };
			localStorage.setItem('iosPwaDialog', JSON.stringify(promptData));
		}

		localStorage.setItem(
			'iosPwaDialog',
			JSON.stringify({
				visits: promptData.visits + 1
			})
		);
	}, []);

	const isiOS13AndUp = /OS (13|14)/.test(window.navigator.userAgent);

	return (
		<React.Fragment>
			<Link variant="body3" onClick={handleClickOpen}>
				How to install app?
			</Link>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">
					Add to Home Screen
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This website has app functionality. Add it to your home
						screen to use it in fullscreen and while offline.
					</DialogContentText>
				</DialogContent>
				<DialogContent>
					<DialogContentText>
						1) Press the 'Share' button on the menu bar below.
						<ShareIcon
							className="iOSPWA-step1-icon"
							modern={isiOS13AndUp}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogContent>
					<DialogContentText>
						2) Press 'Add to Home Screen'.
						<HomeScreenIcon
							className="iOSPWA-step1-icon"
							modern={isiOS13AndUp}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{showForeverButton && (
						<Button onClick={handleCloseForever} color="primary">
							Cancel Forever
						</Button>
					)}
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

PWAInstallDialog.propTypes = {
	forceOpen: PropTypes.bool.isRequired
};

export default PWAInstallDialog;
