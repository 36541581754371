import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	tempLinkButton: {
		cursor: 'pointer',
		textAlign: 'center',
		width: '100%',
		margin: '0 auto',
		marginBottom: '20px',
		maxWidth: '400px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	}
}));

const ConfirmationDialog = ({
	open,
	close,
	action,
	dialogContent,
	dialogTitle
}) => {
	const classes = useStyles();

	const handleClose = () => {
		close();
	};

	const handleSendButton = async () => {
		await action();
		handleClose();
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText>{dialogContent}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						No
					</Button>
					<Button onClick={handleSendButton} color="primary">
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

ConfirmationDialog.propTypes = {
	action: PropTypes.func.isRequired,
	close: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	dialogContent: PropTypes.node.isRequired,
	dialogTitle: PropTypes.string.isRequired
};

export default ConfirmationDialog;
