/* eslint-disable jsx-a11y/label-has-associated-control */
/* TODO - Refactor in functional component */

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Avatar
} from '@material-ui/core';

import PhotoCamera from '@material-ui/icons/PhotoCamera';

import { withStyles } from '@material-ui/styles';

const ONE_SPACE_REG_EX = /^\s+$/;

export const styles = () => ({
	avatar: {
		display: 'flex',
		flexDirection: 'column',
		height: '140px',
		width: '140px',
		margin: '0 auto'
	},
	input: {
		display: 'none'
	},
	uploadButtons: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '15px'
	}
});

class CreatePerson extends Component {
	constructor(props) {
		super(props);

		this.state = {
			person: {
				name: '',
				fileUrl: null
			}
		};
	}

	handleNameChange = event => {
		const { person } = this.state;
		person.name = event.target.value;
		this.setState({ person });
	};

	addPerson = async personData => {
		try {
			const personRes = await this.props.addPerson(personData);

			this.setState({
				person: {
					name: '',
					fileUrl: null
				}
			});
			this.handleClose();

			const personObj = {
				id: personRes.response.getIn(['returnObj', 'person', 'id']),
				name: personRes.response.getIn(['returnObj', 'person', 'name']),
				photo: personRes.response.getIn([
					'returnObj',
					'person',
					'photo'
				])
			};
			this.props.setSelectedUser(personObj);
			this.props.setSelectedPerson(personObj);

			this.props.history.push({
				pathname: `/dashboard/${personRes.response.getIn([
					'returnObj',
					'person',
					'id'
				])}`,
				state: {
					person: {
						...personObj,
						personName: personRes.response.getIn([
							'returnObj',
							'person',
							'name'
						])
					}
				}
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	handleClose = () => {
		this.setState({
			person: {
				name: '',
				fileUrl: null
			}
		});
		this.props.handleClose();
	};

	savePerson = async () => {
		const data = this.state.person;
		const { userId } = this.props.loggedAccountData;

		const personData = {
			person: {
				name: data.name,
				photo: null
			},
			user: userId
		};

		const { formData } = this.state.person;
		if (formData) {
			try {
				const res = await this.props.photoUpload(formData);
				personData.person.photo = res.response.get('fileName');
				this.addPerson(personData);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e);
			}
		} else {
			this.addPerson(personData);
		}
	};

	onNewFileChange = event => {
		const file = event.target.files[0];
		const oneMBSize = 10048487;

		if (file && file.size <= oneMBSize) {
			const formData = new FormData();

			formData.append('avatar', file);

			const { person } = this.state;
			person.fileURL = URL.createObjectURL(file);
			person.formData = formData;

			this.setState({ person });
		} else {
			document.getElementById('fileSizeBtn').click();
		}
	};

	render = () => {
		const { classes, open } = this.props;

		const { person } = this.state;

		return (
			<Fragment>
				<Dialog
					open={open}
					onClose={this.handleClose}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Add Person</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Please enter the full name and attach a photo.
							Attaching a photo is optional but recommended. You
							can add or change the photo at any time.
						</DialogContentText>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="fullName"
							label="Full Name"
							name="fullName"
							onChange={this.handleNameChange}
						/>
						<div>
							<Avatar
								className={classes.avatar}
								alt="photo"
								src={person.fileURL}
							/>
						</div>
						<div className={classes.uploadButtons}>
							<input
								accept="image/*"
								className={classes.input}
								id="contained-button-file"
								multiple
								type="file"
								onChange={this.onNewFileChange}
							/>
							<label htmlFor="contained-button-file">
								<Button
									variant="contained"
									color="primary"
									component="span"
								>
									Upload
									<PhotoCamera
										style={{ marginLeft: '10px' }}
									/>
								</Button>
							</label>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Cancel
						</Button>
						<Button
							onClick={this.savePerson}
							disabled={
								!person.name ||
								ONE_SPACE_REG_EX.test(person.name)
							}
							color="primary"
						>
							Add Person
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		);
	};
}

CreatePerson.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.any.isRequired,
	handleClose: PropTypes.func.isRequired,
	addPerson: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	setSelectedUser: PropTypes.func.isRequired,
	photoUpload: PropTypes.func.isRequired,
	setSelectedPerson: PropTypes.func.isRequired,
	loggedAccountData: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(CreatePerson));
