/* eslint-disable no-param-reassign */
import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	settings: {
		timeElapsed: 20,
		providersConsents: {}
	},
	providers: []
});

const consent = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_SETTINGS:
			if (action.state === 'finished') {
				const timeElapsed = action.response.getIn([
					'value',
					'timeElapsed'
				]);

				const providersConsents = action.response.getIn([
					'value',
					'providersConsents'
				]);

				state = state
					.setIn(['settings', 'timeElapsed'], timeElapsed)
					.setIn(
						['settings', 'providersConsents'],
						providersConsents
					);
			}
			return state;
		case actionTypes.CHANGE_PROVIDER_CONSENT:
			return state.setIn(
				['settings', 'providersConsents', action.body.providerId],
				action.body.isChecked
			);
		case actionTypes.CHANGE_TEMP_FIELD:
			return state.setIn(['settings', 'timeElapsed'], action.body.value);
		case actionTypes.GET_PROVIDERS:
			if (action.state === 'finished') {
				state = state.set('providers', action.response);
			}
			return state;
		default:
			return state;
	}
};

export default consent;
