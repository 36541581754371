import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const LaboratoryTests = props => {
	const handleDateChange = (date, index) => {
		props.handleField(date, 'LaboratoryTests', 'laboratoryTests', [
			index,
			'date'
		]);
	};

	const handleAdd = () => {
		props.handleAddNewItem(
			[
				{
					name: '',
					date: new Date(),
					orderBy: '',
					studyResult: '',
					details: []
				}
			],
			'LaboratoryTests',
			'laboratoryTests'
		);
	};

	const handleAddTestDetails = index => {
		props.handleField(
			[
				...props.data[index].details,
				{
					component: '',
					range: '',
					value: ''
				}
			],
			'LaboratoryTests',
			'laboratoryTests',
			[index, 'details']
		);
	};

	const handleField = (event, index) => {
		props.handleField(
			event.target.value,
			'LaboratoryTests',
			'laboratoryTests',
			[index, event.target.id]
		);
	};

	const handleFieldDetails = (event, index, detailsIndex) => {
		props.handleField(
			event.target.value,
			'LaboratoryTests',
			'laboratoryTests',
			[index, 'details', detailsIndex, event.target.id]
		);
	};

	const handleDelete = index => {
		props.deleteData(index, 'LaboratoryTests', 'laboratoryTests');
	};

	const handleDeleteDetails = (index, detailsIndex) => {
		const { details } = props.data[index];
		details.splice(detailsIndex, 1);

		props.handleField([...details], 'LaboratoryTests', 'laboratoryTests', [
			index,
			'details'
		]);
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Laboratory Tests
			</Typography>
			{props.data.length > 0 ? (
				props.data.map((test, index) => {
					return (
						<Grid
							style={{ marginTop: '20px' }}
							container
							spacing={3}
						>
							<Grid item xs={12} md={4}>
								<TextField
									id="name"
									label="Name"
									fullWidth
									value={test.name}
									onChange={e => handleField(e, index)}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date"
										label="Date"
										value={test.date}
										style={{
											width: '100%',
											marginTop: 0,
											marginBottom: 0
										}}
										autoOk
										onChange={date =>
											handleDateChange(date, index)
										}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12} md={3}>
								<Button
									style={{ marginTop: '20px' }}
									variant="contained"
									color="primary"
									onClick={() => handleAddTestDetails(index)}
								>
									Add Test Details
								</Button>
							</Grid>

							<Grid item xs={12} md={1}>
								<IconButton
									aria-label="delete"
									color="primary"
									style={{
										backgroundColor: '#d9534f',
										'&:hover': {
											backgroundColor: '#c9302c'
										},
										color: '#ffffff',
										height: '45px',
										width: '45px',
										marginTop: '5px',
										cursor: 'pointer'
									}}
									onClick={() => handleDelete(index)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="orderBy"
									label="Order By"
									fullWidth
									value={test.orderBy}
									onChange={e => handleField(e, index)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="studyResult"
									label="Study Result"
									fullWidth
									value={test.studyResult}
									onChange={e => handleField(e, index)}
								/>
							</Grid>
							{test.details.length > 0 && (
								<Grid item xs={12} md={12}>
									<Grid container>
										<Typography variant="h6" gutterBottom>
											Tests Details
										</Typography>

										{test.details.map(
											(detail, detailIndex) => {
												return (
													<Grid container spacing={3}>
														<Grid
															item
															xs={12}
															md={3}
														>
															<TextField
																id="component"
																label="Component"
																fullWidth
																value={
																	detail.component
																}
																onChange={e =>
																	handleFieldDetails(
																		e,
																		index,
																		detailIndex
																	)
																}
															/>
														</Grid>
														<Grid
															item
															xs={12}
															md={3}
														>
															<TextField
																id="range"
																label="Range"
																fullWidth
																value={
																	detail.range
																}
																onChange={e =>
																	handleFieldDetails(
																		e,
																		index,
																		detailIndex
																	)
																}
															/>
														</Grid>
														<Grid
															item
															xs={12}
															md={3}
														>
															<TextField
																id="value"
																label="Value"
																fullWidth
																value={
																	detail.value
																}
																onChange={e =>
																	handleFieldDetails(
																		e,
																		index,
																		detailIndex
																	)
																}
															/>
														</Grid>
														<Grid
															item
															xs={12}
															md={1}
														>
															<IconButton
																aria-label="delete"
																color="primary"
																style={{
																	backgroundColor:
																		'#d9534f',
																	'&:hover': {
																		backgroundColor:
																			'#c9302c'
																	},
																	color:
																		'#ffffff',
																	height:
																		'45px',
																	width:
																		'45px',
																	marginTop:
																		'5px',
																	cursor:
																		'pointer'
																}}
																onClick={() =>
																	handleDeleteDetails(
																		index,
																		detailIndex
																	)
																}
															>
																<DeleteIcon />
															</IconButton>
														</Grid>
													</Grid>
												);
											}
										)}
									</Grid>
								</Grid>
							)}
						</Grid>
					);
				})
			) : (
				<Typography component="h1" variant="h4" align="center">
					No Laboratory Tests
				</Typography>
			)}

			<Grid contariner spacing={3}>
				<Button
					style={{ marginTop: '20px' }}
					variant="contained"
					color="primary"
					onClick={handleAdd}
				>
					Add Laboratory Test
				</Button>
			</Grid>
		</React.Fragment>
	);
};

LaboratoryTests.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired,
	deleteData: PropTypes.func.isRequired,
	handleAddNewItem: PropTypes.func.isRequired
};

export default LaboratoryTests;
