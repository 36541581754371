/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Avatar
} from '@material-ui/core';

import PhotoCamera from '@material-ui/icons/PhotoCamera';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	avatar: {
		display: 'flex',
		flexDirection: 'column',
		height: '140px',
		width: '140px',
		margin: '0 auto'
	},
	input: {
		display: 'none'
	},
	uploadButtons: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '15px'
	}
}));
const ONE_SPACE_REG_EX = /^\s+$/;

const EditPersonModal = ({ open, person, editPersonHandler, onClose }) => {
	const classes = useStyles();

	const [username, setUsername] = useState(person.name);
	const [formData, setFormData] = useState(false);
	const [fileURL, setFileUrl] = useState(person.photo);

	useEffect(() => {
		setUsername(person.name);
		setFileUrl(person.photo);
	}, [person, setUsername, setFileUrl]);

	const handleNameChange = event => {
		setUsername(event.target.value);
	};

	const onNewFileChange = event => {
		const file = event.target.files[0];
		setFileUrl(URL.createObjectURL(file));

		const oneMBSize = 10048487;

		if (file && file.size <= oneMBSize) {
			const data = new FormData();

			data.append('avatar', file);

			setFormData(data);
		}
	};

	const handleEditPerson = (file, data) => {
		editPersonHandler(
			{
				id: person.id,
				name: username
			},
			data
		);
		handleOnClose();
	};

	const handleOnClose = () => {
		setUsername(person.name);
		setFileUrl(person.photo);
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={handleOnClose}
			aria-labelledby="edit-person-dialog"
			className={classes.root}
		>
			<DialogTitle id="edit-person-dialog">Edit Person</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Please enter the full name and attach a photo.
					Attaching a photo is optional but recommended. You
					can add or change the photo at any time.
				</DialogContentText>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="fullName"
					label="Full Name"
					name="fullName"
					value={username}
					onChange={handleNameChange}
				/>
				<div>
					<Avatar
						className={classes.avatar}
						alt="photo"
						src={fileURL}
					/>
				</div>
				<div className={classes.uploadButtons}>
					<input
						accept="image/*"
						className={classes.input}
						id="contained-button-file"
						multiple
						type="file"
						onChange={onNewFileChange}
					/>
					<label htmlFor="contained-button-file">
						<Button
							variant="contained"
							color="primary"
							component="span"
						>
							Upload
							<PhotoCamera style={{ marginLeft: '10px' }} />
						</Button>
					</label>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleOnClose} color="primary">
					Cancel
				</Button>
				<Button
					onClick={() => handleEditPerson(fileURL, formData)}
					color="primary"
					disabled={!username || ONE_SPACE_REG_EX.test(username)}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

EditPersonModal.propTypes = {
	editPersonHandler: PropTypes.func.isRequired,
	person: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

export default EditPersonModal;
