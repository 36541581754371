/* My Chart Components */

import PersonalInfo from '../../components/MyChart/PersonalInfo';
import Allergies from '../../components/MyChart/Allergies';
import Diagnosis from '../../components/MyChart/Diagnosis';
import History from '../../components/MyChart/History';
import PlanOfCare from '../../components/MyChart/PlanOfCare';
import Immunizations from '../../components/MyChart/Immunizations';
import LaboratoryTests from '../../components/MyChart/LaboratoryTests';
import Medications from '../../components/MyChart/Medications';
import Files from '../../components/MyChart/Files';

/* Education components */
import StudentInfo from '../../components/Education/PersonalInfo';
import EmerganceContacts from '../../components/Education/EmerganceContacts';
import Gradebooks from '../../components/Education/Gradebooks';
import Attendances from '../../components/Education/Attendances';
import Disciplines from '../../components/Education/Disciplines';
import HealthConditions from '../../components/Education/HealthConditions';
import DiabeteScarelogs from '../../components/Education/DiabeteScarelogs';
import Disabilities from '../../components/Education/Disabilities';
import OfficeVisits from '../../components/Education/OfficeVisits';
import IhpForms from '../../components/Education/IhpForms';
import ChildhoodIllness from '../../components/Education/ChildhoodIllness';
import Injuries from '../../components/Education/Injuries';
import EducationMedications from '../../components/Education/Medications';
import PhysicalExams from '../../components/Education/PhysicalExams';
import Tests from '../../components/Education/Tests';
import Vaccinations from '../../components/Education/Vaccinations';

/* Fort Worth Components */

import ChildInfoGeneral from '../../components/FortWorth/ChildInfoGeneral';
import ChildInfoAddressAndContacts from '../../components/FortWorth/ChildInfoAddressAndContacts';
import ChildInfoBilingualESL from '../../components/FortWorth/ChildInfoBilingualESL';
import ChildInfoProgressionPlan from '../../components/FortWorth/ChildInfoProgressionPlan';
import ChildInfoPeims from '../../components/FortWorth/ChildInfoPeims';
import ChildInfoDyslexia from '../../components/FortWorth/ChildInfoDyslexia';
import AssignmentsAndGrades from '../../components/FortWorth/AssignmentsAndGrades';
import Grades from '../../components/FortWorth/Grades';
import Absences from '../../components/FortWorth/Absences';

/* IrvingISD Components */

import ChildInfoGeneralIrvingISD from '../../components/IrvingISD/ChildInfoGeneral';
import ChildInfoAddressAndContactsIrvingISD from '../../components/IrvingISD/ChildInfoAddressAndContacts';
import GradesIrvingISD from '../../components/IrvingISD/Grades';
import AttendancesIrvingISD from '../../components/IrvingISD/Attendances';
import Classes from '../../components/IrvingISD/Classes';

export const myChartComponentsMap = [
	{
		comp: PersonalInfo,
		data: ['content', 'personalInfo'],
		schemaProp: ['personalInfo'],
		sectionSchemaProp: ['personalInfo']
	},
	{
		comp: Allergies,
		data: ['content', 'allergies'],
		schemaProp: ['allergies'],
		sectionSchemaProp: ['allergies']
	},
	{
		comp: Diagnosis,
		data: ['content', 'diagnosis'],
		schemaProp: ['diagnosis'],
		sectionSchemaProp: ['diagnosis']
	},
	{
		comp: History,
		data: ['content', 'history'],
		schemaProp: ['history'],
		sectionSchemaProp: ['history']
	},
	{
		comp: PlanOfCare,
		data: ['content', 'planOfCare'],
		schemaProp: ['planOfCare'],
		sectionSchemaProp: ['planOfCare']
	},
	{
		comp: Immunizations,
		data: ['content', 'immunizations'],
		schemaProp: ['immunizations'],
		sectionSchemaProp: ['immunizations']
	},
	{
		comp: LaboratoryTests,
		data: ['content', 'laboratoryTests'],
		schemaProp: ['laboratoryTests'],
		sectionSchemaProp: ['laboratoryTests']
	},
	{
		comp: Medications,
		data: ['content', 'medications'],
		schemaProp: ['medications'],
		sectionSchemaProp: ['medications']
	},
	{
		comp: Files,
		data: ['content', 'files'],
		schemaProp: ['files'],
		sectionSchemaProp: ['files']
	}
];

export const educationComponentsMap = [
	{
		comp: StudentInfo,
		data: ['content', 'studentInfo'],
		schemaProp: ['studentInfo'],
		sectionSchemaProp: ['studentInfo']
	},
	{
		comp: EmerganceContacts,
		data: ['content', 'studentInfo', 'itemStudentInfo'],
		schemaProp: ['studentInfo', 'itemStudentInfo'],
		sectionSchemaProp: ['studentInfo']
	},
	{
		comp: Gradebooks,
		data: ['content', 'gradebooks'],
		schemaProp: ['gradebooks'],
		sectionSchemaProp: ['gradebooks']
	},
	{
		comp: Attendances,
		data: ['content', 'attendances'],
		schemaProp: ['attendances'],
		sectionSchemaProp: ['attendances']
	},
	{
		comp: Disciplines,
		data: ['content', 'disciplines'],
		schemaProp: ['disciplines'],
		sectionSchemaProp: ['disciplines']
	},
	{
		comp: HealthConditions,
		data: ['content', 'healthInfo', 'healthConditions'],
		schemaProp: ['healthInfo', 'healthConditions'],
		sectionSchemaProp: ['healthInfo']
	},
	{
		comp: DiabeteScarelogs,
		data: ['content', 'healthInfo', 'diabeteScarelog'],
		schemaProp: ['healthInfo', 'diabeteScarelog'],
		sectionSchemaProp: ['healthInfo']
	},
	{
		comp: Disabilities,
		data: ['content', 'healthInfo', 'disabilities'],
		schemaProp: ['healthInfo', 'disabilities'],
		sectionSchemaProp: ['healthInfo']
	},
	{
		comp: OfficeVisits,
		data: ['content', 'healthInfo', 'officeVisits'],
		schemaProp: ['healthInfo', 'officeVisits'],
		sectionSchemaProp: ['healthInfo']
	},
	{
		comp: IhpForms,
		data: ['content', 'healthInfo', 'ihpForms'],
		schemaProp: ['healthInfo', 'ihpForms'],
		sectionSchemaProp: ['healthInfo']
	},
	{
		comp: ChildhoodIllness,
		data: ['content', 'healthInfo', 'childhoodIllnesses'],
		schemaProp: ['healthInfo', 'childhoodIllnesses'],
		sectionSchemaProp: ['healthInfo']
	},
	{
		comp: Injuries,
		data: ['content', 'healthInfo', 'injuries'],
		schemaProp: ['healthInfo', 'injuries'],
		sectionSchemaProp: ['healthInfo']
	},
	{
		comp: EducationMedications,
		data: ['content', 'healthInfo', 'medications'],
		schemaProp: ['healthInfo', 'medications'],
		sectionSchemaProp: ['healthInfo']
	},
	{
		comp: PhysicalExams,
		data: ['content', 'healthInfo', 'physicalExams'],
		schemaProp: ['healthInfo', 'physicalExams'],
		sectionSchemaProp: ['healthInfo']
	},
	{
		comp: Tests,
		data: ['content', 'healthInfo', 'tests'],
		schemaProp: ['healthInfo', 'tests'],
		sectionSchemaProp: ['healthInfo']
	},
	{
		comp: Vaccinations,
		data: ['content', 'healthInfo', 'vaccinations'],
		schemaProp: ['healthInfo', 'vaccinations'],
		sectionSchemaProp: ['healthInfo']
	}
];

export const fortWorthComponentsMap = [
	{
		comp: ChildInfoGeneral,
		data: ['content', 'childInfoGeneral'],
		schemaProp: ['childInfoGeneral'],
		sectionSchemaProp: ['childInfoGeneral']
	},
	{
		comp: ChildInfoAddressAndContacts,
		data: ['content', 'childInfoAddressAndContacts'],
		schemaProp: ['childInfoAddressAndContacts'],
		sectionSchemaProp: ['childInfoAddressAndContacts']
	},
	{
		comp: ChildInfoBilingualESL,
		data: ['content', 'childInfoBilingualESL'],
		schemaProp: ['childInfoBilingualESL'],
		sectionSchemaProp: ['childInfoBilingualESL']
	},
	{
		comp: ChildInfoProgressionPlan,
		data: ['content', 'childInfoProgressionPlan'],
		schemaProp: ['childInfoProgressionPlan'],
		sectionSchemaProp: ['childInfoProgressionPlan']
	},
	{
		comp: ChildInfoPeims,
		data: ['content', 'childInfoPeims'],
		schemaProp: ['childInfoPeims'],
		sectionSchemaProp: ['childInfoPeims']
	},
	{
		comp: ChildInfoDyslexia,
		data: ['content', 'childInfoDyslexia'],
		schemaProp: ['childInfoDyslexia'],
		sectionSchemaProp: ['childInfoDyslexia']
	},
	{
		comp: AssignmentsAndGrades,
		data: ['content', 'assignmentsAndGrades'],
		schemaProp: ['assignmentsAndGrades'],
		sectionSchemaProp: ['assignmentsAndGrades']
	},
	{
		comp: Grades,
		data: ['content', 'grades'],
		schemaProp: ['grades'],
		sectionSchemaProp: ['grades']
	},
	{
		comp: Absences,
		data: ['content', 'absences'],
		schemaProp: ['absences'],
		sectionSchemaProp: ['absences']
	}
];

export const irvingISDComponentsMap = [
	{
		comp: ChildInfoGeneralIrvingISD,
		data: ['content', 'childInfoGeneral'],
		schemaProp: ['childInfoGeneral'],
		sectionSchemaProp: ['childInfoGeneral']
	},
	{
		comp: ChildInfoAddressAndContactsIrvingISD,
		data: ['content', 'childInfoAddressAndContacts'],
		schemaProp: ['childInfoAddressAndContacts'],
		sectionSchemaProp: ['childInfoAddressAndContacts']
	},
	{
		comp: GradesIrvingISD,
		data: ['content', 'grades'],
		schemaProp: ['grades'],
		sectionSchemaProp: ['grades']
	},
	{
		comp: AttendancesIrvingISD,
		data: ['content', 'attendances'],
		schemaProp: ['attendances'],
		sectionSchemaProp: ['attendances']
	},
	{
		comp: Classes,
		data: ['content', 'classes'],
		schemaProp: ['classes'],
		sectionSchemaProp: ['classes']
	}
];

export const infoComponents = {
	MyChart: myChartComponentsMap,
	Education: educationComponentsMap,
	FWISD: fortWorthComponentsMap,
	IrvingISD: irvingISDComponentsMap
};

export const initialState = {
	visibilitySchema: {
		personalInfo: {},
		allergies: [],
		diagnosis: [],
		planOfCare: [],
		immunizations: [],
		laboratoryTests: [],
		medications: [],
		files: [],
		history: {
			socialHistory: {},
			familyHistory: [],
			medicalHistory: [],
			surgicalHistory: []
		},
		studentInfo: {
			itemStudentInfo: []
		},
		attendances: [],
		disciplines: [],
		gradebooks: [],
		healthInfo: {
			childhoodIllnesses: [],
			diabeteScarelog: [],
			disabilities: [],
			healthConditions: [],
			ihpForms: [],
			injuries: [],
			medications: [],
			officeVisits: [],
			physicalExams: [],
			tests: [],
			vaccinations: []
		},
		childInfoGeneral: {},
		childInfoAddressAndContacts: [],
		childInfoBilingualESL: {},
		childInfoProgressionPlan: {},
		childInfoPeims: {},
		childInfoDyslexia: {},
		assignmentsAndGrades: [],
		grades: [],
		absences: [],
		classes: []
	},
	showVisibilitySettings: false
};
