import React from 'react';
import PrivateRoute from '@Components/Routes/PrivateRoute';

import CreateSelfProfile from './pages/CreateSelfProfile/index';
import EditSelfProfile from './pages/EditSelfProfile/index';

class SelfProfile extends React.Component {
	render() {
		return (
			<React.Fragment>
				<PrivateRoute
					path="/dashboard/:personId/new-self-profile"
					exact
					component={CreateSelfProfile}
				/>
				<PrivateRoute
					path="/dashboard/:personId/edit-self-profile/:id"
					component={EditSelfProfile}
				/>
			</React.Fragment>
		);
	}
}

export default SelfProfile;
