export const styles = theme => ({
	jobsContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	jobsGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	},
	refreshButton: {
		marginBottom: '20px'
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	},
	subHeader: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.00rem'
		}
	}
});
