/* My Chart Components */
import GlobalSelectors from '../../components/MyChart/GlobalSelectors';
import PatientInformationSelectors from '../../components/MyChart/PatientInformationSelectors';
import HealthIssuesSelectors from '../../components/MyChart/HealthIssuesSelectors';
import MedicationsSelectors from '../../components/MyChart/MedicationsSelectors';
import AllergiesSelectors from '../../components/MyChart/AllergiesSelectors';
import ImmunizationsSelectors from '../../components/MyChart/ImmunizationsSelectors';
import LaboratoryTestSelectors from '../../components/MyChart/LaboratoryTestSelectors';
import LaboratoryTestDetailsSelectors from '../../components/MyChart/LaboratoryTestDetailsSelectors';
import MedicalHistorySelectors from '../../components/MyChart/MedicalHistorySelectors';
import PlanOfCareSelectors from '../../components/MyChart/PlanOfCareSelectors';
import MedicalQuestionsSelectors from '../../components/MyChart/MedicalQuestionsSelectors';
import VisitsSelectors from '../../components/MyChart/VisitsSelectors';
import VisitsNotesSelectors from '../../components/MyChart/VisitsNotesSelectors';
/* Education components */

import GlobalEducationSelectors from '../../components/Education/GlobalSelectors';
import GradebookSelectors from '../../components/Education/GradebookSelectors';
import HealthInfoSelectors from '../../components/Education/HealthInfoSelectors';
import AttendanceSelectors from '../../components/Education/AttendanceSelectors';
import StudentSelectors from '../../components/Education/StudentSelectors';

/* FWISD component */
import GlobalFWISDSelectors from '../../components/FWISD/GlobalSelectors';

export const myChartComponentsMap = [
	GlobalSelectors,
	PatientInformationSelectors,
	HealthIssuesSelectors,
	MedicationsSelectors,
	AllergiesSelectors,
	ImmunizationsSelectors,
	LaboratoryTestSelectors,
	LaboratoryTestDetailsSelectors,
	MedicalHistorySelectors,
	PlanOfCareSelectors,
	MedicalQuestionsSelectors,
	VisitsSelectors,
	VisitsNotesSelectors
];
export const educationComponentsMap = [
	GlobalEducationSelectors,
	GradebookSelectors,
	HealthInfoSelectors,
	AttendanceSelectors,
	StudentSelectors
];

export const fwisdComponentsMap = [GlobalFWISDSelectors];

export const educationInitialConfigurationValues = {
	loginUrl: '',
	selectorUsername: '',
	selectorPassword: '',
	selectorSubmitBtn: '',
	loginErrorSelector: '',
	leftGridSelector: '',
	leftGridCellSelector: '',
	marksGridSelector: '',
	marksGridColSelector: '',
	healthInfoLinkMenuItems: '',
	healthInfoLinkSubMenuItems: '',
	healthInfoMenuItems: [],
	healthInfoNumberOfMenus: 0,
	healthInfochildhoodIllnessesGridSelector: '',
	healthInfoDiabeteScareLogGridSelector: '',
	healthInfoHealthConditionsGridSelector: '',
	healthInfoMedicationsGridselector: '',
	healthInfoOfficeVisitsGridselector: '',
	healthInfoTestsGridSelector: '',
	healthInfoVaccinationsGridselector: '',
	healthPhysicalExamsGridselector: '',
	healthInjuriesGridselector: '',
	healthIHPFormsGridselector: '',
	healthDisabilitiesGridselector: '',
	attendanceGridSelector: '',
	attendanceGridCellSelector: '',
	studentInfoselector: '',
	studentInfoItemMainInfoGridselector: '',
	studentinfoItemStudentInfoGridselector: '',
	studentInfoItemStudentEntityGridselector: '',
	studentInfoItemFamilyInfoGridselector: '',
	selectorLogOut: ''
};

export const myChartInitialConfigurationValues = {
	loginUrl: '',
	selectorUsername: '',
	selectorPassword: '',
	selectorSubmitBtn: '',
	loginErrorSelector: '',
	middleUrl: '',
	selectorCheck: '',
	selectorGetUsers: '',
	selectorGetChild: '',
	selectorAgree: '',
	selectorClickBasicUser: '',
	selectorPageWait: '',
	patientInformationUrl: '',
	patientInformationSelectorName: '',
	patientInformationSelectorBirth: '',
	patientInformationSelectorRace: '',
	patientInformationSelectorMedicalRecordNumbers: '',
	patientInformationSelectorPrimaryProvider: '',
	patientInformationSelectorClinic: '',
	patientInformationSelectorAddress: '',
	patientInformationSelectorPhone: '',
	patientInformationSelectorEmergencyContactName: '',
	patientInformationSelectorEmergencyContactPhone: '',
	patientInformationSelectorInshuranceName: '',
	patientInformationSelectorInshuranceType: '',
	patientInformationSelectorInshuranceNumber: '',
	healthIssuesUrl: '',
	healthIssueSelectorAll: '',
	healthIssueSelectorName: '',
	healthIssueSelectorDate: '',
	medicationsUrl: '',
	medicationsSelectorAll: '',
	medicationsSelectorName: '',
	medicationsSelectorDosage: '',
	medicationsSelectorDate: '',
	visitsUrl: '',
	visitsDocumentSelector: '',
	visitsAllSelector: '',
	visitsPreviewButtonSelector: '',
	visitsSummarySelector: '',
	visitsSummaryContentSelector: '',
	visitNotesUrl: '',
	visitNotesPastListSelector: '',
	visitNotesTitleSelector: '',
	visitNotesBackButtonSelector: '',
	visitNotesListIndexSelector: '',
	allergiesUrl: '',
	allergiesSelectorAll: '',
	allergiesSelectorList: '',
	allergiesSelectorName: '',
	allergiesSelectorDate: '',
	immunizationsUrl: '',
	immunizationsSelectorAll: '',
	immunizationsSelectorName: '',
	immunizationsSelectorDate: '',
	laboratoryTestsUrl: '',
	laboratoryTestsSelectorAll: '',
	laboratoryTestsSelectorUrls: '',
	testsDetailsSelectorAll: '',
	testsDetailsSelectorComponent: '',
	testsDetailsSelectorValue: '',
	testsDetailsSelectorRange: '',
	testsDetailsSelectorName: '',
	testsDetailsSelectorOrderBy: '',
	testsDetailsSelectorDate: '',
	testsDetailsSelectorStudyResult: '',
	medicalHistoryUrl: '',
	medicalHistoryDiagnosisSelectorAll: '',
	medicalHistoryDiagnosisSelectorName: '',
	medicalHistoryDiagnosisSelectorWhen: '',
	surgicalHistorySelectorAll: '',
	surgicalHistorySelectorName: '',
	surgicalHistorySelectorWhen: '',
	familyHistorySelectorAll: '',
	familyHistorySelectorRelationship: '',
	familyHistorySelectorHealthIssue: '',
	familyHistorySelectorComment: '',
	socialHistorySelector: '',
	smokingTobaccoSelectorUse: '',
	smokingTobaccoSelectorType: '',
	smokingTobaccoSelectorPacks: '',
	smokingTobaccoSelectorTears: '',
	smokelessTobaccoSelectorUse: '',
	smokelessTobaccoSelectorType: '',
	alcoholUseSelectorUse: '',
	alcoholUseSelectorOunces: '',
	planOfCareUrl: '',
	planOfCareSelectorAll: '',
	planOfCareOverdueSelectorName: '',
	planOfCareOverdueTitleSelector: '',
	planOfCareStatusSelector: '',
	planOfCareNextSelectorName: '',
	planOfCareDateSelector: '',
	planOfCareLocationSelector: '',
	planOfCareOrderBySelector: '',
	planOfCareOrderDetailsSelector: '',
	planOfCareFutureSelectorName: '',
	selectorLogOut: '',
	medicalQuestionsUrl: ''
};

export const fwisdInitialConfigurationValues = {
	loginUrl: '',
	selectorUsername: '',
	selectorPassword: '',
	selectorSubmitBtn: '',
	loginErrorSelector: '',
	middleUrl: '',
	selectorCheck: '',
	selectorGetUsers: '',
	selectorGetChild: '',
	selectorAgree: '',
	selectorClickBasicUser: '',
	selectorPageWait: '',
	selectorLogOut: ''
};

export const infoComponents = {
	MyChart: {
		componentsMap: myChartComponentsMap,
		configuration: myChartInitialConfigurationValues
	},
	Education: {
		componentsMap: educationComponentsMap,
		configuration: educationInitialConfigurationValues
	},
	FWISD: {
		componentsMap: fwisdComponentsMap,
		configuration: fwisdInitialConfigurationValues
	}
};
