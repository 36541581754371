import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Button,
	CssBaseline,
	Typography,
	Grid,
	Container,
	FormHelperText,
	InputAdornment,
	IconButton,
	OutlinedInput,
	FormControl,
	InputLabel
} from '@material-ui/core';

import { withStyles } from '@material-ui/styles';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

/* Clear information for selected person in left navigation */
import {
	clearSelectedPerson,
	setDefaultSelectedUser,
	setLoggedAccountData
} from '@SharedActions';

import { STRONG_PASS_REGEX } from '@Constants';

import { editAccountInfoStyles } from './styles';

import { changePassword } from '../actions';

import PasswordChangedSuccessfulDialog from '../components/PasswordChangedSuccessfulDialog/index';
import authentication from '../../../utilities/authentication';
import { logOut } from '../../../shared/actions';

class ChangePassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: {},
			errors: {},
			open: false,
			showOldPassword: false,
			showNewPassword: false,
			showConfirmNewPassword: false
		};
	}

	componentDidMount = async () => {
		await this.props.clearSelectedPerson();
		await this.props.setDefaultSelectedUser();
	};

	onChangeHandler = event => {
		const { fields } = this.state;
		fields[event.target.name] = event.target.value;
		this.setState({ fields, errors: {} });
	};

	handleValidation = () => {
		const { fields } = this.state;
		const errors = {};
		let formIsValid = true;

		if (!fields.newPassword) {
			formIsValid = false;
			errors.newPassword = 'Field cannot be empty.';
		} else if (!STRONG_PASS_REGEX.test(fields.newPassword)) {
			formIsValid = false;
			errors.newPassword =
				'Password must contains at least 1 lowercase, 1 uppercase alphabetical character, 1 numeric character, one special character and eight characters or longer.';
		}

		if (!fields.confirmNewPassword) {
			formIsValid = false;
			errors.confirmNewPassword = 'Field cannot be empty.';
		} else if (!STRONG_PASS_REGEX.test(fields.confirmNewPassword)) {
			formIsValid = false;
			errors.confirmNewPassword =
				'Password must contains at least 1 lowercase, 1 uppercase alphabetical character, 1 numeric character, one special character and eight characters or longer.';
		}

		if (
			fields.newPassword &&
			fields.confirmNewPassword &&
			fields.newPassword !== fields.confirmNewPassword
		) {
			formIsValid = false;
			errors.confirmNewPassword = 'Passwords not match.';
		}

		this.setState({ errors });

		return formIsValid;
	};

	onFormSubmit = async event => {
		event.preventDefault();
		if (this.handleValidation()) {
			const { fields } = this.state;

			const passwords = {
				oldPassword: fields.oldPassword,
				newPassword: fields.newPassword
			};

			try {
				const res = await this.props.changePassword(passwords);
				/* handle on refresh */
				if (res.state !== 'error') {
					this.setState({ open: true });
				}

				/* remove old cookie
				cookies.remove('uname');
				cookies.set('uname', userName); */
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e, 'change password error');
			}
		}
	};

	handleOnClose = () => {
		this.setState({ open: false });
		this.props.logOut();
		authentication.logout();
	};

	handleClickShowOldPassword = () => {
		const { showOldPassword } = this.state;
		this.setState({ showOldPassword: !showOldPassword });
	};

	handleClickShowNewPassword = () => {
		const { showNewPassword } = this.state;
		this.setState({ showNewPassword: !showNewPassword });
	};

	handleClickShowConfirmNewPassword = () => {
		const { showConfirmNewPassword } = this.state;
		this.setState({ showConfirmNewPassword: !showConfirmNewPassword });
	};

	render = () => {
		const { classes } = this.props;
		const {
			errors,
			fields,
			open,
			showOldPassword,
			showNewPassword,
			showConfirmNewPassword
		} = this.state;

		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					<div className={classes.accountInfoContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Change Password
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Enter your old password and new passwords
							</Typography>
						</Container>
						<Container
							className={classes.accountInfoGrid}
							maxWidth="xs"
						>
							<form
								onSubmit={this.onFormSubmit}
								className={classes.form}
								noValidate
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<FormControl style={{ width: '100%' }}>
											<InputLabel
												htmlFor="old-password"
												fullWidth
												variant="outlined"
											>
												Old Password
											</InputLabel>
											<OutlinedInput
												variant="outlined"
												required
												fullWidth
												name="oldPassword"
												onChange={this.onChangeHandler}
												error={errors.oldPassword}
												helperText={errors.oldPassword}
												label="Old Password"
												type={
													showOldPassword
														? 'text'
														: 'password'
												}
												value={fields.oldPassword}
												id="old-password"
												inputProps={{
													autocomplete:
														'new-password',
													form: {
														autocomplete: 'off'
													}
												}}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={
																this
																	.handleClickShowOldPassword
															}
														>
															{showOldPassword ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												}
											/>
											{errors.oldPassword && (
												<FormHelperText
													style={{ color: '#f44336' }}
													id="error-old-password-text"
												>
													{errors.oldPassword}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl style={{ width: '100%' }}>
											<InputLabel
												htmlFor="new-password"
												fullWidth
												variant="outlined"
											>
												New Password
											</InputLabel>
											<OutlinedInput
												variant="outlined"
												required
												fullWidth
												name="newPassword"
												onChange={this.onChangeHandler}
												error={errors.newPassword}
												helperText={errors.newPassword}
												label="New Password"
												type={
													showNewPassword
														? 'text'
														: 'password'
												}
												value={fields.newPassword}
												id="new-password"
												inputProps={{
													autocomplete:
														'new-password',
													form: {
														autocomplete: 'off'
													}
												}}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={
																this
																	.handleClickShowNewPassword
															}
														>
															{showNewPassword ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												}
											/>
											{errors.showNewPassword && (
												<FormHelperText
													style={{ color: '#f44336' }}
													id="error-new-password-text"
												>
													{errors.showNewPassword}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl style={{ width: '100%' }}>
											<InputLabel
												htmlFor="confirm-new-password"
												fullWidth
												variant="outlined"
											>
												Confirm New Password
											</InputLabel>
											<OutlinedInput
												variant="outlined"
												required
												fullWidth
												name="confirmNewPassword"
												onChange={this.onChangeHandler}
												error={
													errors.confirmNewPassword
												}
												helperText={
													errors.confirmNewPassword
												}
												label="Confirm New Password"
												type={
													showConfirmNewPassword
														? 'text'
														: 'password'
												}
												value={
													fields.confirmNewPassword
												}
												id="confirm-new-password"
												inputProps={{
													autocomplete:
														'new-password',
													form: {
														autocomplete: 'off'
													}
												}}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={
																this
																	.handleClickShowConfirmNewPassword
															}
														>
															{showConfirmNewPassword ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												}
											/>
											{errors.confirmNewPassword && (
												<FormHelperText
													style={{ color: '#f44336' }}
													id="error-confirm-new-text"
												>
													{errors.confirmNewPassword}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
								</Grid>

								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
									/* 	disabled={!fields.agree} */
								>
									Change Password
								</Button>
							</form>
						</Container>
					</div>
				</main>
				<PasswordChangedSuccessfulDialog
					open={open}
					onClose={this.handleOnClose}
				/>
			</React.Fragment>
		);
	};
}

ChangePassword.propTypes = {
	classes: PropTypes.object.isRequired,
	clearSelectedPerson: PropTypes.func.isRequired,
	setDefaultSelectedUser: PropTypes.func.isRequired,
	changePassword: PropTypes.func.isRequired,
	logOut: PropTypes.func.isRequired
};

export default withStyles(editAccountInfoStyles)(
	connect(
		state => {
			return {
				loggedAccountData: state.general.get('loggedAccountData').toJS()
			};
		},
		{
			clearSelectedPerson,
			setDefaultSelectedUser,
			changePassword,
			setLoggedAccountData,
			logOut
		}
	)(ChangePassword)
);
