import React, { useState, Fragment } from 'react';
/* import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack'; */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
/* import ConsentPDF from '@ConsentPDF/consent.pdf'; */

const ConsentPdfDialog = () => {
	const [open, setOpen] = useState(false);
	/* 	const [numberPages, setNumberPages] = useState(null); */

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	/* 
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumberPages(numPages);
	}; */

	return (
		<Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
			>
				Open the Consent
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="consent-dialog-title"
				maxWidth="md"
			>
				<DialogTitle id="consent-dialog-title">Consent</DialogTitle>
				<DialogContent>
					{/* 	<div>
						<Document
							file={ConsentPDF}
							onLoadSuccess={onDocumentLoadSuccess}
						>
							{Array.from(new Array(numberPages), (el, index) => (
								<Page
									key={`page_${index + 1}`}
									pageNumber={index + 1}
								/>
							))}
						</Document>
					</div> */}
					Connections added allow MyPHI to access information from
					this provider and share it with other providers in your
					account according to MyPHI's data sharing policies or at
					your direction via email or link. Account data sharing
					settings can be modified at any time in account settings. I
					am authorized to access this information and share it. I
					consent to share this data with MyPHI and to the other
					providers in my account per my settings.
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

export default ConsentPdfDialog;
