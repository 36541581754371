/* eslint-disable no-eval */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { fromJS } from 'immutable';

import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { formStyles } from './styles';

import {
	myChartSteps,
	myChartSocialHistoryDefaultState,
	myChartComponentsMap,
	educationSteps,
	educationComponentsMap
} from './constants';

const useStyles = makeStyles(formStyles);

const Form = props => {
	const classes = useStyles();

	const isMyChart = props.type === 'myChart';

	const [activeStep, setActiveStep] = React.useState(0);
	/* My Chart set state funcs */
	const [personalInfo, setPersonalInfo] = React.useState(
		props.data.myChart.personalInfo
	);
	const [allergies, setAllergies] = React.useState(
		props.data.myChart.allergies
	);
	const [familyHistories, setFamilyHistories] = React.useState(
		props.data.myChart.history.familyHistory
	);
	const [medicalHistories, setMedicalHistories] = React.useState(
		props.data.myChart.history.medicalHistory
	);
	const [surgicalHistories, setSurgicalHistories] = React.useState(
		props.data.myChart.history.surgicalHistory
	);
	const [planOfCareOverdues, setPlanOfCareOverdues] = React.useState(
		props.data.myChart.planOfCare.planOfCareOverdue
	);
	const [planOfCareNexts, setPlanOfCareNexts] = React.useState(
		props.data.myChart.planOfCare.planOfCareNext
	);
	const [planOfCareFutures, setPlanOfCareFutures] = React.useState(
		props.data.myChart.planOfCare.planOfCareFuture
	);
	const [diagnosis, setDiagnosis] = React.useState(
		props.data.myChart.diagnosis
	);
	const [immunizations, setImmunizations] = React.useState(
		props.data.myChart.immunizations
	);
	const [medications, setMedications] = React.useState(
		props.data.myChart.medications
	);
	const [laboratoryTests, setLaboratoryTests] = React.useState(
		props.data.myChart.laboratoryTests
	);
	const [files, setFiles] = React.useState(props.data.myChart.files);
	const [socialHistory, setSocialHistory] = React.useState(
		myChartSocialHistoryDefaultState
	);

	/* Education set state funcs */

	const [studentInfo, setStudentInfo] = React.useState(
		props.data.education.studentInfo
	);
	const [emerganceContacts, setEmerganceContacts] = React.useState(
		props.data.education.studentInfo.itemStudentInfo
	);
	const [attendances, setAttendances] = React.useState(
		props.data.education.attendances
	);
	const [disciplines, setDisciplines] = React.useState(
		props.data.education.disciplines
	);
	const [gradebooks, setGradebooks] = React.useState(
		props.data.education.gradebooks
	);
	const [healthConditions, setHealthConditions] = React.useState(
		props.data.education.healthInfo.healthConditions
	);
	const [diabeteScarelogs, setDiabeteScarelogs] = React.useState(
		props.data.education.healthInfo.diabeteScarelog
	);
	const [disabilities, setDisabilities] = React.useState(
		props.data.education.healthInfo.disabilities
	);
	const [officeVisits, setOfficeVisits] = React.useState(
		props.data.education.healthInfo.officeVisits
	);
	const [ihpForms, setIhpForms] = React.useState(
		props.data.education.healthInfo.ihpForms
	);
	const [childhoodIllnesses, setChildhoodIllnesses] = React.useState(
		props.data.education.healthInfo.childhoodIllnesses
	);
	const [injuries, setInjuries] = React.useState(
		props.data.education.healthInfo.injuries
	);
	const [educationMedications, setEducationMedications] = React.useState(
		props.data.education.healthInfo.medications
	);
	const [physicalExams, setPhysicalExams] = React.useState(
		props.data.education.healthInfo.physicalExams
	);
	const [tests, setTests] = React.useState(
		props.data.education.healthInfo.tests
	);
	const [vaccinations, setVaccinations] = React.useState(
		props.data.education.healthInfo.vaccinations
	);

	const [deletedFiles, setDeletedFiles] = React.useState([]);

	useEffect(() => {
		setPersonalInfo(props.data.myChart.personalInfo);
		setAllergies(props.data.myChart.allergies);
		setFamilyHistories(props.data.myChart.history.familyHistory);
		setMedicalHistories(props.data.myChart.history.medicalHistory);
		setSurgicalHistories(props.data.myChart.history.surgicalHistory);
		setPlanOfCareOverdues(props.data.myChart.planOfCare.planOfCareOverdue);
		setPlanOfCareNexts(props.data.myChart.planOfCare.planOfCareNext);
		setPlanOfCareFutures(props.data.myChart.planOfCare.planOfCareFuture);
		setDiagnosis(props.data.myChart.diagnosis);
		setImmunizations(props.data.myChart.immunizations);
		setMedications(props.data.myChart.medications);
		setLaboratoryTests(props.data.myChart.laboratoryTests);
		setFiles(props.data.myChart.files);
		setSocialHistory(props.data.myChart.history.socialHistory);

		/* education */

		setStudentInfo(props.data.education.studentInfo);
		setEmerganceContacts(props.data.education.studentInfo.itemStudentInfo);
		setAttendances(props.data.education.attendances);
		setDisciplines(props.data.education.disciplines);
		setGradebooks(props.data.education.gradebooks);
		setHealthConditions(props.data.education.healthInfo.healthConditions);
		setDiabeteScarelogs(props.data.education.healthInfo.diabeteScarelog);
		setDisabilities(props.data.education.healthInfo.disabilities);
		setOfficeVisits(props.data.education.healthInfo.officeVisits);
		setIhpForms(props.data.education.healthInfo.ihpForms);
		setChildhoodIllnesses(
			props.data.education.healthInfo.childhoodIllnesses
		);
		setInjuries(props.data.education.healthInfo.injuries);
		setEducationMedications(props.data.education.healthInfo.medications);
		setPhysicalExams(props.data.education.healthInfo.physicalExams);
		setTests(props.data.education.healthInfo.tests);
		setVaccinations(props.data.education.healthInfo.vaccinations);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(props.data)]);

	const handleFinish = async () => {
		let profile;
		if (isMyChart) {
			profile = {
				personalInfo,
				allergies,
				diagnosis,
				history: {
					familyHistory: familyHistories,
					medicalHistory: medicalHistories,
					socialHistory: {
						ouncesWeek: socialHistory.ouncesWeek,
						alcoholUse: socialHistory.alcoholUse,

						smokelessTobaccoTypes:
							socialHistory.smokelessTobaccoTypes,
						smokelessTobaccoUse: socialHistory.smokelessTobaccoUse,
						packsDay: socialHistory.packsDay,
						smokingTobaccoTypes: socialHistory.smokingTobaccoTypes,
						smokingTobaccoUse: socialHistory.smokingTobaccoUse,
						yearsSmoked: socialHistory.yearsSmoked
					},
					surgicalHistory: surgicalHistories
				},
				planOfCare: {
					planOfCareOverdue: planOfCareOverdues,
					planOfCareFuture: planOfCareFutures,
					planOfCareNext: planOfCareNexts
				},
				immunizations,
				laboratoryTests,
				medications,
				files: files.map(file => {
					return { id: file.file ? '' : file.id };
				})
			};
		} else {
			profile = {
				gradebooks,
				attendances,
				disciplines,
				studentInfo: {
					...studentInfo,
					itemStudentInfo: emerganceContacts
				},
				healthInfo: {
					healthConditions,
					diabeteScarelog: diabeteScarelogs,
					disabilities,
					officeVisits,
					ihpForms,
					childhoodIllnesses,
					injuries,
					medications: educationMedications,
					physicalExams,
					tests,
					vaccinations
				}
			};
		}
		const isLast = activeStep === steps.length - 1;
		try {
			setActiveStep(0);
			await props.action(
				profile,
				props.type,
				files,
				isLast,
				deletedFiles
			);
			props.redirect('/');
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	const handleNext = async () => {
		const isLast = activeStep === steps.length - 1;
		if (!isLast) {
			setActiveStep(activeStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const handleStepperClick = async index => {
		setActiveStep(index);
	};

	const handleAddNewItem = (value, stateFunc, stateName) => {
		const funcName = `set${stateFunc}`;
		const stateData = eval(stateName);

		eval(funcName)([...stateData, ...value]);
	};

	const handleField = (value, stateFunc, stateName, path) => {
		const funcName = `set${stateFunc}`;

		let stateData = fromJS(eval(stateName));

		stateData = stateData.setIn([...path], value);
		eval(funcName)(stateData.toJS());
	};

	const deleteData = (index, stateFunc, stateName) => {
		const funcName = `set${stateFunc}`;

		const stateData = fromJS(eval(stateName));
		const stateObject = stateData.toJS();

		if (stateName === 'files') {
			setDeletedFiles([...deletedFiles, stateObject[index].id]);
		}

		stateObject.splice(index, 1);

		eval(funcName)([...stateObject]);
	};

	const componentsMap = isMyChart
		? myChartComponentsMap
		: educationComponentsMap;
	const steps = isMyChart ? myChartSteps : educationSteps;

	const StepComponent = componentsMap[activeStep].comp;

	return (
		<React.Fragment>
			<CssBaseline />

			<main className={classes.layout}>
				<Paper className={classes.paper}>
					<Typography component="h1" variant="h4" align="center">
						Self-provider profile form
					</Typography>
					<Stepper
						activeStep={activeStep}
						className={classes.stepper}
					>
						{steps.map((label, index) => (
							<Step
								style={{ cursor: 'pointer' }}
								onClick={() => handleStepperClick(index)}
								key={label}
							>
								<StepLabel style={{ cursor: 'pointer' }}>
									{label}
								</StepLabel>
							</Step>
						))}
					</Stepper>
					<React.Fragment>
						<React.Fragment>
							<StepComponent
								handleField={handleField}
								deleteData={deleteData}
								handleAddNewItem={handleAddNewItem}
								handleGetOCR={props.handleGetOCR}
								data={eval(componentsMap[activeStep].data)}
							/>
							<div className={classes.buttons}>
								{activeStep !== 0 && (
									<Button
										onClick={handleBack}
										className={classes.button}
									>
										Back
									</Button>
								)}
								<Button
									variant="contained"
									color="primary"
									onClick={
										activeStep === steps.length - 1
											? handleFinish
											: handleNext
									}
									className={classes.button}
								>
									{activeStep === steps.length - 1
										? 'Finish and Exit'
										: 'Next'}
								</Button>

								<Button
									variant="contained"
									color="primary"
									onClick={handleFinish}
									className={classes.button}
								>
									Save and Exit
								</Button>
							</div>
						</React.Fragment>
					</React.Fragment>
				</Paper>
			</main>
		</React.Fragment>
	);
};

Form.propTypes = {
	type: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired,
	redirect: PropTypes.func.isRequired,
	action: PropTypes.func.isRequired,
	handleGetOCR: PropTypes.func.isRequired
};
export default Form;
