import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import HttpsRedirect from 'react-https-redirect';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { enUS } from '@material-ui/core/locale';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { configureStore, history } from './store';

const initialState = {};
const store = configureStore(initialState);

const theme = createTheme(
	{
		palette: {
			primary: {
				main: '#1976d2',
				hover: '#c5e2fea3'
			} /* 
			primary: { main: '#1a511d', hover: '#c5e2fea3' } */
		}
	},
	enUS
);

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<HttpsRedirect>
				<ThemeProvider theme={theme}>
					<App history={history} />
				</ThemeProvider>
			</HttpsRedirect>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
