/* eslint-disable no-param-reassign */
import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	vaccines: [],
	hasVaccines: false,
	mainInfo: {
		lat: 32.77610023069153,
		lon: -96.7952260064936,
		zoom: 11
	}
	/* 	vaccines: [
		{
			id: 1,
			name: 'test1',
			address: 'test1',
			city: 'test1',
			zipCode: 'test1',
			lat: 32.78610023069153,
			lon: -96.8052260064936
		},
		{
			id: 2,
			name: 'test2',
			city: 'test2',
			address: 'test2',
			zipCode: 'test2',
			lat: 32.81610023069153,
			lon: -96.8252260064936
		}
	] */
});

const vaccineDashboard = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_VACCINES:
			if (action.state === 'finished') {
				state = state
					.set('mainInfo', action.response.get('mainInfo'))
					.setIn(['mainInfo', 'zoom'], 11)
					.set('hasVaccines', true)
					.set('vaccines', action.response.get('vaccineCenters'));
			}

			return state;
		case actionTypes.GO_TO_POINT:
			state = state
				.setIn(
					['mainInfo', 'lat'],
					state.getIn(['vaccines', action.data.id, 'lat'])
				)
				.setIn(
					['mainInfo', 'lon'],
					state.getIn(['vaccines', action.data.id, 'lon'])
				)
				.setIn(['mainInfo', 'zoom'], 16);
			return state;
		case actionTypes.CHECK_AGAIN:
			state = state.set('hasVaccines', false);
			return state;
		default:
			return state;
	}
};

export default vaccineDashboard;
