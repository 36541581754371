export const GET_PROVIDERS = Symbol('GET_PROVIDERS');
export const GET_PROVIDER_TYPE = Symbol('GET_PROVIDER_TYPE');
export const REQUEST_NEW_PROVIDER = Symbol('REQUEST_NEW_PROVIDER');
export const ADD_PROFILE_FROM_PROVIDER = Symbol('ADD_PROFILE_FROM_PROVIDER');
export const GET_PROVIDERS_WAITING_FOR_SMS = Symbol(
	'GET_PROVIDERS_WAITING_FOR_SMS'
);
export const SEND_SMS_CODE = Symbol('SEND_SMS_CODE');
export const SAVE_SETTINGS = Symbol('SAVE_SETTINGS');
export const GET_SETTINGS = Symbol('GET_SETTINGS');
