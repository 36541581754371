/* eslint-disable react/no-danger */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import ConfirmationDialog from '@Components/ConfirmationDialog';

import ActionButtons from '../../components/ActionButtons/index';
import Vendor from '../../components/Vendor/index';

import { styles } from './styles';

import {
	getVisit,
	getProvider,
	getProviderCredentials,
	resetData,
	deleteVisit,
	sendTempLink,
	updateProviderCredentials,
	updateVisit,
	getContent,
	moveVisit,
	getPersonsList
} from '../../actions';

/* Style content from backend */
import '../../styles.css';

class VisitInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			person: {},
			tempLinkEmail: '',
			isConfirmationDialogOpen: false
		};
	}

	componentDidMount = async () => {
		const { visit, person } = this.props.location.state;
		try {
			const provider = await this.props.getProvider(visit.provider);

			const res = provider.response.toJS();

			if (res.providerName !== 'self provider') {
				this.props.getProviderCredentials(visit.profileId);
			}

			await this.props.getContent(visit.content);

			this.props.getVisit(visit.id);

			this.setState({
				visitDetailId: visit.visitDetailId,
				person
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e, 'error');
		}
	};

	deleteVisit = async () => {
		const visitObj = {
			visitId: this.props.visitData.toJS().id
		};

		try {
			await this.props.deleteVisit(visitObj);
			const { person } = this.state;
			this.props.history.push({
				pathname: `/dashboard/${person.id}/visits/`,
				state: { person }
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	updateVisit = async () => {
		const visitObj = {
			personId: this.props.profileData.get('person'),
			profileId: this.props.visitData.get('profileId'),
			providerId: this.props.visitData.get('provider'),
			profileDetailId: this.state.visitDetailId
		};

		try {
			await this.props.updateVisit(visitObj);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	updateProviderCredentials = async data => {
		const { providerCredentials } = this.props;
		const credentialsData = {
			password: data.password,
			username: data.username,
			id: providerCredentials.id
		};

		try {
			await this.props.updateProviderCredentials(credentialsData);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	generateTempLink = () => {
		const loggedData = this.props.loggedAccountData;

		const tempLink = {
			user: loggedData.userId,
			email: this.state.tempLinkEmail,
			visitId: this.props.visitData.toJS().id,
			url: 'printVisit',
			content: {
				visitData: this.props.visitData.toJS(),
				adminMode: false
			}
		};

		this.props.sendTempLink(tempLink);

		this.setState({
			tempLinkEmail: '',
			isConfirmationDialogOpen: false
		});
	};

	handleSendEmail = tempLinkEmail => {
		this.setState({
			tempLinkEmail,
			isConfirmationDialogOpen: true
		});
	};

	handleCloseConfirmationDialog = () => {
		this.setState({
			tempLinkEmail: '',
			isConfirmationDialogOpen: false
		});
	};

	moveVisit = async personId => {
		const { person } = this.state;
		const visitObj = {
			visitId: this.props.visitData.get('id'),
			personId
		};

		try {
			await this.props.moveVisit(visitObj);
			this.props.history.push({
				pathname: `/dashboard/${person.id}/visits/`,
				state: { person }
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	handlePrint = () => {
		window.print();
	};

	render = () => {
		const {
			visitData,
			providerData,
			providerCredentials,
			content,
			personsList,
			classes,
			loggedAccountData
		} = this.props;

		return (
			<Fragment>
				{visitData.get('content') && (
					<Fragment>
						<Typography
							component="h1"
							variant="h2"
							align="center"
							color="textPrimary"
							gutterBottom
							className={classes.fullName}
							style={{ marginTop: '100px' }}
						>
							{`${visitData.get('visitName')}`}
						</Typography>
						<Grid
							style={{ marginBottom: '10px' }}
							container
							spacing={4}
						>
							<Grid item md={6} xs={12}>
								<Box display="block" displayPrint="none">
									<ActionButtons
										providerName={providerData.providerName}
										personalInfo={
											visitData.toJS().content
												.personalInfo
										}
										handleSendEmail={this.handleSendEmail}
										deleteVisit={this.deleteVisit}
									/>
								</Box>
							</Grid>

							<Grid item md={6} xs={12}>
								<Box display="block" displayPrint="none">
									<Vendor
										providerData={providerData}
										visitData={visitData.toJS()}
										updateVisit={this.updateVisit}
										updateProviderCredentials={
											this.updateProviderCredentials
										}
										handlePrint={this.handlePrint}
										moveVisit={this.moveVisit}
										getPersonsList={
											this.props.getPersonsList
										}
										personsList={personsList}
										loggedAccountData={loggedAccountData}
										providerCredentials={
											providerCredentials
										}
									/>
								</Box>
							</Grid>
						</Grid>

						<Grid container spacing={4}>
							<Grid item md={12} xs={12}>
								<div
									className="visits_content_wrapper"
									dangerouslySetInnerHTML={{
										__html: content
									}}
								/>
							</Grid>
						</Grid>
					</Fragment>
				)}
				<ConfirmationDialog
					open={this.state.isConfirmationDialogOpen}
					dialogContent="Are you sure that you want to share your health information with this email?"
					dialogTitle="Confirmation Dialog"
					action={this.generateTempLink}
					close={this.handleCloseConfirmationDialog}
				/>
			</Fragment>
		);
	};
}

VisitInformation.propTypes = {
	visitData: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	providerData: PropTypes.object.isRequired,
	providerCredentials: PropTypes.object.isRequired,
	sendTempLink: PropTypes.func.isRequired,
	deleteVisit: PropTypes.func.isRequired,
	getProviderCredentials: PropTypes.func.isRequired,
	content: PropTypes.string.isRequired,
	updateProviderCredentials: PropTypes.func.isRequired,
	updateVisit: PropTypes.func.isRequired,
	moveVisit: PropTypes.func.isRequired,
	getProvider: PropTypes.func.isRequired,
	getVisit: PropTypes.func.isRequired,
	getContent: PropTypes.func.isRequired,
	loggedAccountData: PropTypes.object.isRequired,
	getPersonsList: PropTypes.func.isRequired,
	personsList: PropTypes.array.isRequired,
	location: PropTypes.shape({
		state: PropTypes.shape({
			visit: PropTypes.string.isRequired,
			person: PropTypes.object.isRequired
		})
	})
};

VisitInformation.defaultProps = {
	location: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				visitData: state.dashboard.getIn([
					'visitDashboard',
					'visitData'
				]),
				content: state.dashboard.getIn(['visitDashboard', 'content']),
				providerData: state.dashboard
					.getIn(['visitDashboard', 'providerData'])
					.toJS(),
				providerCredentials: state.dashboard
					.getIn(['visitDashboard', 'providerCredentials'])
					.toJS(),
				personsList: state.dashboard
					.getIn(['visitDashboard', 'personsList'])
					.toJS(),
				loggedAccountData: state.general.get('loggedAccountData').toJS()
			};
		},
		{
			getProvider,
			getProviderCredentials,
			getVisit,
			resetData,
			deleteVisit,
			sendTempLink,
			updateProviderCredentials,
			updateVisit,
			getContent,
			moveVisit,
			getPersonsList
		}
	)(VisitInformation)
);
