import React from 'react';
import PropTypes from 'prop-types';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import CakeIcon from '@material-ui/icons/Cake';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {
	List,
	WindowScroller,
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache
} from 'react-virtualized';

import { formatDate } from '@Common';

import TimelineInfoDialog from '../TimelineInfoDialog/index';

import { iconsMap, sectionTitlesMap } from './constants';
import { useStyles } from './styles';

const rowRenderer = (
	{
		key, // Unique key within array of rows
		index, // Index of row within collection
		parent,
		isScrolling, // The List is currently being scrolled
		isVisible, // This row is visible within the List (eg it is not an overscanned row)
		style // Style object to be applied to row (to position it)
	},
	data,
	cache,
	classes,
	getContent,
	isSmallContainer
) => {
	const isLeftOriented = index % 2 === 0;
	const isLast = data.length - 1 === index;
	const Icon = iconsMap[data[index].type];

	return (
		<CellMeasurer
			key={key}
			cache={cache}
			parent={parent}
			columnIndex={0}
			rowIndex={index}
		>
			<div key={key} style={style}>
				<TimelineItem
					style={{
						flexDirection: isLeftOriented ? 'row-reverse' : 'row'
					}}
				>
					<TimelineOppositeContent
						style={{
							textAlign: isLeftOriented ? 'left' : 'right',
							width: '46%'
						}}
					>
						<Typography
							className={classes.dateTime}
							variant="body2"
							color="textSecondary"
						>
							{formatDate(data[index].date)}
						</Typography>
					</TimelineOppositeContent>
					<TimelineSeparator
						style={{
							width: '8%'
						}}
					>
						<TimelineDot className={classes.icon} color="primary">
							{iconsMap[data[index].type] ? (
								<Icon />
							) : (
								<CakeIcon />
							)}
						</TimelineDot>

						{!isLast && <TimelineConnector />}
					</TimelineSeparator>
					<TimelineContent
						style={{
							textAlign: isLeftOriented ? 'right' : 'left',
							width: '46%'
						}}
					>
						<Paper elevation={3} className={classes.paperWrapper}>
							<Typography
								className={classes.timelineHeader}
								variant="h6"
								component="h1"
							>
								<span>
									{sectionTitlesMap[data[index].type] ||
										data[index].type}
								</span>
								{' - '}
								<span className={classes.profileName}>
									{data[index].profileName}
								</span>
							</Typography>
							<Typography>
								<div className={classes.ellipsisText}>
									{data[index].type === 'dateOfBirth'
										? formatDate(data[index].fieldName)
										: data[index].fieldName}
								</div>
								<TimelineInfoDialog
									data={data[index].childObject}
									subTitle={data[index].providerName}
									type={data[index].type}
									getContent={getContent}
									isSmallContainer={isSmallContainer}
									title={`${sectionTitlesMap[
										data[index].type
									] || data[index].type}`}
								/>
							</Typography>
						</Paper>
					</TimelineContent>
				</TimelineItem>
			</div>
		</CellMeasurer>
	);
};

const TimelineData = props => {
	const { data, scrollElement, getContent, isSmallContainer } = props;

	const classes = useStyles(props)();

	const cache = new CellMeasurerCache({
		fixedWidth: true,
		defaultHeight: 50
	});

	const getDynamicHeight = e => cache.rowHeight(e) + 20;

	return (
		<div className={classes.root}>
			<WindowScroller
				scrollElement={
					scrollElement && scrollElement.current !== null
						? scrollElement.current
						: window
				}
			>
				{({
					height,
					isScrolling,
					registerChild,
					onChildScroll,
					scrollTop
				}) => (
					<div>
						<AutoSizer disableHeight>
							{({ width }) => (
								<div ref={registerChild}>
									<Timeline align="alternate">
										<List
											autoHeight
											ref={el => {
												window.listEl = el;
											}}
											height={height}
											isScrolling={isScrolling}
											rowCount={data.length}
											rowHeight={getDynamicHeight}
											rowRenderer={e =>
												rowRenderer(
													e,
													data,
													cache,
													classes,
													getContent,
													isSmallContainer
												)
											}
											scrollTop={scrollTop}
											width={width}
											overscanRowCount={2}
											onScroll={onChildScroll}
											deferredMeasurementCache={cache}
										/>
									</Timeline>
								</div>
							)}
						</AutoSizer>
					</div>
				)}
			</WindowScroller>
		</div>
	);
};

TimelineData.propTypes = {
	data: PropTypes.array.isRequired,
	scrollElement: PropTypes.any,
	isSmallContainer: PropTypes.any,
	getContent: PropTypes.func.isRequired
};

TimelineData.defaultProps = {
	scrollElement: {},
	isSmallContainer: false
};

export default TimelineData;
