import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import {
	TextField,
	InputAdornment,
	IconButton,
	OutlinedInput,
	FormControl,
	InputLabel
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const ProviderForm = props => {
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<Fragment>
			<TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				id="providerUsername"
				label="Username for Provider Portal"
				name="providerUsername"
				value={props.values.providerUsername}
				onChange={props.handleChange}
				inputProps={{
					autocomplete: 'new-password',
					form: {
						autocomplete: 'off'
					}
				}}
			/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel
					htmlFor="provider-password"
					fullWidth
					variant="outlined"
				>
					Password for Provider Portal*
				</InputLabel>

				<OutlinedInput
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="providerPassword"
					label="Password for Provider Portal"
					id="provider-password"
					type={showPassword ? 'text' : 'password'}
					value={props.values.providerPassword}
					onChange={props.handleChange}
					inputProps={{
						autocomplete: 'new-password',
						form: {
							autocomplete: 'off'
						}
					}}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
							>
								{showPassword ? (
									<Visibility />
								) : (
									<VisibilityOff />
								)}
							</IconButton>
						</InputAdornment>
					}
				/>
			</FormControl>
		</Fragment>
	);
};

ProviderForm.propTypes = {
	values: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired
};

export default ProviderForm;
