/* eslint-disable no-param-reassign */
import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';
import { profileHelper } from '../../constants';

export const defaultState = fromJS({
	providersType: [],
	profileData: profileHelper,
	providers: [],
	providerTypeName: 'myChart',
	imageText: '',
	profileInfo: {}
});

const providerMap = {
	MyChart: 'myChart',
	Education: 'education'
};

const editSelfProfile = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_PROVIDERS_TYPE:
			if (action.state === 'finished') {
				state = state.set('providersType', action.response);
			}
			return state;
		case actionTypes.GET_PROFILE:
			if (action.state === 'finished') {
				const profileProvider = state
					.get('providers')
					.toJS()
					.find(item => item.id === action.response.get('provider'));
				state = state
					.set(
						'providerTypeName',
						providerMap[profileProvider.providerTypeName]
					)
					.set('profileInfo', action.response)
					.setIn(
						[
							'profileData',
							providerMap[profileProvider.providerTypeName]
						],
						action.response.get('content')
					);
			}
			return state;
		case actionTypes.GET_ALL_PROVIDERS:
			if (action.state === 'finished') {
				state = state.set('providers', action.response);
			}
			return state;

		case actionTypes.UPDATE_PROFILE:
			if (action.state === 'finished') {
				state = state
					.set('profileInfo', action.response)
					.setIn(
						['profileData', state.get('providerTypeName')],
						action.response.get('content')
					);
			}
			return state;
		case actionTypes.GET_TEXT_FROM_IMAGE:
			if (action.state === 'finished') {
				state = state.set('imageText', action.response);
			}
			return state;
		case actionTypes.GET_TEXT_FROM_URL_IMAGE:
			if (action.state === 'finished') {
				state = state.set('imageText', action.response);
			}
			return state;
		case actionTypes.SET_DEFAULT_STATE:
			state = defaultState;

			return state;
		default:
			return state;
	}
};

export default editSelfProfile;
