import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VirtualizedTable from '@Components/VirtualizedTable/index';

import {
	Button,
	CssBaseline,
	Typography,
	Paper,
	Container
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
/* Clear information for selected person in left navigation */
import { clearSelectedPerson, setDefaultSelectedUser } from '@SharedActions';

import { getRequestedZipCodes } from './actions';

const styles = theme => ({
	icon: {
		marginRight: theme.spacing(2)
	},
	zipCodesContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	zipCodesGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	},
	subHeader: {
		marginBottom: '20px',
		marginTop: '20px'
	},
	refreshButton: {
		marginBottom: '20px'
	}
});

class RequestedZipCodes extends Component {
	componentDidMount() {
		this.props.clearSelectedPerson();
		this.props.setDefaultSelectedUser();
		this.props.getRequestedZipCodes();
	}

	render() {
		const { requestedZipCodes, classes } = this.props;

		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					<div className={classes.zipCodesContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
							>
								Requested Zip Code
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
							>
								See below the requested zip code by the Covid
								form
							</Typography>
						</Container>
						<Container
							className={classes.zipCodesGrid}
							maxWidth="md"
						>
							<Button
								onClick={this.props.getRequestedZipCodes}
								color="primary"
								variant="contained"
								className={classes.refreshButton}
							>
								Refresh
							</Button>
							<Paper
								style={{
									height: ' calc(100vh - 400px)',
									width: '800px'
								}}
							>
								<VirtualizedTable
									rowCount={requestedZipCodes.length}
									rowGetter={({ index }) =>
										requestedZipCodes[index]
									}
									rowHeight={100}
									columns={[
										{
											width: 200,
											label: 'Zip Code',
											dataKey: 'zipCode'
										},
										{
											width: 200,
											label: 'Requested Times',
											dataKey: 'requestedTimes'
										},
										{
											width: 200,
											label: 'Crated',
											dataKey: 'created',
											isDateTime: true
										},
										{
											width: 200,
											label: 'Is Imported',
											dataKey: 'isImported',
											customComponent: isImported => (
												<div>
													{isImported ? 'Yes' : 'No'}
												</div>
											)
										}
									]}
								/>
							</Paper>
						</Container>
					</div>
				</main>
			</React.Fragment>
		);
	}
}

RequestedZipCodes.propTypes = {
	requestedZipCodes: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	getRequestedZipCodes: PropTypes.func.isRequired,
	clearSelectedPerson: PropTypes.func.isRequired,
	setDefaultSelectedUser: PropTypes.func.isRequired
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				requestedZipCodes: state.admin
					.getIn(['requestedZipCodes', 'requestedZipCodes'])
					.toJS()
			};
		},
		{
			getRequestedZipCodes,
			setDefaultSelectedUser,
			clearSelectedPerson
		}
	)(RequestedZipCodes)
);
