import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import TempLinkDialog from '../TempLinkDialog/index';

const useStyles = makeStyles(theme => ({
	tempLinkButton: {
		width: '100%',
		cursor: 'pointer',
		textAlign: 'center',

		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	},
	email: {
		width: '600px'
	}
}));

const TempLinkButton = props => {
	const classes = useStyles();
	const { selectedPerson, handleSendTemporaryLinkData } = props;

	const [isDialogOpened, setOpen] = React.useState(false);

	const handleClickDialogOpen = () => {
		setOpen(true);
	};

	const handleDialogClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickDialogOpen}
				className={classes.tempLinkButton}
			>
				Send temporary link
			</Button>
			<TempLinkDialog
				isDialogOpened={isDialogOpened}
				selectedPerson={selectedPerson}
				handleSendTemporaryLinkData={handleSendTemporaryLinkData}
				handleDialogClose={handleDialogClose}
			/>
		</React.Fragment>
	);
};

TempLinkButton.propTypes = {
	selectedPerson: PropTypes.object.isRequired,
	handleSendTemporaryLinkData: PropTypes.func.isRequired
};

export default TempLinkButton;
