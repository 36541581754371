import React, { Component, Fragment, Suspense } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { setSelectedUser, setSelectedPerson } from '@SharedActions';

import {
	closeAlert,
	logOut,
	sendFeedback,
	getNotifications,
	sendSmsCode,
	addPerson,
	photoUpload
} from './actions';

import { getJobs } from './pages/Jobs/actions';

import { getProvidersWaitingForSms } from './pages/Dashboard/actions';

import './index.css';

import Routes from './components/Routes/Routes';

import Home from './pages/Home/index';

import Profiles from './pages/Dashboard/pages/Profiles/index';
import Visits from './pages/Dashboard/pages/Visits/index';
import PrintProfileInformation from './pages/Dashboard/pages/Profiles/pages/ProfilePrintInformation/index';
import PrintVisitInformation from './pages/Dashboard/pages/Visits/pages/VisitPrintInformation/index';
import PrintSummary from './pages/Summary/pages/PrintSummaryInformation/index';
import Register from './pages/Authentication/pages/Register';
import Login from './pages/Authentication/pages/Login';
import ForgottenPassword from './pages/Authentication/pages/ForgottenPassword';
import ResetPassword from './pages/Authentication/pages/ResetPassword';
import SelfProfile from './pages/SelfProfile/index';
import Reports from './pages/Reports/index';
import Admin from './pages/Admin/index';
import RiskScoreByZipCode from './pages/Reports/pages/RiskScoreByZipCode/index';
import VaccineDashboard from './pages/UnauthenticatedPages/pages/VaccineDashboard/index';
import CovidHesitancyAnalysis from './pages/UnauthenticatedPages/pages/CovidHesitancyAnalysis/index';

class App extends Component {
	componentDidMount = async () => {
		const userData = this.props.loggedAccountData;
		if (userData.loggedIn) {
			this.props.getNotifications(userData.userId);
		}
	};

	componentDidUpdate = prevProps => {
		if (this.props.isLoginSuccessful !== prevProps.isLoginSuccessful) {
			const userData = this.props.loggedAccountData;
			this.props.getNotifications(userData.userId);
		}
	};

	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.props.closeAlert();
	};

	handleSendSmsCode = async (smsCode, providerId, personId) => {
		try {
			await this.props.sendSmsCode(smsCode, providerId);

			await this.props.getProvidersWaitingForSms(personId);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
		}
	};

	render = () => {
		const {
			isOpen,
			isAlertOpen,
			alertInfo,
			selectedPerson,
			messages,
			providersWaitingForSms,
			selectedUser,
			persons,
			loggedAccountData
		} = this.props;

		return (
			<Fragment>
				<BrowserRouter>
					<Suspense fallback={<Fragment />}>
						<Switch>
							<Route
								path="/login"
								component={props => <Login {...props} />}
							/>
							<Route
								path="/register"
								component={props => <Register {...props} />}
							/>
							<Route
								path="/user/forgotten-password"
								component={props => (
									<ForgottenPassword {...props} />
								)}
							/>
							<Route
								path="/user/reset/:token"
								component={props => (
									<ResetPassword {...props} />
								)}
							/>
							<Route
								path="/printProfile/:guid"
								component={props => (
									<PrintProfileInformation {...props} />
								)}
							/>
							<Route
								path="/printVisit/:guid"
								component={props => (
									<PrintVisitInformation {...props} />
								)}
							/>
							<Route
								path="/printSummary/:guid"
								component={props => <PrintSummary {...props} />}
							/>
							<Route
								path="/riskScoreByZipCode"
								component={props => (
									<RiskScoreByZipCode {...props} />
								)}
							/>
							<Route
								path="/vaccinations-centers"
								component={props => (
									<VaccineDashboard {...props} />
								)}
							/>
							<Route
								path="/covid-hesitancy-analysis"
								component={props => (
									<CovidHesitancyAnalysis {...props} />
								)}
							/>
							<Home
								messages={messages}
								selectedPerson={selectedPerson}
								providersWaitingForSms={providersWaitingForSms}
								selectedUser={selectedUser}
								persons={persons}
								loggedAccountData={loggedAccountData}
								sendSmsCode={this.handleSendSmsCode}
								{...this.props}
							>
								<Routes />
								<SelfProfile />
								<Reports />
								<Admin />
								<Profiles />
								<Visits />
							</Home>
						</Switch>
					</Suspense>
				</BrowserRouter>
				<Backdrop
					style={{
						zIndex: 94477777,
						color: '#fff'
					}}
					open={isOpen}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
				<Snackbar
					open={isAlertOpen}
					autoHideDuration={3000}
					onClose={this.handleClose}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				>
					<MuiAlert
						elevation={6}
						variant="filled"
						onClose={this.handleClose}
						severity={alertInfo.severity}
					>
						{alertInfo.message}
					</MuiAlert>
				</Snackbar>
			</Fragment>
		);
	};
}

App.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isAlertOpen: PropTypes.bool.isRequired,
	alertInfo: PropTypes.object.isRequired,
	selectedPerson: PropTypes.object.isRequired,
	providersWaitingForSms: PropTypes.array.isRequired,
	history: PropTypes.object.isRequired,
	selectedUser: PropTypes.object.isRequired,
	loggedAccountData: PropTypes.object.isRequired,
	closeAlert: PropTypes.func.isRequired,
	sendSmsCode: PropTypes.func.isRequired,
	logOut: PropTypes.func.isRequired,
	sendFeedback: PropTypes.func.isRequired,
	getJobs: PropTypes.func.isRequired,
	getNotifications: PropTypes.func.isRequired,
	setSelectedUser: PropTypes.func.isRequired,
	setSelectedPerson: PropTypes.func.isRequired,
	getProvidersWaitingForSms: PropTypes.func.isRequired,
	addPerson: PropTypes.func.isRequired,
	photoUpload: PropTypes.func.isRequired,
	messages: PropTypes.array.isRequired,
	persons: PropTypes.array.isRequired,
	isLoginSuccessful: PropTypes.bool.isRequired
};

export default connect(
	state => {
		return {
			isOpen: state.general.get('isOpen'),
			isAlertOpen: state.general.get('isAlertOpen'),
			selectedPerson: state.general.get('selectedPerson').toJS(),
			alertInfo: state.general.get('alertInfo').toJS(),
			messages: state.general.get('messages').toJS(),
			isLoginSuccessful: state.general.get('isLoginSuccessful'),
			selectedUser: state.general.get('selectedUser').toJS(),
			providersWaitingForSms: state.dashboard
				.get('providersWaitingForSms')
				.toJS(),
			persons: state.persons.get('personsList').toJS(),
			loggedAccountData: state.general.get('loggedAccountData').toJS()
		};
	},
	{
		closeAlert,
		logOut,
		sendFeedback,
		getNotifications,
		sendSmsCode,
		getProvidersWaitingForSms,
		getJobs,
		setSelectedUser,
		setSelectedPerson,
		addPerson,
		photoUpload
	}
)(App);
