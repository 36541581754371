/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '@Common';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableRow from '@material-ui/core/TableRow';

import { getComparator, stableSort, generateNestedHeadCells } from './common';

const NestedTableBody = props => {
	const { rows, isNested, headCells, customMap, order, orderBy } = props;

	const generateRow = data => {
		const keys = Object.keys(data);

		// eslint-disable-next-line consistent-return
		return keys.map(key => {
			if (data[key] instanceof Array) {
				const nestedRows = data[key];

				const nestedHeadCells = generateNestedHeadCells(
					nestedRows[0],
					customMap
				);

				return (
					<TableRow>
						<TableCell
							style={{
								paddingBottom: 0,
								paddingTop: 0
							}}
							colSpan={6}
						>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell />
										{nestedHeadCells.map(head => (
											<TableCell>{head.label}</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{nestedRows.map((row, rowIndex) => {
										const nestedRowKeys = Object.keys(row);
										const nestedRow = [];
										for (
											let j = 0;
											j < nestedRowKeys.length;
											// eslint-disable-next-line no-plusplus
											j++
										) {
											const nestedKey = nestedRowKeys[j];
											if (
												row[nestedKey] instanceof Array
											) {
												if (row[nestedKey].length > 0) {
													nestedRow.push(
														generateRow(row)
													);
												}
											}
										}
										return (
											<>
												<TableRow>
													<TableCell>
														{rowIndex + 1}
													</TableCell>
													{nestedHeadCells.map(
														head => {
															if (head.isCustom) {
																return (
																	<TableCell>
																		{head.customComponent(
																			row
																		)}
																	</TableCell>
																);
															}

															return (
																<TableCell>
																	{head.isDateFormat
																		? formatDate(
																				row[
																					head
																						.id
																				]
																		  )
																		: row[
																				head
																					.id
																		  ] ||
																		  'N/A'}
																</TableCell>
															);
														}
													)}
												</TableRow>
												{nestedRow}
											</>
										);
									})}
								</TableBody>
							</Table>
						</TableCell>
					</TableRow>
				);
			}
		});
	};

	return (
		<>
			<TableBody>
				{stableSort(rows, getComparator(order, orderBy)).map(
					(row, index) => {
						return (
							<>
								<TableRow
									role="checkbox"
									tabIndex={-1}
									style={{ backgroundColor: 'unset' }}
								>
									<>
										{isNested && (
											<TableCell>{index + 1}</TableCell>
										)}
										{headCells.map(head => (
											<TableCell>
												{head.isDateFormat
													? formatDate(row[head.id])
													: row[head.id]}
											</TableCell>
										))}
									</>
								</TableRow>

								{isNested && generateRow(row)}
							</>
						);
					}
				)}
				{rows.length === 0 && (
					<TableRow>
						<TableCell align="center" colSpan={6}>
							No Data
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</>
	);
};

NestedTableBody.propTypes = {
	rows: PropTypes.array.isRequired,
	headCells: PropTypes.array.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	customMap: PropTypes.object,
	isNested: PropTypes.bool.isRequired
};

NestedTableBody.defaultProps = {
	customMap: {}
};

export default NestedTableBody;
