import React from 'react';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker
} from 'react-google-maps';
import { compose, withProps } from 'recompose';

const MyMapComponent = compose(
	withProps({
		googleMapURL:
			'https://maps.googleapis.com/maps/api/js?key=AIzaSyDMFF6uqMd9lgvoc-yXCddzZR6wJVoEus8&v=3.exp&libraries=geometry,drawing,places',
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `100%` }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	withScriptjs,
	withGoogleMap
)(props => (
	<GoogleMap defaultZoom={props.zoom} defaultCenter={props.defaultCenter}>
		{props.markers.map(marker => {
			return (
				<Marker
					key={marker.id}
					position={{ lat: marker.lat, lng: marker.lon }}
					onClick={() =>
						props.handleOnClick({
							lat: marker.lat,
							lon: marker.lon
						})
					}
				/>
			);
		})}
	</GoogleMap>
));

export default MyMapComponent;

/* <Marker
					key={marker.id}
					position={{ lat: marker.latitude, lng: marker.longitude }}
				>
					<InfoWindow>
						<div>{marker.name}</div>
					</InfoWindow>
				</Marker> */
