import profilesImage from '@Images/profiles.jpg';
import visitsImage from '@Images/visits.jpg';

export const CARDS = [
	{
		name: 'visits',
		title: 'My Visits',
		url: id => `/dashboard/${id}/visits`,
		description: 'See here the information for all your hospital visits.',
		image: visitsImage,
		id: 1
	},
	{
		name: 'profile',
		title: 'My Health Records',
		url: id => `/dashboard/${id}/profiles`,
		description: 'See here all your connections and profiles.',
		image: profilesImage,
		id: 2
	}
];

export const NewsData = [
	{
		title: 'Warning notification multi-line',
		description:
			'Here is a clear explanation. It drops to next line if the content is long enough.',
		isVisible: true,
		id: 4
	},
	{
		title: 'Informational notification multi-line',
		description:
			'Here is a clear explanation. It drops to next line if the content is long enough.',
		isVisible: true,
		id: 4455
	},
	{
		title: 'Error notification multi-line',
		description:
			'Here is a clear explanation. It drops to next line if the content is long enough.',
		isVisible: true,
		id: 44
	},
	{
		title: 'Success notification multi-line',
		description:
			'Here is a clear explanation. It drops to next line if the content is long enough.',
		isVisible: true,
		id: 5
	},
	{
		title: 'Warning notification multi-line',
		description:
			'Here is a clear explanation. It drops to next line if the content is long enough.',
		isVisible: true,
		id: 6
	},
	{
		title: 'Informational notification multi-line',
		description:
			'Here is a clear explanation. It drops to next line if the content is long enough.',
		isVisible: true,
		id: 7
	},
	{
		title: 'Error notification multi-line',
		description:
			'Here is a clear explanation. It drops to next line if the content is long enough.',
		isVisible: true,
		id: 8
	},
	{
		title: 'Success notification multi-line',
		description:
			'Here is a clear explanation. It drops to next line if the content is long enough.',
		isVisible: true,
		id: 9
	}
];
