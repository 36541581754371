import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const Tests = props => {
	const handleDateChange = (date, index) => {
		props.handleField(date, 'Tests', 'tests', [index, 'date']);
	};

	const handleAdd = () => {
		props.handleAddNewItem(
			[
				{
					schoolYear: '',
					date: new Date(),
					school: '',
					examinedBy: '',
					referredBy: '',
					tests: []
				}
			],
			'Tests',
			'tests'
		);
	};

	const handleAddTestResults = index => {
		props.handleField(
			[
				...props.data[index].tests,
				{
					columnName1: '',
					columnName2: '',
					val1: '',
					val2: ''
				}
			],
			'Tests',
			'tests',
			[index, 'tests']
		);
	};

	const handleField = (event, index) => {
		props.handleField(event.target.value, 'Tests', 'tests', [
			index,
			event.target.id
		]);
	};

	const handleFieldResults = (event, index, resultsIndex) => {
		props.handleField(event.target.value, 'Tests', 'tests', [
			index,
			'tests',
			resultsIndex,
			event.target.id
		]);
	};

	const handleDelete = index => {
		props.deleteData(index, 'Tests', 'tests');
	};

	const handleDeleteResults = (index, resultsIndex) => {
		const { tests } = props.data[index];
		tests.splice(resultsIndex, 1);

		props.handleField([...tests], 'Tests', 'tests', [index, 'tests']);
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Tests
			</Typography>
			{props.data.length > 0 ? (
				props.data.map((test, index) => {
					return (
						<Grid
							style={{ marginTop: '20px' }}
							container
							spacing={3}
						>
							<Grid item xs={12} md={4}>
								<TextField
									id="schoolYear"
									label="School Year"
									fullWidth
									value={test.schoolYear}
									onChange={e => handleField(e, index)}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date"
										label="Date"
										value={test.date}
										style={{
											width: '100%',
											marginTop: 0,
											marginBottom: 0
										}}
										autoOk
										onChange={date =>
											handleDateChange(date, index)
										}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12} md={3}>
								<Button
									style={{ marginTop: '20px' }}
									variant="contained"
									color="primary"
									onClick={() => handleAddTestResults(index)}
								>
									Add Test Results
								</Button>
							</Grid>

							<Grid item xs={12} md={1}>
								<IconButton
									aria-label="delete"
									color="primary"
									style={{
										backgroundColor: '#d9534f',
										'&:hover': {
											backgroundColor: '#c9302c'
										},
										color: '#ffffff',
										height: '45px',
										width: '45px',
										marginTop: '5px',
										cursor: 'pointer'
									}}
									onClick={() => handleDelete(index)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="school"
									label="School"
									fullWidth
									value={test.school}
									onChange={e => handleField(e, index)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="examinedBy"
									label="Examined By"
									fullWidth
									value={test.examinedBy}
									onChange={e => handleField(e, index)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="referredBy"
									label="Referred By"
									fullWidth
									value={test.referredBy}
									onChange={e => handleField(e, index)}
								/>
							</Grid>
							{test.tests.length > 0 && (
								<Grid item xs={12} md={12}>
									<Grid container>
										<Typography variant="h6" gutterBottom>
											Tests Results
										</Typography>

										{test.tests.map(
											(result, resultIndex) => {
												return (
													<Grid container spacing={3}>
														<Grid
															item
															xs={12}
															md={3}
														>
															<TextField
																id="columnName1"
																label="Test Name"
																fullWidth
																value={
																	result.columnName1
																}
																onChange={e =>
																	handleFieldResults(
																		e,
																		index,
																		resultIndex
																	)
																}
															/>
														</Grid>
														<Grid
															item
															xs={12}
															md={3}
														>
															<TextField
																id="val1"
																label="Test result"
																fullWidth
																value={
																	result.val1
																}
																onChange={e =>
																	handleFieldResults(
																		e,
																		index,
																		resultIndex
																	)
																}
															/>
														</Grid>
														<Grid
															item
															xs={12}
															md={3}
														>
															<TextField
																id="columnName2"
																label="Test name 2"
																fullWidth
																value={
																	result.columnName2
																}
																onChange={e =>
																	handleFieldResults(
																		e,
																		index,
																		resultIndex
																	)
																}
															/>
														</Grid>
														<Grid
															item
															xs={12}
															md={3}
														>
															<TextField
																id="val2"
																label="Test result 2"
																fullWidth
																value={
																	result.val2
																}
																onChange={e =>
																	handleFieldResults(
																		e,
																		index,
																		resultIndex
																	)
																}
															/>
														</Grid>
														<Grid
															item
															xs={12}
															md={1}
														>
															<IconButton
																aria-label="delete"
																color="primary"
																style={{
																	backgroundColor:
																		'#d9534f',
																	'&:hover': {
																		backgroundColor:
																			'#c9302c'
																	},
																	color:
																		'#ffffff',
																	height:
																		'45px',
																	width:
																		'45px',
																	marginTop:
																		'5px',
																	cursor:
																		'pointer'
																}}
																onClick={() =>
																	handleDeleteResults(
																		index,
																		resultIndex
																	)
																}
															>
																<DeleteIcon />
															</IconButton>
														</Grid>
													</Grid>
												);
											}
										)}
									</Grid>
								</Grid>
							)}
						</Grid>
					);
				})
			) : (
				<Typography component="h1" variant="h4" align="center">
					No Tests
				</Typography>
			)}

			<Grid contariner spacing={3}>
				<Button
					style={{ marginTop: '20px' }}
					variant="contained"
					color="primary"
					onClick={handleAdd}
				>
					Add Test
				</Button>
			</Grid>
		</React.Fragment>
	);
};

Tests.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired,
	deleteData: PropTypes.func.isRequired,
	handleAddNewItem: PropTypes.func.isRequired
};

export default Tests;
