/* eslint-disable no-param-reassign */
import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	visitsList: [],
	healthSummaryList: [],
	visitData: {},
	providerData: {
		providerTypeName: 'MyChart'
	},
	providerCredentials: {},
	isAccessCodeValid: false,
	personsList: [],
	content: ''
});

const visitDashboard = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_VISITS:
			if (action.state === 'finished') {
				state = state.set('visitsList', action.response);
			}
			return state;
		case actionTypes.GET_HEALTH_SUMMARIES:
			if (action.state === 'finished') {
				state = state.set('healthSummaryList', action.response);
			}
			return state;
		case actionTypes.GET_VISIT:
			if (action.state === 'finished') {
				state = state.set('visitData', action.response);
			}
			return state;
		case actionTypes.RESET_DATA:
			state = defaultState;

			return state;
		case actionTypes.GET_PROVIDER_CREDENTIALS:
			if (action.state === 'finished') {
				state = state.set('providerCredentials', action.response);
			}
			return state;
		case actionTypes.DELETE_VISIT:
			if (action.state === 'finished') {
				const newVisitsList = state
					.get('visitsList')
					.toJS()
					.filter(visit => visit.id !== action.body.visitId);

				state = state.set('visitsList', fromJS(newVisitsList));
			}
			return state;
		case actionTypes.MOVE_VISIT:
			if (action.state === 'finished') {
				const newVisitsList = state
					.get('visitsList')
					.toJS()
					.filter(visit => visit.id !== action.body.visitId);

				state = state.set('visitsList', fromJS(newVisitsList));
			}
			return state;
		case actionTypes.CHECK_ACCESS_CODE:
			if (action.state === 'finished') {
				state = state.set('isAccessCodeValid', action.response);
			}
			return state;
		case actionTypes.GET_CONTENT:
			if (action.state === 'finished') {
				state = state.set('content', action.response);
			}
			return state;
		case actionTypes.GET_PERSONS:
			if (action.state === 'finished') {
				state = state.set('personsList', action.response);
			}
			return state;
		case actionTypes.LOAD_VISIT_FROM_TEMP_LINK:
			if (action.state === 'finished') {
				state = state.set(
					'visitData',
					action.response.getIn(['content', 'visitData'])
				);
			}
			return state;
		default:
			return state;
	}
};

export default visitDashboard;
