import * as actionTypes from './actionTypes';

export const logIn = loginData => {
	return {
		type: actionTypes.LOG_IN,
		requestType: 'POST',
		request: `/login`,
		message: 'Login is successful.',
		body: {
			...loginData
		}
	};
};

export const registerUser = registerData => {
	return {
		type: actionTypes.REGISTER_USER,
		requestType: 'POST',
		request: `/register`,
		message: 'Registration is successful.',
		body: {
			...registerData
		}
	};
};

export const sendRecoveryEmail = recoveryObj => {
	return {
		type: actionTypes.SEND_RECOVERY_EMAIL,
		requestType: 'POST',
		request: `/forgotten-password`,
		message: 'Recovery email send successfully. Please check your email.',
		body: {
			...recoveryObj
		}
	};
};

export const resetPassword = passwordObj => {
	return {
		type: actionTypes.RESET_PASSWORD,
		requestType: 'POST',
		request: `/user/resetPassword`,
		message: 'Password changed successful.',
		body: {
			...passwordObj
		}
	};
};

export const getAccountInformation = () => {
	return {
		type: actionTypes.GET_ACCOUNT_INFORMATION,
		requestType: 'GET',
		request: `/getAccountInfo`,
		body: {}
	};
};

export const editAccountInformation = accountData => {
	return {
		type: actionTypes.EDIT_ACCOUNT_INFORMATION,
		requestType: 'POST',
		request: `/editAccountInfo`,
		message: 'Edit Information changed successful.',
		body: {
			accountData
		}
	};
};
export const changePassword = passwordObj => {
	return {
		type: actionTypes.CHANGE_PASSWORD,
		requestType: 'POST',
		request: `/user/changePassword`,
		message: 'Password changed successful.',
		body: {
			...passwordObj
		}
	};
};
